// import { NewContract } from '@/components/business/new.contract';
import request from '@/utils/request';
/***
 * banner数据
 * @param params
 */ 
export async function getVerifyCode(params) {
  return request({
    url: 'jkapi/Login/sendSms',
    method: 'POST',
    data: params,
  });
}

/***
 * 登录
 * @param params
 */
 export async function mobileLogin(params) {
  return request({
    url: 'jkapi/Login/index',
    method: 'POST',
    data: params,
  });
}

/**
 * 获取token
 */
 export async function smsToken(params) {
  return request({
    url: 'jkapi/Login/smsToken',
    method: 'POST',
    data: params,
  });
}

/**
 * 
 */
 export async function getTicket() {
  return request({
    url: 'jkapi/Member/getTicket' ,
    method: 'POST',
    // data: params,
  });
}

/**
 * 校验是否登录成功
 */
 export async function checkLogin(params) {
  return request({
    url: 'jkapi/Member/checkLogin' ,
    method: 'POST',
    data: params,
  });
}


/**
 * 校验是否登录成功
 */
 export async function getVerify() {
  return request({
    url: 'jkapi/Login/verify',
    method: 'POST',
    // data: params,
  });
}

/**
 * 注册
 */
 export async function register(params) {
  return request({
    url: 'jkapi/Register/add',
    method: 'POST',
    data: params,
  });
}

/**
 * 扫码绑定
 */
 export async function wxBind(params) {
  return request({
    url: 'jkapi/Login/wxBind',
    method: 'POST',
    data: params,
  });
}
