import { notification, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import showcode from '@/assets/img/showcode.png'
import styles from './index.module.scss'
import { Fragment } from 'react/cjs/react.production.min';
const Info = (props) => {
  const [tags, setTags] = useState([])
  const { record } = props;
  const { head_img, nickname,
    trade, content_type, introduction, sec_uid, dt_id, qr_code } = record;
  useEffect(() => {
    setTags(...[content_type?.split(',')])
  }, [trade, content_type])
  const tocode = () => {
    // window.open('https://www.douyin.com/user/' + sec_uid)
  }
  return <div className={styles.info} onClick={props?.handleClick}>
    {/* <Tooltip title="点击可查看达人主页"> */}
    <img src={head_img} alt="" />
    {/* </Tooltip> */}
    <div className={styles.infoBox}>
      <div className={styles.infoTop}>
        {/* <Tooltip title="点击可查看达人主页"> */}
        <div className={styles.title} onClick={tocode}>
          {nickname}
        </div>
        {/* </Tooltip> */}
        {
          props.showcode && (
            <Tooltip placement="bottomLeft" overlayClassName='codeTooltip' getPopupContainer={() => document.getElementById('root')} title={
              <Fragment>
                <img className='dareninfocode' src={qr_code} />
                <div className="dareninfocodeTitle">抖音扫一扫，查看达人主页</div>
              </Fragment>
            } color="#ffffff" >
              <div className={styles.code}>
                <img src={showcode} alt="" />
              </div>
            </Tooltip>
          ) || null
        }

      </div>
      <div className={styles.infoContent}>
        {introduction}
      </div>
      <div className={styles.tags}>
        {
          tags?.map(i => {
            return !!i && <div className={styles.tagItem}>
              {i}
            </div> || null
          })
        }
      </div>
    </div>
  </div>
};
export default Info