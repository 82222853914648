import {getLocal, setLocal, setSession, getSession } from '@/utils/storage';

export const getToken = () => {
  // return 'Token:046018a0e81f0c40d4d8a2f22b5fcd99';
  return getLocal('token');
};

export const setToken = (value) => {
  return setLocal('token', value);
};

export const getAuthorization = () => {
  // return 'Token:046018a0e81f0c40d4d8a2f22b5fcd99';
  return getSession('Authorization');
};

export const setAuthorization = (value) => {
  return setSession('Authorization', value);
};

/**
 * 获取 getLibraryId
 */
export const getLibraryId = () => {
  // return getLocal('libraryInfo').libraryId;
  // let libraryInfoStr = localStorage.getItem('libraryInfo');
  let libraryInfoStr = localStorage.getItem('libraryInfo');

  let jsonInfo = {};
  if (libraryInfoStr != null) {
    jsonInfo = JSON.parse(libraryInfoStr);
  }
  return jsonInfo.libraryId;
};

export const setLibraryId = (value) => {
  return setLocal('libraryId', value);
};
//存取userId
export const getUser = () => {
  return getLocal('user');
};

export const setUser = (value) => {
  return setLocal('user', value);
};
export const getAppid = () => {
  if (window.location?.host === 'do.kaikeba.com') {
    return 'wx5046bc7413796142'
  } else {
    return 'wxf47dff26f5a918f7'
  }
}

export const isPc = () => {
  let userAgentInfo = navigator.userAgent;
  let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}
export const menuId = 1068

export const tagColor = {
  'primary':'rgb(37, 184, 100)',
  'success':'#1AB394',
  'info':'rgb(37, 184, 100)',
  'warning':'#F8AC59',
  'danger':'#ED5566'
}
export const mobileTest = /^[1][3,5,7,8][0-9]{9}$/

// 二维码根据环境切换接口
export const getApiHost = () => {
  let { hostname } = window.location;
  if (hostname.indexOf('advance.yinliu.club') > -1) {
    return 'https://advance-api.yinliu.club/';
  }
  if (hostname.indexOf('lms.yinliu.club') > -1) {
    return 'https://advance-api.yinliu.club/';
  }
  return 'http://api.yinliu.club/';
}