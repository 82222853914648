// import { columns } from '@/src/utils/formdata.js';
import { useCallback, useEffect, useState } from 'react'
import styles from './index.module.scss';
import { save, disassembleInfo, gettradeList } from './../../service'
import { useHistory } from 'react-router-dom';
import { Button, Tabs, Form, Input, Select, message, Radio, Space, Spin } from 'antd'
import { sign_analyze, name_analyze, head_img_analyze, character_analyze, daily_style_analyze, appearance_analyze, scene_analyze, realization_analyze, age_distribution_analyze } from './constant'
import Check from './checks';
import Selects from './select'
const { Option } = Select;
const { TextArea } = Input;
const Part = (props) => {
  const [tradeList, setTradeList] = useState([])
  const [partForm] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    resetCanstant()
    partForm.resetFields();
    partForm.setFieldsValue({
      sign_analyze, name_analyze, head_img_analyze, character_analyze, daily_style_analyze, appearance_analyze, scene_analyze
    });
    // window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    document.getElementById('root').scrollTop = 0;
  }, [])
  const resetCanstant = () => {
    sign_analyze.map(i => {i.checked = false;i.inner = '';return i;})
    name_analyze.map(i => {i.selected = false;i.inner = '';return i;})

    head_img_analyze.map(i => {i.selected = false;i.inner = '';return i;})
    character_analyze.map(i => {i.selected = false;i.inner = '';return i;})

    daily_style_analyze.map(i => {i.selected = false;i.inner = '';return i;})
    appearance_analyze.map(i => {i.selected = false;i.inner = '';return i;})
    
    scene_analyze.map(i => {i.selected = false;i.inner = '';return i;})
  }
  useEffect(() => {
    getTradeList()
    if (props?.dtte_id != 0) {
      getDisassembleInfo()
    }
    //先写成dt_id  之后变成ddtid
  }, [props.dt_id, props.dtte_id])
  const getTradeList = async () => {
    message.destroy()
    try {
      const res = await gettradeList();
      // partForm.setFieldsValue({
      //   trade_analyze: res
      // })
      setTradeList(res)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
  };
  const getDisassembleInfo = async() => {
    try {
      const res = await disassembleInfo(props.dtte_id)
      restoreForm(res)
    } catch(errorInfo) {
      console.log(errorInfo);
    }
  };
  const restoreForm = (params) => {
    const {analyze_info, base_info } = params;
    const {account_analyze, homepage_analyze, overall } = analyze_info;
    const { title } = base_info;
    const { business_analyze, role_analyze, content_analyze, user_analyze } =account_analyze;
    const { 
      conclusion,
      summary
    } = overall;

    const { 
      // realization_analyze,
       realization_think
    } = business_analyze;

    const { 
      // appearance_analyze, 
      // character_analyze, 
      // daily_style_analyze, 
      is_match_appearance, 
      // scene_analyze, 
      speak_style_analyze 
    } = role_analyze; 
    const { 
      content_style_analyze,
      content_type_analyze,
      trade_analyze
    } = content_analyze; 
    const {
      // user_analyze, 
      // age_distribution_analyze,
      gender_analyze, 
      consumption_analyze 
    } = user_analyze;
    let newValue = {
     

      sign_analyze: !!homepage_analyze?.sign_analyze && JSON.parse(homepage_analyze?.sign_analyze)?.map(i => {
        i.checked = true;
        return i
      }).concat(sign_analyze).filter((item,index,self) => {
        return self.findIndex(el=>el.title==item.title)===index
      }) || sign_analyze,

      name_analyze: !!homepage_analyze?.name_analyze && JSON.parse(homepage_analyze?.name_analyze)?.map(i => {
        i.selected = true;
        return i
      }).concat(name_analyze).filter((item,index,self) => {
        return self.findIndex(el=>el.title==item.title)===index
      }) || name_analyze,

      // name_analyze: [...name_analyze,...JSON.parse(homepage_analyze?.name_analyze)?.map(i => {
      //   i.selected = false;
      //   return i
      // })],
      head_img_analyze: !!homepage_analyze?.head_img_analyze && JSON.parse(homepage_analyze?.head_img_analyze)?.map(i => {
        i.selected = true;
        return i
      }).concat(head_img_analyze).filter((item,index,self) => {
        return self.findIndex(el=>el.title==item.title)===index
      }) || head_img_analyze,
      // head_img_analyze: [...head_img_analyze,...JSON.parse(homepage_analyze?.head_img_analyze)?.map(i => {
      //   i.selected = false;
      //   return i
      // })],
      character_analyze: !!role_analyze?.character_analyze && JSON.parse(role_analyze?.character_analyze)?.map(i => {
        i.selected = true;
        return i
      }).concat(character_analyze).filter((item,index,self) => {
        return self.findIndex(el=>el.title==item.title)===index
      }) || character_analyze,
     
      daily_style_analyze: !!role_analyze?.daily_style_analyze && JSON.parse(role_analyze?.daily_style_analyze)?.map(i => {
        i.selected = true;
        return i
      }).concat(daily_style_analyze).filter((item,index,self) => {
        return self.findIndex(el=>el.title==item.title)===index
      }) || daily_style_analyze,
      // daily_style_analyze: [...daily_style_analyze,...JSON.parse(character_analyze?.daily_style_analyze)?.map(i => {
      //   i.selected = false;
      //   return i
      // })],

      appearance_analyze: !!role_analyze?.appearance_analyze && JSON.parse(role_analyze?.appearance_analyze)?.map(i => {
        i.selected = true;
        return i
      }).concat(appearance_analyze).filter((item,index,self) => {
        return self.findIndex(el=>el.title==item.title)===index
      }) || appearance_analyze,

      // appearance_analyze: [...appearance_analyze,...JSON.parse(character_analyze?.appearance_analyze)?.map(i => {
      //   i.selected = false;
      //   return i
      // })], 

      scene_analyze: !!role_analyze?.scene_analyze && JSON.parse(role_analyze?.scene_analyze)?.map(i => {
        i.selected = true;
        return i
      }).concat(scene_analyze).filter((item,index,self) => {
        return self.findIndex(el=>el.title==item.title)===index
      }) || scene_analyze,

      // scene_analyze: [...scene_analyze,...JSON.parse(character_analyze?.scene_analyze)?.map(i => {
      //   i.selected = false;
      //   return i
      // })], 

      realization_analyze: !!business_analyze?.realization_analyze && JSON.parse(business_analyze?.realization_analyze) || [],

      // realization_analyze: [...realization_analyze,...JSON.parse(business_analyze?.realization_analyze)?.map(i => {
      //   i.checked = false;
      //   return i
      // })],

      // age_distribution_analyze: JSON.parse(user_analyze?.age_distribution_analyze)?.map(i => {
      //   i.checked = true;
      //   return i
      // }).concat(age_distribution_analyze).filter((item,index,self) => {
      //   return self.findIndex(el=>el.title==item.title)===index
      // }),
      age_distribution_analyze: user_analyze?.age_distribution_analyze && JSON.parse(user_analyze?.age_distribution_analyze) || [],
      // age_distribution_analyze: [...age_distribution_analyze,...JSON.parse(user_analyze?.age_distribution_analyze)?.map(i => {
      //   i.checked = false;
      //   return i
      // })],
      conclusion,
      summary,
      realization_think,
      is_match_appearance, 
      speak_style_analyze,

      content_style_analyze,
      content_type_analyze,
      trade_analyze,

      gender_analyze, 
      consumption_analyze,

      title
    }
    partForm.setFieldsValue({
      ...newValue
    })
  }
  const onValuesChange = useCallback((changedValues) => {
    if (changedValues.hasOwnProperty('name_analyze')) {
      
      partForm.setFieldsValue({
        name_analyze: changedValues.name_analyze
      });
    }
  }, []);
  const changedForm = async(formvalues,status) => {
    message.destroy()
    try {
    const { dt_id, dtte_id} = props;
    let {age_distribution_analyze, appearance_analyze, character_analyze, daily_style_analyze, head_img_analyze, name_analyze, realization_analyze, scene_analyze, sign_analyze} = formvalues;

    
    age_distribution_analyze =JSON.stringify(age_distribution_analyze);

    let new_appearance_analyze = JSON.stringify(appearance_analyze.filter(i => i.selected)?.map(i => {
      return {
        title:i.title,
        inner:i.inner
      }
    }))
    if (new_appearance_analyze === '[]') {
      new_appearance_analyze = ''
    }
    let new_character_analyze = JSON.stringify(character_analyze.filter(i => i.selected)?.map(i => {
      return {
        title:i.title,
        inner:i.inner
      }
    }))
    if (new_character_analyze === '[]') {
      new_character_analyze = ''
    }
    let new_daily_style_analyze = JSON.stringify(daily_style_analyze.filter(i => i.selected)?.map(i => {
      return {
        title:i.title,
        inner:i.inner
      }
    }))
    if (new_daily_style_analyze === '[]') {
      new_daily_style_analyze = ''
    }
    let new_head_img_analyze = JSON.stringify(head_img_analyze.filter(i => i.selected)?.map(i => {
      // delete i.selected;
      return {
        title:i.title,
        inner:i.inner
      }
    }))
    if (new_head_img_analyze === '[]') {
      new_head_img_analyze = ''
    }
    let new_name_analyze = JSON.stringify(name_analyze.filter(i => i.selected)?.map(i => {
      return {
        title:i.title,
        inner:i.inner
      }
    }))
    if (new_name_analyze === '[]') {
      new_name_analyze = ''
    }
    let new_realization_analyze = JSON.stringify(realization_analyze)
    
    if (new_realization_analyze === '[]') {
      new_realization_analyze = ''
    }
    let new_scene_analyze = JSON.stringify(scene_analyze.filter(i => i.selected)?.map(i => {
      return {
        title:i.title,
        inner:i.inner
      }
    }))
    if (new_scene_analyze === '[]') {
      new_scene_analyze = ''
    }
    let new_sign_analyze = JSON.stringify(sign_analyze.filter(i => i.checked)?.map(i => {
      return {
        title:i.title,
        inner:i.inner
      }
    }))
    if (new_sign_analyze === '[]') {
      new_sign_analyze = ''
    }
    const values = {age_distribution_analyze, appearance_analyze:new_appearance_analyze, character_analyze:new_character_analyze, daily_style_analyze:new_daily_style_analyze, head_img_analyze:new_head_img_analyze, name_analyze:new_name_analyze, realization_analyze: new_realization_analyze, scene_analyze:new_scene_analyze, sign_analyze:new_sign_analyze}
    if (status === 0) {
      if (dtte_id === '0') {
        await save({...formvalues,...values,dt_id,status})
      } else {
        await save({...formvalues,...values,dt_id,status, dtte_id})
      }
      message.success('草稿保存成功')
      setTimeout(() => {
        history.push(`/daren`)
      }, 1000);
    } else if (status === 1) {
      if (dtte_id === '0') { 
        await save({...formvalues,...values,dt_id,status})
      } else {
        await save({...formvalues,...values,dt_id,status,dtte_id})
      }
      message.success('提交成功')
      setTimeout(() => {
        history.push(`/daren`)
      }, 1000);
    }
  } catch(errorInfo) {
    message.error(errorInfo?.msg || '提交失败')
    console.log(errorInfo);
  }
  }
  const toList = () => {
    history.push(`/daren`)
  }
  const handleShare = async() => {
    try {
      const formvalues = await partForm.getFieldsValue();
      const status = 0
      setLoading(true)
      changedForm(formvalues,status)
    } catch(errorInfo) {
      console.log(errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  const handleOk = async() => {
    try {
      partForm.submit()
      const formvalues = await partForm.validateFields();
      const status = 1
      setLoading(true)
      changedForm(formvalues,status)
    } catch(errorInfo) {
      console.log(errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  const change_sign_analyze = useCallback((e,dataSource,item) => {
    const sign_analyze = dataSource
    const val = e.target.value
    sign_analyze?.map(i => {
      if (val === i.title && e?.type !== 'blur') {
         i.title = val
         i.checked = e.target.checked
      }
      if (e?.type === 'blur') {
        if (item.title === i.title ) {
          i.inner = val
       }
      }
      return i;
    } ) 
    
    partForm.setFieldsValue({'sign_analyze':
      sign_analyze
    })
  }, [partForm])

  const change_realization_analyze = useCallback((e,dataSource,item) => {
    const realization_analyze = dataSource
    const val = e.target.value
    realization_analyze?.map(i => {
      if (val === i.title ) {
         i.title = val
         i.checked = e.target.checked
      }
      if (e?.type === 'blur') {
        if (item.title === i.title ) {
          i.inner = val
       }
      }
      return i;
    } ) 
    partForm.setFieldsValue({'realization_analyze':
    realization_analyze
    })
  }, [partForm])
  

  const change_name_analyze = useCallback((e,dataSource,item) => {
    const name_analyze = dataSource
    name_analyze?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title ) {
          i.inner = e.target.value
       }
      } else if (e === i.title ){
        i.selected = true
      } else {
        i.selected = false
      }
      return i;
    } ) 
    partForm.setFieldsValue({'name_analyze':
    name_analyze
    })
  }, [partForm])

  const change_head_img_analyze = useCallback((e,dataSource,item) => {
    const head_img_analyze = dataSource
    head_img_analyze?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title ) {
          i.inner = e.target.value
       }
      } else if (e === i.title ){
        i.selected = true
      } else {
        i.selected = false
      }
      return i;
    } ) 
    partForm.setFieldsValue({'head_img_analyze':
    head_img_analyze
    })
  }, [partForm])
  const change_character_analyze = useCallback((e,dataSource,item) => {
    const character_analyze = dataSource
    character_analyze?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title ) {
          i.inner = e.target.value
       }
      } else if (e === i.title ){
        i.selected = true
      } else {
        i.selected = false
      }
      return i;
    } ) 
    partForm.setFieldsValue({'character_analyze':
    character_analyze
    })
  }, [partForm])
  const change_appearance_analyze = useCallback((e,dataSource,item) => {
    const appearance_analyze = dataSource
    appearance_analyze?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title ) {
          i.inner = e.target.value
       }
      } else if (e === i.title ){
        i.selected = true
      } else {
        i.selected = false
      }
      return i;
    } ) 
    partForm.setFieldsValue({'appearance_analyze':
    appearance_analyze
    })
  }, [partForm])
  const change_scene_analyze = useCallback((e,dataSource,item) => {
    const scene_analyze = dataSource
    scene_analyze?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title ) {
          i.inner = e.target.value
       }
      } else if (e === i.title ){
        i.selected = true
      } else {
        i.selected = false
      }
      return i;
    } ) 
    partForm.setFieldsValue({'scene_analyze':
    scene_analyze
    })
  }, [partForm])
  
  const check_head_img_analyze = (rule, value) => {
    const checvalue = JSON.stringify(value.filter(i => i.selected))
    if (checvalue === '[]' || !checvalue ) {
      return Promise.reject('请选择该账号的头像图片类型')
    } else {
      return Promise.resolve(); 
    }
  }
  const check_sign_analyze = (rule, value) => {
    let { getFieldValue } = partForm;
    const checvalue = JSON.stringify(getFieldValue('sign_analyze').filter(i => i?.checked !== false))
    if (checvalue === '[]' || !checvalue ) {
      return Promise.reject('请选择该账号的签名')
    } else {
      return Promise.resolve(); 
    }
  }
  const check_character_analyze = (rule, value) => {
    if(Array.isArray(value)) {

    }
    const checvalue = JSON.stringify(value.filter(i => i.selected))
    if (checvalue === '[]' || !checvalue ) {
      return Promise.reject('请选择该账号的头像图片类型')
    } else {
      return Promise.resolve(); 
    }
  }
  const check_name_analyze = (rule, value) => {
    if(Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.selected))
      if (checvalue === '[]' || !checvalue ) {
        return Promise.reject('请选择该账号的取名方式')
      } else {
        return Promise.resolve(); 
      }
    }
  }
  const check_daily_style_analyze = (rule, value) => {
    const checvalue = JSON.stringify(value.filter(i => i.selected))
    if (checvalue === '[]' || !checvalue ) {
      return Promise.reject('请选择他在镜头里的穿衣风格')
    } else {
      return Promise.resolve(); 
    }
  }
  const check_appearance_analyze = (rule, value) => {
    if(Array.isArray(value)) {
      const checvalue = JSON.stringify(value.filter(i => i.selected))
      if (checvalue === '[]' || !checvalue ) {
        return Promise.reject('请选择他的外在形象（气质）')
      } else {
        return Promise.resolve(); 
      }
    }
  }
  const check_scene_analyze = (rule, value) => {
    if(Array.isArray(value)) {
      const checvalue = JSON.stringify(value.filter(i => i.selected))
      if (checvalue === '[]' || !checvalue ) {
        return Promise.reject('请选择视频的主要拍摄场景')
      } else {
        return Promise.resolve(); 
      }
    }
  }
  const change_daily_style_analyze = useCallback((e,dataSource,item) => {
    const daily_style_analyze = dataSource
    daily_style_analyze?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title ) {
          i.inner = e.target.value
       }
      } else if (e === i.title ){
        i.selected = true
      } else {
        i.selected = false
      }
      return i;
    } )
    partForm.setFieldsValue({'daily_style_analyze':
    daily_style_analyze
    })
  }, [partForm])

  

  const change_age_distribution_analyze = useCallback((e,dataSource,item) => {
    const age_distribution_analyze = dataSource
    const val = e.target.value
    age_distribution_analyze?.map(i => {
      if (val === i.title ) {
         i.title = val
         i.checked = e.target.checked
      }
      if (e?.type === 'blur') {
        if (item.title === i.title ) {
          i.inner = val
       }
      }
      return i;
    })
    partForm.setFieldsValue({'age_distribution_analyze':
    age_distribution_analyze
    })
  }, [partForm])

  
  const change_content_type_analyze = useCallback((e) => {
    partForm.setFieldsValue({'content_type_analyze':
    e.target.value,
    })
  }, [partForm])
  const change_is_match_appearance = useCallback((e) => {
    partForm.setFieldsValue({'is_match_appearance':
    e.target.value,
    })
  }, [partForm])
  const change_gender_analyze = useCallback((e) => {
    partForm.setFieldsValue({'gender_analyze':
    e.target.value,
    })
  }, [partForm])
  const change_consumption_analyze = useCallback((e) => {
    partForm.setFieldsValue({'consumption_analyze':
    e.target.value,
    })
  }, [partForm])
  
  const change_content_style_analyze = useCallback((e) => {
    partForm.setFieldsValue({'content_style_analyze':
    e.target.value,
    })
  }, [partForm])
  
  return <div className={styles.part}>
     <Spin size="large" spinning={loading}>
<Form
      name="partForm"
      // labelWrap
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      // initialValues={{ remember: true }}
      colon={false}
      form={partForm}
      onValuesChange={onValuesChange}
      className={'partForm'}
      // onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      // autoComplete="off"
    >
      <Form.Item
        label="拆解分析标题："
        name="title"
        rules={[{ required: true, message: '请输入分析标题' }]}
      >
        <TextArea maxLength={200} autoSize={{ minRows: 2, maxRows: 2 }} />
      </Form.Item>
      <div className={styles.titleBox}>
        <span className={styles.title}>
        账号主页分析
        </span >
        <span className={styles.content}>
        （账号主页包含昵称、个性签名、头像、背景4大主要部分，重点关注前2部分)
        </span>
      </div>
      <Form.Item
        label="该账号的取名方式："
        name="name_analyze"
       
        rules={[{ required: true,validator: check_name_analyze}]}
      >
        <Selects dataSource={partForm.getFieldValue('name_analyze')} onChange={change_name_analyze} />
      </Form.Item>
      
      <Form.Item
        label="该账号签名包含哪些内容："
        name="sign_analyze"
        // style={{marginTop: '-32px'}}
        rules={[{ required: true,validator: check_sign_analyze}]}
      >
        <Check dataSource ={partForm.getFieldValue('sign_analyze')} onChange={change_sign_analyze} />
         
      </Form.Item> 

      <Form.Item
        label="该账号的头像图片是什么类型："
        name="head_img_analyze"
       
        rules={[{ required: true,validator: check_head_img_analyze}]}
      >
        <Selects dataSource={partForm.getFieldValue('head_img_analyze')} onChange={change_head_img_analyze} />
      </Form.Item>

      <div className={styles.titleBox}>
        <span className={styles.title}>
        账号定位
        </span >
        <span className={styles.content}>
        （主页分析完毕，可浏览视频内容深入了解账号，并从内容、用户、人设、商业四大维度来做定位分析）
        </span>
      </div>

      <div className={styles.tabBox}>
        <span className={styles.tabName}>
        1、内容定位
        </span >
        <span className={styles.tabContent}>
        （重点分析该账号的视频内容）
        </span>
      </div>

      <Form.Item
        label="视频内容属于哪个行业："
        name="trade_analyze"
        rules={[{ required: true, message:'请选择视频内容所属哪个行业'}]}
      >
        <Select
            allowClear
            defaultValue=''
            showArrow={true}
        >
            {
              tradeList?.map(i => {
                return <Option key={i} value={i}>{i}</Option>
              })
            }
        </Select>
      </Form.Item>

      <Form.Item
        label="内容是宽泛的还是精准的："
        name="content_type_analyze"
        rules={[{ required: true}]}
      >
        <Radio.Group onChange={change_content_type_analyze} >
          <Space direction="vertical">
            <Radio value={'精准'}> 精准</Radio>
            <Radio value={'宽泛'}>宽泛</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="各个视频的内容风格是否统一："
        name="content_style_analyze"
        rules={[{ required: true}]}
      >
        <Radio.Group onChange={change_content_style_analyze} >
          <Space direction="vertical">
            <Radio value={'统一'}> 统一</Radio>
            <Radio value={'不统一'}>不统一</Radio>
          </Space>
        </Radio.Group>
       
      </Form.Item>

      <div className={styles.tabBox}>
        <span className={styles.tabName}>
        2、人设定位
        </span >
        <span className={styles.tabContent}>
        （重点分析该账号IP的人设）
        </span>
      </div>
      <Form.Item
        label="该达人的人设身份是什么："
        name="character_analyze"
        rules={[{ required: true,  validator: check_character_analyze}]}
      >
        <Selects dataSource={partForm.getFieldValue('character_analyze')} onChange={change_character_analyze} />
      </Form.Item>
      <Form.Item
        label="他说话的方式怎么样："
        name="speak_style_analyze"
        rules={[{ required: true, message: '可从音量、语速、口音等多角度分析' }]}
      >
         <TextArea maxLength={200} placeholder='可从音量、语速、口音等多角度分析' autoSize={{ minRows: 2, maxRows: 2 }} />
      </Form.Item>
      <Form.Item
        label="他在镜头里的穿衣风格："
        name="daily_style_analyze"
       
        rules={[{ required: true, validator: check_daily_style_analyze}]}
      >
        <Selects dataSource={partForm.getFieldValue('daily_style_analyze')} onChange={change_daily_style_analyze} />
      </Form.Item>
      <Form.Item
        label="他的外在形象（气质）如何："
        name="appearance_analyze"
       
        rules={[{ required: true, validator: check_appearance_analyze}]}
      >
        <Selects dataSource={partForm.getFieldValue('appearance_analyze')} onChange={change_appearance_analyze} />
      </Form.Item>
      <Form.Item
        label="视频的主要拍摄场景是什么："
        name="scene_analyze"
       
        rules={[{ required: true, validator: check_scene_analyze}]}
      >
        <Selects dataSource={partForm.getFieldValue('scene_analyze')} onChange={change_scene_analyze} />
      </Form.Item>

      <Form.Item
        label="该达人的人设形象和账号是否匹配："
        name="is_match_appearance"
        rules={[{ required: true}]}
      >
        <Radio.Group onChange={change_is_match_appearance} >
          <Space direction="vertical">
            <Radio value={'匹配'}> 匹配</Radio>
            <Radio value={'不匹配'}>不匹配</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <div className={styles.tabBox}>
        <span className={styles.tabName}>
        3、用户定位
        </span >
        <span className={styles.tabContent}>
        （根据账号提供的内容，大致判断该账号面向怎样的用户群体）
        </span>
      </div>
      <Form.Item
        label="目标用户性别偏向："
        name="gender_analyze"
        rules={[{ required: true}]}
      >
        <Radio.Group onChange={change_gender_analyze} >
          <Space direction="vertical">
            <Radio value={'男'}> 男</Radio>
            <Radio value={'女'}>女</Radio>
            <Radio value={'不限'}>不限</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="目标用户年龄分布："
        name="age_distribution_analyze"
        // style={{marginTop: '-32px'}}
        rules={[{ required: true}]}
      >
        <Select
            mode="multiple"
            allowClear
            showArrow={true}
          >
            {
              age_distribution_analyze?.map(i => {
                return <Option key={i.title} value={i.title}>{i.title}</Option>
              })
            }
        </Select>
      </Form.Item>
      
      <Form.Item
        label="目标用户消费能力："
        name="consumption_analyze"
        rules={[{ required: true}]}
      >
        <Radio.Group onChange={change_consumption_analyze} >
          <Space direction="vertical">
            <Radio value={'一般'}>一般</Radio>
            <Radio value={'较好'}>较好</Radio>
            <Radio value={'很好'}>很好</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <div className={styles.tabBox}>
        <span className={styles.tabName}>
        4、商业定位
        </span >
        <span className={styles.tabContent}>
        （可从商品橱窗、视频小黄车、直播、粉丝群、私聊等角度分析该账号的商业定位）
        </span>
      </div>
      
      <Form.Item
        label="账号的变现形式："
        name="realization_analyze"
        // style={{marginTop: '-32px'}}
        rules={[{ required: true}]}
      >
        <Select
            mode="multiple"
            allowClear
            showArrow={true}
          >
            {
              [...realization_analyze]?.map(i => 
                 <Option key={i?.title} value={i?.title}>{i?.title}</Option>
              )
            }
        </Select>
      </Form.Item>
      <Form.Item
        label="你还能想到的哪些适用 于该账号的变现方式："
        name="realization_think"
        rules={[{ required: false, message: '请输入' }]}
      >
        <TextArea maxLength={200} autoSize={{ minRows: 2, maxRows: 2 }} />
      </Form.Item>
      <div className={styles.titleBox}>
        <span className={styles.title}>
        账号整体分析
        </span >
        <span className={styles.content}>
        （分析完账号的主页和定位后，再来做个总结吧）
        </span>
      </div>
      <Form.Item
        label="用50字左右概述该账号："
        name="summary"
        
        rules={[{ required: true, message: '请输入' }]}
      >
        <TextArea maxLength={200} autoSize={{ minRows: 2, maxRows: 2 }} />
      </Form.Item>
      <Form.Item
        label="该账号有哪些可借鉴的
        亮点和需要改进的不足："
        name="conclusion"
        rules={[{ required: true, message: '请输入' }]}
      >
        <TextArea maxLength={200} autoSize={{ minRows: 2, maxRows: 2 }} />
      </Form.Item>
      
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary"  style={{marginRight: '16px'}} onClick={handleOk}>
          发布
        </Button>
        <Button  style={{marginRight: '16px'}} onClick={handleShare}>
          保存为草稿
        </Button>
        {/* <Popconfirm title="确定不保存草改吗" okText="确定" cancelText="保存草稿" onCancel={handleShare} onConfirm={toList}> */}
          <Button onClick={toList} >
            取消
          </Button>
        {/* </Popconfirm> */}
      </Form.Item>
    </Form>
    </Spin>
  </div>
}
export default Part;

