import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>
      <Form.Item key='4087' name='shop_name' label='商家名称' className ='searchFormItem'><Input placeholder='商家名称' /></Form.Item>


    
      <Form.Item key='4089' name='sku_name' label='商品名称' className ='searchFormItem'><Input placeholder='商品名称' /></Form.Item>


    


    
      <Form.Item key='4098' label='好评数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4098_start' name='good_comments_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4098_end' name='good_comments_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4099' name='is_zy' label='自营' className = 'searchFormItem'>
        <Select
            placeholder='自营'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>是</Option>
                <Option key='0' value='0'>否</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='4086' label='付款价' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4086_start' name='final_price_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4086_end' name='final_price_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4091' label='原价' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4091_start' name='lower_price_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4091_end' name='lower_price_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4095' label='佣金比例(%)' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4095_start' name='wl_commission_ratio_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4095_end' name='wl_commission_ratio_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4093' label='佣金' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4093_start' name='wl_commission_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4093_end' name='wl_commission_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4092' label='优惠券' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4092_start' name='coupon_discount_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4092_end' name='coupon_discount_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4097' label='30天内总支出佣金' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4097_start' name='in_order_comm_30days_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4097_end' name='in_order_comm_30days_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4146' label='30天内有佣金的销量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4146_start' name='in_order_com_count_30days_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4146_end' name='in_order_com_count_30days_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    


    
      <Form.Item key='4145' name='record' label='备注' className = 'searchFormItem'>
        <Select
            placeholder='备注'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='0' value='0'>暂无</Option>
                <Option key='1' value='1'>高佣-价格低-购买频率高</Option>
                <Option key='2' value='2'>高佣-价格适中-购买频率适中</Option>
                <Option key='3' value='3'>高佣-价格高-购买低-出一单吃一周</Option>
                <Option key='4' value='4'>非常高佣-不容易出单-出一单吃一月</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='4102' label='优惠券剩余数量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4102_start' name='coupon_remain_cnt_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4102_end' name='coupon_remain_cnt_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
</Fragment>