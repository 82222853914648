// import { NewContract } from '@/components/business/new.contract';
import request from '@/utils/request';
import { controller_name } from './constant'
/***
 * 列表数据
 * @param params
 */
export async function getDataList(params) {
  return request({
    url: `jkapi/${controller_name}/index`,
    method: 'POST',
    data: params,
  });
}

/***
 * 长尾词搜索接口
 * @param params
 */
 export async function searchPre(keyword) {
  return request({
    url: `jkapi/${controller_name}/search`,
    method: 'POST',
    data: {
      keyword
    },
  });
}

