import { Fragment } from 'react'
import styles from './index.module.scss';
import { Input, Select } from 'antd'
const { Option } = Select;
const { TextArea } = Input;

const Selects = (props) => {
  const { dataSource } = props;
  const onChange = (e) => {
    props.onChange(e, [...dataSource])
  }
  const onBlur = (e, i) => {
    props.onChange(e, [...dataSource], i)
  }

  return <Fragment>
    {
      <Select
        placeholder='请选择'
        allowClear
        mode="multiple"
        showArrow={true}
        // key={dataSource?.filter(i => i.checked === true)[0]?.title}
        value={dataSource?.filter(i => i.checked === true)?.map(i => i.title)}

        onChange={onChange}
        getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
      >
        {
          dataSource?.map(i =>
            <Option key={i.title} value={i.title}>{i.title}</Option>
          )
        }
      </Select>
    }

    {
      dataSource?.map(i => {
        if (i.title === '其他' && i.checked) {
          return <TextArea
            onBlur={(e) => onBlur(e, i)}
            autoSize={{ minRows: 2, maxRows: 2 }}
            allowClear
            key={i}
            defaultValue={i.inner}
            placeholder='请输入'
            maxLength={300} rows={4} className={styles.textArea} />
        } else {
          return null
        }
      })
    }
  </Fragment>
}
export default Selects;