import styles from './index.module.scss';
import jike from '@/assets/img/jike.png'
import vip from '@/assets/img/vip.png'
import { Layout, Menu, Button, Form, List } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { createMenu } from './service'
import { useEffect, useState, useContext, useCallback, Fragment } from 'react';
import { GlobalContext } from '@/App'
import Notification from '@/components/notification'
import Login from '@/components/auth/login'
import Register from '@/components/auth/register';
import Wxbind from '@/components/auth/wxbind'
import My from '@/assets/img/my.png'
import User from './user';
const { Header } = Layout;
const { SubMenu } = Menu;
export default (props) => {
  const root = document.getElementById('root')
  const history = useHistory();
  const { user_info, loginVis, setloginVis } = useContext(GlobalContext);
  const [theme, settheme] = useState('light')
  const [current, setCurrent] = useState('')
  const [openKey, setOpenKey] = useState('')
  const [routerList, setRouterList] = useState([])
  const [vis, setVis] = useState({
    // 'login': loginVis,
    'wxbind': false,
    'register': false,
    // scene_id: null
  })
  useEffect(async () => {
    let list = await createMenu();
   
    // setRouterList(list)
    // let newlist = list.slice(0,2)
    let newlist = []
    newlist.push({
      controller_name: "",
      menu_id: 899,
      pid: 0,
      title: "抖音引流",
      child_menu: [{
        child_menu: [],
        controller_name: "/daren",
        menu_id: 'daren',
        pid: 'daren',
        title: "账号拆解",
      },{
        child_menu: [],
        controller_name: "/video",
        menu_id: 'video',
        pid: 'video',
        title: "短视频拆解",
      }]
    },{
      child_menu: [],
      controller_name: "/benchmarking",
      menu_id: 'benchmarking',
      pid: 'benchmarking',
      title: "对标库",
    }
    // ,
    // {
    //   child_menu: [],
    //   controller_name: "/tiktokHot",
    //   menu_id: 'TiktokHot',
    //   pid: 'TiktokHot',
    //   title: "抖音热点",
    // }
    )
    setRouterList(newlist)
    if (history.location.pathname === '/') {
      history.push('/daren')
    }
    root.addEventListener('click', onCloseChange)
    return () => {
      root.removeEventListener("click", onCloseChange)
    }
  }, [])
  
  const handleClick = e => {
    setCurrent(e.key);
    setOpenKey('')
    // if (e.key !== '/video') {
    //   sessionStorage.removeItem('selectedTab');
    //   sessionStorage.removeItem('pagination');
    //   sessionStorage.removeItem('searchParams');
    // }
  };
  const onCloseChange = e => {
    if (e.target.className !== 'ant-menu-title-content' && e.target.className !== 'ant-menu-submenu-title' && e.target.className !== 'ant-menu-submenu-arrow') {
      setOpenKey('');
    }
  };
  const onOpenChange = e => {
    console.log('click ', e);
    setOpenKey(e[1]);
  };
 
  const changeVisble = useCallback((vis, type, scene_id) => {
    if (!!vis) {
      if (vis === 'login') {
        setloginVis(true)
        setVis({
          // 'login': true,
          'wxbind': false,
          'register': false,
        })
      } else if (vis === 'wxbind') {
        setloginVis(false)
        setVis({
          // 'login': false,
          'wxbind': true,
          'register': false,
          type,
          scene_id
        })
      } else if (vis === 'register') {
        setloginVis(false)
        setVis({
          // 'login': false,
          'wxbind': false,
          'register': true,
        })
      }
    } else {
      setloginVis(false)
      setVis({
        // 'login': false,
        'wxbind': false,
        'register': false,
      })
    }
  }, [])
  const toIndex = () => {
    let indexPath;
    if (!!routerList.length) {
      if (!routerList[0].child_menu.length) {
        indexPath = routerList.controller_name
      } else {
        indexPath = routerList[0].child_menu[0].controller_name
      }
    }
    // 清除首页的缓存
    sessionStorage.setItem("dTab", '1');
    setTimeout(() => {
      history.push(indexPath)
    }, 0);
  }
  return (
    <div className={styles.headerBox}>
      <Header className={styles.header}>
        {
           loginVis && <Login changeVisble={changeVisble} key={'login'} />
          }
          {
            vis.register && <Register changeVisble={changeVisble} key={'register'}  />
          }
          {
            vis.wxbind && <Wxbind changeVisble={changeVisble} key={vis.type}  type={vis.type}
            scene_id={vis.scene_id} />
          }
        <div className={styles.tabs}>
          <img src={jike} alt="" onClick={toIndex} />
          <Menu
            theme={theme}
            onClick={handleClick}
            // style={{ width: 256 }}
            // defaultOpenKeys={['sub1']}
            selectedKeys={[current]}
            mode="inline"
            className="topmenu"
            key={current}
            onOpenChange={onOpenChange}
            openKeys={[openKey]}
          // triggerSubMenuAction={'hover'}
          >
            {
              routerList?.map(item => {
                if (!!item?.child_menu?.length) {

                  return <SubMenu key={item?.title} title={item.title} className='headerMenu' >
                    {item?.child_menu?.map(j => {
                      return <Menu.Item key={j?.controller_name}>
                        <NavLink to={j?.controller_name} exact className='headerLink'
                          activeClassName="active" key={j.controller_name}>{j.title}</NavLink>
                      </Menu.Item>
                    })}
                  </SubMenu>

                } else {
                  return <Menu.Item key={item?.title}>
                    <NavLink to={item?.controller_name} exact
                      activeClassName="active" key={item.title}>{item.title}</NavLink>
                  </Menu.Item>
                }
              })
            }
          </Menu>
        </div>

        <div className={styles.user}>
          {/* <div className={styles.userBuy} onClick={goods}>
            <img src={vip} alt="" />
            {
              user_info?.old_member && '续购7折' || '购买'
            }
          </div> */}
          {/* <div className={styles.line}>

          </div> */}
          {
            !user_info && (
              <div className={styles.loginBox}>
                <div  className={styles.login} onClick={() => {setloginVis(true);setVis({
                // 'login': true,
                'wxbind': false,
                'register': false,
              })}}>登录</div> | 
              <div className={styles.register} onClick={() => {setloginVis(false);setVis({
                // 'login': true,
                'wxbind': false,
                'register': true,
              })}}>注册</div>
              </div>
            ) || (
              <Menu
              theme={theme}
              onClick={handleClick}
              // style={{ width: 256 }}
              // defaultOpenKeys={['sub1']}
              selectedKeys={[current]}
              mode="inline"
              className="usermenu"
              key={current}
              onOpenChange={onOpenChange}
              openKeys={[openKey]}
            >
              <SubMenu key={'user'} icon={<img src={My} />} title={'我的'} className='topUsermenu' >
                    {/* <Menu.Item key={'userinfo'}> */}
                        <User />
                      {/* </Menu.Item> */}
                  </SubMenu>
                  </Menu>
            )
          }
        </div>
      </Header>
      {/* <Notification no={10} /> */}
    </div>
  )
}
