import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>
      <Form.Item key='3050' name='keyword' label='关键词' className ='searchFormItem'><Input placeholder='关键词' /></Form.Item>


    
      <Form.Item key='3051' label='排名' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3051_start' name='rank_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3051_end' name='rank_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3052' name='type' label='类型' className = 'searchFormItem'>
        <Select
            placeholder='类型'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='group' value='group'>小组</Option>
                <Option key='note' value='note'>日记</Option>
                <Option key='movie' value='movie'>电影</Option>
                <Option key='doulist' value='doulist'>豆列</Option>
                <Option key='location' value='location'>同城</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='3064' name='title' label='标题' className ='searchFormItem'><Input placeholder='标题' /></Form.Item>


    
      <Form.Item key='3067' label='总搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3067_start' name='baidu_index_all_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3067_end' name='baidu_index_all_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3055' label='PC搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3055_start' name='bidword_pcpv_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3055_end' name='bidword_pcpv_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3056' label='移动搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3056_start' name='bidword_wisepv_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3056_end' name='bidword_wisepv_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3059' label='竞价公司数量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3059_start' name='bidword_companycount_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3059_end' name='bidword_companycount_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
</Fragment>