import { notification } from 'antd';

const Notification = (props) => {
  return !!props.no && notification.open({
    message: '',
    maxCount: 1,
    description:<span>本次搜索为您筛选出<span style={{color: '#52C41A'}}>{props.no}</span>条数据</span>,
    // onClick: () => {
    //   console.log('Notification Clicked!');
    // },
  }) || null;
};
export default Notification