import { SyncOutlined, CaretRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { message, Spin, Tooltip } from 'antd'
import { useState, useEffect, useContext } from 'react';
import { worksList } from '../../constant'
import showcode from "@/assets/img/showcode.png";
import VideoInfoItem from '@/components/videoInfoItem'
import PartList from './../partList';
import zf from '@/assets/img/zf.png'
import v2 from '@/assets/img/v2.png'
import v3 from '@/assets/img/v3.png'
import { getInfo, getRecommendDisbleList } from '../../service'
import styles from './index.module.scss'
import ShowVip from '@/components/showVip';
import Addvip from '@/components/addVip'
import { GlobalContext } from '@/App'
const Works = (props) => {
  const history = useHistory();
  const { user_info, setloginVis } = useContext(GlobalContext);
  const [showAddVip, setShowAddVip] = useState(false)
  const [info, setInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [url, seturl] = useState(null)
  const [isNext, setIsNext] = useState(true)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 4,
    total: 0,
    // showTotal: (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` },
    // defaultPageSize: 3,
    // showSizeChanger: true, // 显示可改变每页数量
    // pageSizeOptions: [20, 50, 100, 500], // 每页数量选项
  });
  const [recommendList, setRecommendList] = useState([])
  useEffect(() => {
    getInfoFun()
    getRecommendDisbleListFun()
    worksList[0] = {
      "title": "视频信息", "dataIndex": "info", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": false, "note": "", "name": "视频信息", "key": "info", render: (tags, record, i) => (
        <div className='worksInfo'>
          <div className="worksimg">
            <img src={record?.cover_img} alt="" />
            <div className="play" onClick={() => toPlay(record?.url || null, i)}>
              <CaretRightOutlined />
            </div>
          </div>
          <div className="infotitle" onClick={() => tocode(record)}>
            {record.title}
          </div>
        </div>
      )
    }
    if (!user_info?.is_vip) {
      document.getElementById('root').scrollTop = 0
      document.getElementById('root').style.overflow = 'hidden'
    } else {
      document.getElementById('root').style.overflow = 'auto'
    }
    return resetscroll
  }, [props.match.params.video_id, user_info?.is_vip])
  const next = () => {
    isNext && getRecommendDisbleListFun()
  }
  const resetscroll = () => {
    document.getElementById('root').style.overflow = 'auto'
  }
  const getInfoFun = async () => {
    message.destroy()
    setLoading(true)
    try {
      const res = await getInfo(props.match.params.video_id);
      setInfo(res)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  const goVip = () => {
    if (!!user_info) {
      setShowAddVip(true)
    } else {
      setloginVis(true)
      // history.push('/login')
    }
  }
  const getRecommendDisbleListFun = async () => {
    message.destroy()
    setLoading(true)
    try {

      const last_id = recommendList?.length != 0 ? recommendList[3]?.video_id : null
      const res = await getRecommendDisbleList({
        video_id: props.match.params.video_id,
        last_id
      });
      setIsNext(res?.count <= 4 ? false : true)
      setRecommendList(res?.list || [])
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    // pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    setPagination({ ...pagination });
  };
  const tocode = (r) => {
    window.open('https://www.iesdouyin.com/share/video/' + r?.video_id)
  }
  const toClose = () => {
    seturl(null)
  }
  const toPre = () => {
    if (url === 0) {
      message.destroy()
      message.warning('已经是第一个')
    } else {
      seturl(url - 1)
    }
  }
  const toNext = () => {
    if (url === 3) {
      message.destroy()
      message.warning('已经是最后一个')
    } else {
      seturl(url + 1)
    }
  }
  const toPlay = (url, i) => {
    seturl(i)
  }
  const handleClick = (i) => {
    history.push('/PopVideoInfo/' + i?.video_id)
  }
  const toVideo = () => {
    window.open('https://www.iesdouyin.com/share/video/' + info.video_id)
  }
  const toIndex = () => {
    // history.push('/works/' + props.match.params.dt_id)
    window.open('https://www.douyin.com/user/' + info?.sec_uid)
  }
  return <div className={styles.works}>
    <Spin size="large" spinning={loading}>
      <div className={styles.bread}>
        <div className={styles.breadItem}>
          拆解详情
        </div>
        {/* <div className={styles.teach} onClick={toTutorials}>
          <FileTextOutlined style={{ color: '#25B864', marginRight: '4px' }} />
          页面使用教程
        </div> */}
      </div>
      <div className={styles.worksContent}>
        <div className={styles.woksLeft}>
          <div className={styles.recommend}>

            <div className={styles.topTenList}>
              {
                !!info?.url && (
                  <video controls key={info?.url}
                    controlsList="nodownload"
                    name="media">
                    <source src={info?.url} type="video/mp4" />
                  </video>
                )
              }
              <div className={styles.topTenListTitle} onClick={toVideo}>
                {
                  info?.title
                }
                {/* {topTenList[index]?.title} */}
              </div>
              {
                !!info?.comment_num && (

                  <div className={styles.videoInfo}>
                    <div className={styles.infoItem}>
                      <img src={zf} alt="" />&nbsp;
                      {info?.forward_num}
                    </div>
                    <div className={styles.infoItem}>
                      <img src={v2} alt="" />&nbsp;
                      {info?.like_num}
                    </div>
                    <div className={styles.infoItem}>
                      <img src={v3} alt="" />&nbsp;
                      {info?.comment_num}
                    </div>
                  </div>
                )
              }
              {/* <Button className='toIndex' style={{ fontSize: '16px'}} onClick={toIndex} >前往达人作品主页</Button> */}


              <div className={styles.user_message} >
                <div className={styles.userBox} onClick={toIndex}>
                  <div className={styles.user_img}>
                    <img src={info?.head_img} alt="" />
                  </div>
                  <p>{info?.nickname}</p>
                  <Tooltip
                    placement="bottomLeft"
                    getPopupContainer={() => document.getElementById("root")}
                    overlayClassName="codeTooltip"
                    title={
                      <>
                        <img className="dareninfocode" src={info?.qr_code} />
                        <div className="dareninfocodeTitle">
                          抖音扫一扫，查看达人主页
                        </div>
                      </>
                    }
                    color="#ffffff"
                  >
                    {
                      !!info?.qr_code && (

                        <div className={styles.code_icon}>
                          <img src={showcode} alt="" />
                        </div>
                      )
                    }
                  </Tooltip>
                </div>
                {/* <div></div> */}
              </div>

            </div>
          </div>

          {
            !!recommendList && !!recommendList?.length && (
              <div className={styles.recommend}>
                <div className={styles.title}>
                  推荐相关短视频
                </div>
                <div className={isNext ? styles.change : styles.unchange} onClick={next}>
                  <SyncOutlined />
                  &nbsp;换一批
                </div>

                <div className={styles.recommendList}>
                  {
                    recommendList?.map(i => <VideoInfoItem record={i} handleClick={() => handleClick(i)} /> || null)
                  }
                </div>
              </div>
            )
          }

        </div>
        <div className={styles.woksRight}>
          {
            !user_info?.is_vip && (
              <ShowVip goVip={goVip}></ShowVip>
            )
          }
          {showAddVip && (
            <Addvip handleCancel={() => setShowAddVip(false)} />
          )
          }
          <PartList video_id={props.match.params.video_id} />
        </div>
      </div>
    </Spin>
  </div>
}
export default Works