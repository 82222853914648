// import { NewContract } from '@/components/business/new.contract';
import request from '@/utils/request';
import { controller_name } from './constant'
/***
 * banner数据
 * @param params
 */
export async function getDataList(params) {
  return request({
    url: `jkapi/DouyinTalent/index`,
    method: 'POST',
    data: params,
  });
}
/**
 * 达人行业
 */
 export async function gettradeList() {
  return request({
    url: `jkapi/DouyinTalent/tradeList`,
    method: 'POST',
    // data: params,
  });
}
/**
 * 达人内容类型
 */
 export async function getcontentTypeList() {
  return request({
    url: `/jkapi/DouyinTalent/contentTypeList`,
    method: 'POST',
    // data: params,
  });
}

/**
 * 爆款视频详情
 */
 export async function getInfo(video_id) {
  return request({
    url: `/jkapi/DouyinBenchmarkTalent/talentVideoInfo`,
    method: 'POST',
    data: {
      video_id
    },
  });
}

/**
 * 达人内容类型
 */
 export async function getRecommendList(params) {
  return request({
    url: `/jkapi/DouyinTalent/recommendList`,
    method: 'POST',
    data: params,
  });
}

/**
 * 推荐案例列表
 */
 export async function getRecommendDisbleCaseList(params) {
  return request({
    url: `/jkapi/DouyinShortVideoTeardowns/recommendList`,
    method: 'POST',
    data: params,
  });
}
/**
 * 推荐推荐视频列表
 */
 export async function getRecommendDisbleList(params) {
  return request({
    url: `/jkapi/DouyinShortVideo/recommendVideoList`,
    method: 'POST',
    data: params,
  });
}

/**
 * 达人作品
 */
 export async function getTalentVideoList(params) {
  return request({
    url: `/jkapi/DouyinShortVideo/talentVideoList`,
    method: 'POST',
    data: params,
  });
}

/**
 * 达人账号拆解top10
 */
 export async function getTopTenList(dt_id) {
  return request({
    url: `/jkapi/DouyinShortVideo/likeTopTen`,
    method: 'POST',
    data: {
      dt_id
    },
  });
}

/**
 * 我拆解的案例列表
 */
 export async function getTeardownsList(params) {
  return request({
    url: `/jkapi/DouyinTalentTeardowns/myTeardowns`,
    method: 'POST',
    data: params
  });
}

/**
 * 案例列表
 */
 export async function changeStatus(params) {
  return request({
    url: `/jkapi/DouyinTalentTeardowns/changeStatus`,
    method: 'POST',
    data: params
  });
}

/**
 * 拆解案例发布
 */
 export async function save(params) {
  return request({
    url: `/jkapi/DouyinShortVideoTeardowns/save`,
    method: 'POST',
    data: params
  });
}

/**
 * 拆解案例发布
 */
export async function disassembleInfo(dtte_id) {
  return request({
    url: `/jkapi/DouyinTalentTeardowns/info`,
    method: 'POST',
    data: {
      dtte_id
    }
  });
}
export async function teardownsList(params) {
  return request({
    url: `/jkapi/DouyinShortVideoTeardowns/videoTeardownsList`,
    method: 'POST',
    data: params
  });
}
/**
 * 
 * 拆解案例详情
 * @returns 
 */
 export async function dsvtInfo(dsvt_id) {
  return request({
    url: `/jkapi/DouyinShortVideoTeardowns/info`,
    method: 'POST',
    data: {
      dsvt_id
    }
  });
}

/**
 * 拆解案例列表
 */
export async function disassembleList(param) {
  return request({
    url: `/jkapi/DouyinTalentTeardowns/talentTeardownsList`,
    method: 'POST',
    data: param
  });
}

/**
 * 拆解案例收藏取消
 */
export async function collectTeardowns(dsvt_id) {
  return request({
    url: `/jkapi/DouyinVideoTeardownsCollect/collectTeardowns`,
    method: 'POST',
    data: {
      dsvt_id
    }
  });
}

/**
 * 
 */
 export async function getCollectList(params) {
  return request({
    url: `/jkapi/DouyinTalentTeardownsCollect/myCollect`,
    method: 'POST',
    data: params
  });
}

/**
 * 
 */
 export async function getPeopleNum(video_id) {
  return request({
    url: `/jkapi/DouyinShortVideoTeardowns/peopleNum`,
    method: 'POST',
    data: {
      video_id
    }
  });
}

