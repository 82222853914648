import { FileTextOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { message, Button } from 'antd'
import { useState, useEffect, useContext } from 'react';
import InfoItem from '@/components/info'
import Part from './part'
import { getInfo, getTopTenList, getPeopleNum } from './../service'
import v1 from '@/assets/img/zf.png'
import v2 from '@/assets/img/v2.png'
import v3 from '@/assets/img/v3.png'
import styles from './index.module.scss'
import ShowVip from '@/components/showVip';
import Addvip from '@/components/addVip'
import { GlobalContext } from '@/App'
const Works = (props) => {
  const history = useHistory();
  const { user_info, setloginVis } = useContext(GlobalContext);
  const [showAddVip, setShowAddVip] = useState(false)
  const [info, setInfo] = useState(null)
  const [peopleNum, setPeopleNum] = useState(0)
  const [loading, setLoading] = useState(false)
  const [index, setIndex] = useState(0)
  const [topTenList, setTopTenList] = useState([])
  useEffect(() => {
    getInfoFun()
    getPeopleNumFun()
    getTopTenListFun()
    if (!user_info?.is_vip) {
      document.getElementById('root').scrollTop = 0
      document.getElementById('root').style.overflow = 'hidden'
    } else {
      document.getElementById('root').style.overflow = 'auto'
    }
    return resetscroll
  }, [props.match.params.dt_id, user_info?.is_vip])
  const resetscroll = () => {
    document.getElementById('root').style.overflow = 'auto'
  }
  // useEffect(() =>  {
  //   if (!!topTenList.length) {
  //     setIndex(0)
  //   }
  // }, [topTenList])
  const getPeopleNumFun = async () => {
    message.destroy()
    setLoading(true)
    try {
      const res = await getPeopleNum(props.match.params.dt_id);
      setPeopleNum(res || 0)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setLoading(false)
  }
  const getInfoFun = async () => {
    message.destroy()
    setLoading(true)
    try {
      const res = await getInfo(props.match.params.dt_id);
      setInfo(res)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setLoading(false)
  };
  const getTopTenListFun = async () => {
    message.destroy()
    setLoading(true)
    try {
      const res = await getTopTenList(props.match.params.dt_id);
      setTopTenList(res?.list || [])
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setLoading(false)
  };
  const toTutorials = () => {
    // window.open(
    //   routerList?.filter(i => i?.path === props?.match?.path)[0]?.tutorials_url
    // )
  }
  const toPre = () => {
    message.destroy()
    if (index === 0) {
      message.warning('已经是第一个')
    } else {
      setIndex(index - 1)
    }
  }
  const toNext = () => {
    message.destroy()
    if (index === 9) {
      message.warning('已经是最后一个')
    } else {
      setIndex(index + 1)
    }
  }
  const toVideo = () => {
    window.open('https://www.iesdouyin.com/share/video/' + topTenList[index].video_id)
  }
  const toIndex = () => {
    window.open('https://www.douyin.com/user/' + info?.sec_uid)
    // history.push('/works/' + props.match.params.dt_id + '/0')
  }
  const goVip = () => {
    if (!!user_info) {
      setShowAddVip(true)
    } else {
      setloginVis(true)
      // history.push('/login')
    }
  }
  return <div className={styles.works}>
    <div className={styles.bread}>
      <div className={styles.breadItem}>
        拆解详情
      </div>
      {/* <div className={styles.teach} onClick={toTutorials}>
        <FileTextOutlined style={{ color: '#25B864', marginRight: '4px' }} />
        页面使用教程
      </div> */}
    </div>
    <div className={styles.worksContent}>
      <div className={styles.woksLeft}>
        {
          !!info && (
            <InfoItem record={info} handleClick={toIndex} />
          )
        }
        <div className={styles.infoList}>
          <div className={styles.infoItem}>
            <div className={styles.itemTitle}>作品</div>
            <div className={styles.itemInner}>
              {
                info?.works_num
              }
            </div>
          </div>
          <div className={styles.infoItem}>
            <div className={styles.itemTitle}>粉丝</div>
            <div className={styles.itemInner}>
              {
                info?.followers_num
              }
            </div>
          </div>
          <div className={styles.infoItem}>
            <div className={styles.itemTitle}>获赞</div>
            <div className={styles.itemInner}>
              {
                info?.like_num
              }
            </div>
          </div>
          <div className={styles.infoItem}>
            <div className={styles.itemTitle}>近10天粉丝增量</div>
            <div className={styles.itemInner}>
              {
                info?.followers_num_add
              }
            </div>
          </div>
        </div>
        <div className={styles.addNum}>
          <p>已有{peopleNum}人参与此账号的拆解</p>
        </div>
        <div className={styles.recommend}>
          <div className={styles.title}>
            获赞top10作品
          </div>
          <div className={styles.change} >
            <LeftCircleOutlined disabled={index === 0} onClick={toPre} />
            &nbsp;{index + 1}/10&nbsp;
            <RightCircleOutlined disabled={index === 0} onClick={toNext} />
          </div>
          <div className={styles.topTenList}>
            {
              !!topTenList.length && (
                <video controls key={index}
                  controlsList="nodownload"
                  name="media">
                  <source src={topTenList[index]?.url} type="video/mp4" />
                </video>
              )
            }
            <div className={styles.topTenListTitle} onClick={toVideo}>
              {topTenList[index]?.title}
            </div>
            <div className={styles.videoInfo}>
              <div className={styles.infoItem}>
                <img src={v1} alt="" />&nbsp;
                {topTenList[index]?.forward_num}
              </div>
              <div className={styles.infoItem}>
                <img src={v2} alt="" />&nbsp;
                {topTenList[index]?.like_num}
              </div>
              <div className={styles.infoItem}>
                <img src={v3} alt="" />&nbsp;
                {topTenList[index]?.comment_num}
              </div>
            </div>
            <Button className='toIndex' style={{ fontSize: '16px' }} onClick={toIndex} >前往达人作品主页</Button>
          </div>
        </div>
      </div>
      <div className={styles.woksRight}>
        {
          !user_info?.is_vip && (
            <ShowVip goVip={goVip}></ShowVip>
          )
        }
        {showAddVip && (
          <Addvip handleCancel={() => setShowAddVip(false)} />
        )
        }
        <Part dt_id={props.match.params.dt_id} dtte_id={props.match.params.dtte_id} />
      </div>
    </div>
  </div>
}
export default Works