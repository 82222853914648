// import { NewContract } from '@/components/business/new.contract';
import request from '@/utils/request';
import { controller_name } from './constant'
/***
 * 对标库全部数据
 * @param params
 */
export async function getDataList(params) {
  return request({
    url: `/jkapi/DouyinBenchmarkTalent/all`,
    method: 'POST',
    data: params,
  });
}
/***
 * 对标库全部数据
 * @param params
 */
 export async function getNoGroupList(params) {
  return request({
    url: `/jkapi/DouyinBenchmarkTalent/noGroupList`,
    method: 'POST',
    data: params,
  });
}
/**
 * 达人行业
 */
/**
 * 
 * @returns 
 */
 

 export async function douyinBenchmarkTalent(sec_uid) {
  return request({
    url: `/jkapi/DouyinBenchmarkTalent/delete`,
    method: 'POST',
    data: {sec_uid},
  });
}
 export async function gettradeList() {
  return request({
    url: `jkapi/DouyinTalent/tradeList`,
    method: 'POST',
    // data: params,
  });
}
/**
 * 达人内容类型
 */
 export async function getcontentTypeList() {
  return request({
    url: `/jkapi/DouyinTalent/contentTypeList`,
    method: 'POST',
    // data: params,
  });
}

/**
 * 达人内容类型
 */
 export async function getInfo(dt_id) {
  return request({
    url: `/jkapi/DouyinTalent/info`,
    method: 'POST',
    data: {
      dt_id
    },
  });
}

/**
 * 达人内容类型
 */
 export async function getRecommendList(params) {
  return request({
    url: `/jkapi/DouyinTalent/recommendList`,
    method: 'POST',
    data: params,
  });
}

/**
 * 推荐案例列表
 */
 export async function getRecommendDisbleList(params) {
  return request({
    url: `/jkapi/DouyinTalentTeardowns/recommendList`,
    method: 'POST',
    data: params,
  });
}

/**
 * 达人作品视频
 */
 export async function getTalentVideoList(params) {
  return request({
    url: `/jkapi/DouyinShortVideo/talentVideoList`,
    method: 'POST',
    data: params,
  });
}

/**
 * 对标库视频
 */
 export async function getBTalentVideoList(params) {
  return request({
    url: `/jkapi/DouyinBenchmarkTalent/talentVideoList`,
    method: 'POST',
    data: params,
  });
}

/**
 * 达人账号拆解top10
 */
 export async function getTopTenList(dt_id) {
  return request({
    url: `/jkapi/DouyinShortVideo/likeTopTen`,
    method: 'POST',
    data: {
      dt_id
    },
  });
}

/**
 * 我拆解的案例列表
 */
 export async function getTeardownsList(params) {
  return request({
    url: `/jkapi/DouyinTalentTeardowns/myTeardowns`,
    method: 'POST',
    data: params
  });
}

/**
 * 案例列表
 */
 export async function changeStatus(params) {
  return request({
    url: `/jkapi/DouyinTalentTeardowns/changeStatus`,
    method: 'POST',
    data: params
  });
}

/**
 * 拆解案例发布
 */
 export async function save(params) {
  return request({
    url: `/jkapi/DouyinTalentTeardowns/save`,
    method: 'POST',
    data: params
  });
}

/**
 * 拆解案例发布
 */
export async function disassembleInfo(dtte_id) {
  return request({
    url: `/jkapi/DouyinTalentTeardowns/info`,
    method: 'POST',
    data: {
      dtte_id
    }
  });
}

/**
 * 拆解案例列表
 */
export async function disassembleList(param) {
  return request({
    url: `/jkapi/DouyinTalentTeardowns/talentTeardownsList`,
    method: 'POST',
    data: param
  });
}

/**
 * 拆解案例收藏取消
 */
export async function collectTeardowns(dtte_id) {
  return request({
    url: `/jkapi/DouyinTalentTeardownsCollect/collectTeardowns`,
    method: 'POST',
    data: {
      dtte_id
    }
  });
}

/**
 * 
 */
 export async function getCollectList(params) {
  return request({
    url: `/jkapi/DouyinTalentTeardownsCollect/myCollect`,
    method: 'POST',
    data: params
  });
}

/**
 * 
 */
 export async function getPeopleNum(dt_id) {
  return request({
    url: `/jkapi/DouyinTalentTeardowns/peopleNum`,
    method: 'POST',
    data: {
      dt_id
    }
  });
}

/**
 * 
 */
 export async function addgroup(group_name) {
  return request({
    url: `/jkapi/DouyinBenchmarkTalentGroup/add`,
    method: 'POST',
    data: {
      group_name
    }
  });
}

/**
 * /jkapi/DouyinBenchmarkTalentGroup/groupList
 */
 export async function getGroupList(pageNo,pageSize) {
  return request({
    url: `/jkapi/DouyinBenchmarkTalentGroup/groupList?pageNo=${pageNo}&pageSize=${pageSize}`,
    method: 'GET',
  });
}
/**
 * 批量加入分组
 */
 export async function saveToGroup(dbtg_id,sec_uids) {
  return request({
    url: `/jkapi/DouyinBenchmarkTalentGroupInfo/saveToGroup`,
    method: 'POST',
    data: {
      dbtg_id,sec_uids
    }
  });
}

/**
 * 删除分组
 */

 export async function deleteGroup(dbtg_id) {
  return request({
    url: `/jkapi/DouyinBenchmarkTalentGroup/delete`,
    method: 'POST',
    data: {
      dbtg_id
    }
  });
}

/**
 * 分组详情列表
 */
 export async function getGroupInfoList(params) {
  return request({
    url: `/jkapi/DouyinBenchmarkTalentGroupInfo/groupInfoList`,
    method: 'POST',
    data: params
  });
}

/**
 * 
 */

 export async function receiveBenchmarkTalent(params) {
  return request({
    url: `/jkapi/DouyinBenchmarkTalent/receiveBenchmarkTalent`,
    method: 'POST',
    data: params
  });
}