import styles from './index.module.scss'
import { message, Button } from 'antd'
import { useHistory } from 'react-router-dom';
import addvip from '@/assets/img/addvip.png'
const ShowVip = (props) => {
  const history = useHistory();
  const goVip = () => {
    props.goVip()
  }
  return <div className={styles.addvip}>
  <div className={styles.btn}>
    <img src={addvip} alt="" />
    <Button type='primary' onClick={goVip}>加入会员</Button>
  </div>
</div>
}
export default ShowVip