import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>
      <Form.Item key='3197' name='keyword' label='关键词' className ='searchFormItem'><Input placeholder='关键词' /></Form.Item>


    
      <Form.Item key='3237' label='总搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3237_start' name='baidu_index_all_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3237_end' name='baidu_index_all_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3238' label='pc搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3238_start' name='bidword_pcpv_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3238_end' name='bidword_pcpv_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3239' label='移动搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3239_start' name='bidword_wisepv_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3239_end' name='bidword_wisepv_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3591' label='公众号数量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3591_start' name='account_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3591_end' name='account_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3592' name='is_same_name' label='公众号重名' className = 'searchFormItem'>
        <Select
            placeholder='公众号重名'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='2' value='2'>小程序同名</Option>
                <Option key='1' value='1'>是</Option>
                <Option key='0' value='0'>否</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='3196' name='nickname' label='排第一的公众号' className ='searchFormItem'><Input placeholder='排第一的公众号' /></Form.Item>


    
      <Form.Item key='3190' name='type' label='匹配类型' className = 'searchFormItem'>
        <Select
            placeholder='匹配类型'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>完全匹配</Option>
                <Option key='2' value='2'>包含匹配</Option>
                <Option key='3' value='3'>类似匹配</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='3194' name='realname_type' label='是否认证' className = 'searchFormItem'>
        <Select
            placeholder='是否认证'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='3' value='3'>特殊</Option>
                <Option key='1' value='1'>是</Option>
                <Option key='0' value='0'>否</Option>
        </Select>
      </Form.Item>


    
</Fragment>