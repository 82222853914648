import styles from './index.module.scss'
import { StarOutlined, StarFilled, RotateRightOutlined } from '@ant-design/icons';
import { message, Spin } from 'antd';
import part from '../../pages/Daren/works/constant'
import { Fragment } from 'react/cjs/react.production.min';
import { collectTeardowns } from '../../pages/Daren/service'
import moment from 'moment';
import { useState } from 'react';
const PartItem = (props) => {
  const [loading, setLoading] = useState(false)
  // const { item } = props;
  // const { analyze_info, base_info } = item;
  // const { title } = base_info;
  const { item } = props;

  const { analyze_info, base_info } = item;
  // const {analyze_info, base_info } = params;
  const { account_analyze, homepage_analyze, overall } = analyze_info;
  const { head_img_analyze, name_analyze, sign_analyze } = homepage_analyze;
  const { title, dtte_id, is_collect, create_time } = base_info;
  const { business_analyze, role_analyze, content_analyze, user_analyze } = account_analyze;
  const {
    conclusion,
    summary
  } = overall;

  const {
    realization_analyze,
    realization_think
  } = business_analyze;

  const { appearance_analyze,
    character_analyze,
    daily_style_analyze,
    is_match_appearance,
    scene_analyze,
    speak_style_analyze
  } = role_analyze;
  const {
    content_style_analyze,
    content_type_analyze,
    trade_analyze
  } = content_analyze;
  const {
    // user_analyze, 
    age_distribution_analyze,
    gender_analyze,
    consumption_analyze
  } = user_analyze;
  let partList = [
    {
      label: '账号主页分析 ',
      content: [
        {
          inner: name_analyze,
          key: 'name_analyze',
          name: '该账号的取名方式：'
        },
        {
          inner: sign_analyze,
          key: 'sign_analyze',
          name: '该账号签名包含哪些内容：'
        },
        {
          inner: head_img_analyze,
          key: 'head_img_analyze',
          name: '该账号的头像图片是什么类型：'
        },
      ]
    },
    {
      label: '账号定位 ',
      content: [
        {
          inner: trade_analyze,
          key: 'trade_analyze',
          name: '视频内容行业：'
        },
        {
          inner: content_type_analyze,
          key: 'content_type_analyze',
          name: '内容是宽泛的还是精准的：'
        },
        {
          inner: content_style_analyze,
          key: 'content_style_analyze',
          name: '各个视频的内容风格是否统一：'
        },
        {
          inner: character_analyze,
          key: 'character_analyze',
          name: '该达人的人设身份是什么：'
        },
        {
          inner: speak_style_analyze,
          key: 'speak_style_analyze',
          name: '他说话的方式怎么样：'
        },
        {
          inner: daily_style_analyze,
          key: 'daily_style_analyze',
          name: '他在镜头里的穿衣风格：'
        },
        {
          inner: appearance_analyze,
          key: 'appearance_analyze',
          name: '他的外在形象（气质）如何：'
        },
        {
          inner: scene_analyze,
          key: 'scene_analyze',
          name: '视频的主要拍摄场景是什么：'
        },
        {
          inner: is_match_appearance,
          key: 'is_match_appearance',
          name: '该达人的人设形象和账号是否匹配：'
        },
        {
          inner: gender_analyze,
          key: 'gender_analyze',
          name: '目标用户性别偏向：'
        },
        {
          inner: age_distribution_analyze,
          key: 'age_distribution_analyze',
          name: '目标用户年龄分布：'
        },
        {
          inner: consumption_analyze,
          key: 'consumption_analyze',
          name: '目标用户消费能力：'
        },
        {
          inner: realization_analyze,
          key: 'realization_analyze',
          name: '账号的变现形式：'
        },
        {
          inner: realization_think,
          key: 'realization_think',
          name: '你还能想到的哪些适用 于该账号的变现方式：'
        },
      ],

    },
    {
      label: '账号整体分析 ',
      content: [
        {
          inner: summary,
          key: 'summary',
          name: '用50字左右概述该账号：'
        },
        {
          inner: conclusion,
          key: 'conclusion',
          name: '该账号有哪些可借鉴的亮点和需要改进的不足：'
        },
      ]
    },
  ]
  const [isCollect, setIsCollect] = useState(is_collect == 0 ? true: false)
  const changecollectTeardowns = async () => {
    message.destroy();
    setLoading(true);
    try {
      const res = await collectTeardowns(dtte_id);
      message.success('操作成功')
      setIsCollect(!isCollect)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setLoading(false);
  }
  const renderContent = (contentItem) => {
    let content = JSON.parse(JSON.stringify(contentItem.content))
    content = content.filter(i => !!i.inner)
    if (content.length != 0) {
      try {
        return content.map(item => {

          if (item.key === 'age_distribution_analyze' || item.key === 'realization_analyze') {
            return <div className={styles.slotItem}><span>{item?.name}</span>
              {
                JSON.parse(item?.inner)?.map(i => <span className={styles.slotSpan}>{i} </span>)
              }
            </div>
          }
          else if (item.key === 'appearance_analyze' || item.key === 'character_analyze' || item.key === 'daily_style_analyze' || item.key === 'head_img_analyze' || item.key === 'name_analyze' || item.key === 'scene_analyze') {
            return <div className={styles.slotItem}><span>{item?.name}</span>
              {
                JSON.parse(item?.inner)?.map(i => <span className={styles.slotSpan}>{i?.title === '其他' ? i?.inner : i?.title}</span>)
              }
            </div>
          } else if (item.key === 'sign_analyze') {
            return <div className={styles.slotItem}><span>{item?.name}</span>
              {
                JSON.parse(item?.inner)?.map(i => <span className={styles.slotSpan}>{i?.title}{i?.inner ? `（${i?.inner}）` : ''}</span>)
              }
            </div>
          }
          else {
            return <div className={styles.slotItem}>
              <span>
                {item.name}
              </span>
              <span>{item.inner}</span>
            </div>
          }
        })
      } catch (errorInfo) {
        message.error(errorInfo?.msg || '@@@系统正在卖力成长中，请稍后重试！');
        console.log('Failed:', errorInfo);
      }
    }
  }
  return <div className={styles.partItem}>
    <Spin size="large" spinning={loading}>
      <div className={styles.title}>
        {props.item?.base_info?.title}
      </div>
      <div className={styles.time}>
        {
          moment(create_time * 1000).format('YYYY/MM/DD HH:mm')
        }
      </div>
      {
        partList?.filter(i => {
          if (!!i?.content?.filter(i => !!i.inner).length) {
            return i;
          }
        })?.map((i, index) => {
          return <div className={styles.partTab}>
            <div className={styles.partTabTitle}>
              {index + 1}、{i.label}
            </div>
            <div className={styles.partTabcontent}>
              <Fragment>
                {
                  !!i && (
                    <Fragment>
                      <div className={styles.contenItem}>
                        <div className={styles.contentInner}>
                          {renderContent(i)}
                          {/* {i.inner} */}
                        </div>
                      </div>
                    </Fragment>
                  ) || null
                }
              </Fragment>


            </div>
            
          </div>
        })
      }
      {
              !props?.hideCollect && (
                <div className={styles.operation}>
                  {
                    isCollect && (
                      <div className={styles.collect} onClick={changecollectTeardowns}>
                        <StarOutlined /><span>收藏</span>
                      </div>
                    ) || (
                      <div className={styles.unCollect}>
                        <StarFilled /><span className={styles.collectT} onClick={changecollectTeardowns}>已收藏</span>
                      </div>
                    )
                  }
                </div>
              ) || null
            }
    </Spin>
  </div>
}
export default PartItem;