import styles from './index.module.scss';
import { tools } from '@/utils';
import moment from 'moment';
import { Fragment, useContext } from 'react';
import {  QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '@/App'
import { message } from 'antd';
export default () => {
  const history = useHistory();
  const { user_info,setLogin } = useContext(GlobalContext);
  const signOut = () => {
    setLogin()
    tools?.setAuthorization('')
    sessionStorage.setItem('isLogin',false)
    history.go(history.location.pathname)
  }
  return (
    <div className={styles.userBox}>
      <div className={styles.top}>
        {
          user_info?.is_vip && (
          <div className={styles.vip}>
            <div className={styles.icon}>
            VIP
            </div>
            {moment(user_info?.end_time * 1000).format('YYYY/MM/DD')}到期
            
            {
              user_info?.end_day <= 16 && (
                <div className={styles.endDay}>
                <QuestionCircleOutlined style={{color: '#F02828'}} /> &nbsp;会员已不足{
                    user_info?.end_day || 0
                  }天
                </div>
              ) || null
            }
          </div>
          ) || (
          <div className={styles.getVip}>
            <div className={styles.getvipicon}>
            开通VIP
            </div>
            享更多特权
        </div>
          )
        }
      </div>
      <div className={styles.content}>
        <div className={styles.contentItem} onClick={signOut}>
          退出
        </div>
      </div>
    </div>
  )
}
