// import { columns } from '@/src/utils/formdata.js';
import styles from './index.module.scss';
import { Form, Modal } from 'antd'
import code1 from '@/assets/img/code1.png'
const AddCount = (props) => {
  return <Modal title={null} visible={true} footer={null}  onCancel={props.handleCancel} className={styles.addVip}>
   <div className={styles.confirmcontent}>
     <div className={styles.vipt}>欢迎加入极客增长！</div>
            <img src={code1} alt="" />
            <div className={styles.vipc}>
            打开微信扫一扫，添加萌萌微信好友
            </div>
          </div>
  </Modal>
}
export default AddCount;

