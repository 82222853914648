import React, { useEffect, useState } from "react";
import Search from "../components/search";
import ToPlayModal from "../components/toPlayModal";
import {
  Button,
  Popover,
  ConfigProvider,
  Table,
  Tooltip,
  message,
  Checkbox,
  Form,
  notification
} from "antd";
import {
  SettingOutlined,
  ReloadOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import { hotVideoRequest } from "../service";
import styles from "./index.module.scss";
import "./index.scss";
import nodata from "@/assets/img/nodata.png";
import showcode from "@/assets/img/showcode.png";
import { useHistory, Link } from "react-router-dom";
import Addvip from "@/components/addVip";

const HotVideo = () => {
  const search = JSON.parse(sessionStorage.getItem("searchParams"));
  const storagePage = JSON.parse(sessionStorage.getItem("pagination"));
  const [showOptionsChecked, setShowOptionChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [pagination, setPagination] = useState({
    current: storagePage?.pageNo || 1,
    pageSize: storagePage?.pageSize || 20,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: [20, 50, 100, 500],
    showTotal: (total, range) =>
      `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录`,
  });
  const [searchParams, setSearchParams] = useState({...search});
  const [columnsList, setColumnsList] = useState([]);
  const [sorter, setSorter] = useState({
    order: "",
    columnKey: "",
  });
  const [modalShow, setModalShow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [currentIdx, setCurrentIdx] = useState();
  const [searchForm] = Form.useForm();
  const history = useHistory();
  const [showCode, setShowCode] = useState(false);
  const isLogin = sessionStorage.getItem("isLogin");
  const [isSearchBtn, setIsSearchBtn] = useState(false);
  const [searchFlag, setSearchFlag] = useState(false);

  const columns = [
    {
      title: "视频信息",
      key: "video_info",
      checked: true,
      render: (t, record, index) => {
        return (
          <div className="video_info">
            <div
              className="video_img"
              onClick={() => toShowModal(record, index)}
            >
              <img src={record?.cover_img} alt="" />
              <div className="video_playicon">
                <CaretRightOutlined />
              </div>
            </div>
            <div className="video_right">
              <div
                className="video_title"
                onClick={() =>
                  window.open(
                    `https://www.iesdouyin.com/share/video/${record.video_id}`
                  )
                }
              >
                {record.title}
              </div>
              <div className="user_message">
                <div
                  className="user_img"
                  onClick={() =>
                    window.open(`https://www.douyin.com/user/${record.sec_uid}`)
                  }
                >
                  <img src={record?.head_img} alt="" />
                </div>
                <p
                  onClick={() =>
                    window.open(`https://www.douyin.com/user/${record.sec_uid}`)
                  }
                >
                  {record?.nickname}
                </p>
                <Tooltip
                  placement="bottomLeft"
                  getPopupContainer={() => document.getElementById("root")}
                  overlayClassName="codeTooltip"
                  title={
                    <>
                      <img className="dareninfocode" src={record.qr_code} />
                      <div className="dareninfocodeTitle">
                        抖音扫一扫，查看达人主页
                      </div>
                    </>
                  }
                  color="#ffffff"
                >
                  <div className="code_icon">
                    <img src={showcode} alt="" />
                  </div>
                </Tooltip>
              </div>
              <div className="followers_num">粉丝：{record?.followers_num}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: "拆解次数",
      key: "dismantle_num",
      dataIndex: "dismantle_num",
      checked: true,
      sorter: true,
      render: (num, record) => (
        <Link to={`/popVideoInfo/${record.video_id}`}>{num || 0}</Link>
      ),
    },
    {
      title: "点赞数",
      key: "like_num",
      dataIndex: "like_num",
      checked: true,
      sorter: true,
      render: (num) => {
        if (!num) return "0";
        return num;
      },
    },
    {
      title: "转发数",
      key: "forward_num",
      dataIndex: "forward_num",
      checked: true,
      sorter: true,
      render: (num) => {
        if (!num) return "0";
        return num;
      },
    },
    {
      title: "评论数",
      key: "comment_num",
      dataIndex: "comment_num",
      checked: true,
      sorter: true,
      render: (num) => {
        if (!num) return "0";
        return num;
      },
    },
    {
      title: "操作",
      key: "operation",
      checked: true,
      render: (record) => {
        return (
          <>
            <Tooltip title={"视频详情"} color={"black"}>
              <div
                className="video_detail"
                onClick={() => history.push(`/popVideoInfo/${record.video_id}`)}
              ></div>
            </Tooltip>
            <Tooltip title={"视频拆解"} color={"black"}>
              <div
                className="video_dismantling"
                onClick={() =>
                  history.push(`/videoDisassemble/${record.video_id}/0`)
                }
              ></div>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (search) {
      searchForm.setFieldsValue({
        ...search,
      });
    }
    setColumnsList([...columns]);
    setSearchFlag(false)
  }, []);

  useEffect(() => {
    searchFn();
  }, [searchParams, pagination.current, pagination.pageSize, sorter]);

  // 刷新列表
  const refreshFn = () => {
    setIsSearchBtn(false);
    if (isLogin === "false" || !isLogin) {
      message.error("请先登录");
      return
    }
    setSearchFlag(true);
    searchFn();
  };

  // 列表变化
  const handleTableChange = (p, f, s) => {
    setSearchFlag(false);
    const { current, pageSize } = p;
    if (current !== pagination.current || pageSize !== pagination.pageSize) {
      setPagination((v) => ({
        ...v,
        current,
        pageSize,
      }));
    }
    const { order, columnKey } = s;
    if (order !== sorter.order || columnKey !== sorter.columnKey) {
      setSorter((v) => ({
        ...v,
        order,
        columnKey,
      }));
    }
  };

  // 列设置变化
  const changeColumns = (e, index) => {
    columnsList[index].checked = e.target.checked;
    setColumnsList([...columnsList]);
  };

  // 进行搜索
  const searchFn = async () => {
    notification.destroy();
    setLoading(true);
    const params = {
      ...searchParams,
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
      order: sorter.order,
      sort: sorter.order ? sorter.columnKey : "",
      is_search: !searchFlag ? undefined : true
    };
    try {
      const res = await hotVideoRequest({ ...params });
      if (res.code === 2) {
        setShowCode(true);
      } else {
        !!isSearchBtn && notification.open({
          message: '',
          duration: 2,
          maxCount: 1,
          description: <span>本次搜索为您筛选出{res?.count || 0}条数据!</span>,
        })
      }
      setLoading(false);
      setDataSource(res.list);
      setPagination((v) => ({
        ...v,
        total: res.count,
      }));
      
      if (searchParams) {
        sessionStorage.setItem("searchParams", JSON.stringify(searchParams));
      }
      sessionStorage.setItem(
        "pagination",
        JSON.stringify({
          pageNo: pagination.current,
          pageSize: pagination.pageSize,
        })
      );
    } catch (errorInfo) {
      setLoading(false);
      message.error(errorInfo?.msg || "系统正在卖力成长中，请稍后重试！");
    }
  };

  // 显示播放器
  const toShowModal = (record, index) => {
    setModalShow(true);
    setCurrentRecord(record);
    setCurrentIdx(index);
  };

  // 切换播放url
  const changeVideoUrl = (index) => {
    setCurrentRecord(dataSource[index]);
  };

  const emptyContent = () => {
    return (
      <div className={styles.empty}>
        {(!!dataSource && <img src={nodata} alt="" />) || null}
      </div>
    );
  };

  const optionsCheckedContent = (
    <div className="columms_checkbox">
      {columnsList.map((item, index) => (
        <Checkbox
          checked={item.checked}
          onChange={(e) => {
            changeColumns(e, index);
          }}
        >
          {" "}
          {item.title.length > 10 ? (
            <Tooltip title={item.title} color={"black"}>
              <span style={{ cursor: "pointer" }}>
                {item.title.slice(0, 10)}...
              </span>
            </Tooltip>
          ) : (
            item.title
          )}
        </Checkbox>
      ))}
    </div>
  );

  // 搜索组件props
  const searchProps = {
    searchForm,
    setSearchParams,
    setIsSearchBtn,
    setSearchFlag,
  };

  // 播放器组件props
  const toPlayModalProps = {
    modalShow,
    setModalShow,
    currentRecord,
    setCurrentRecord,
    changeVideoUrl,
    currentIdx,
    setCurrentIdx,
    pageTotal: pagination.pageSize,
  };

  return (
    <div className={styles.hotVideoContainer}>
      <Search {...searchProps} />
      <div className={styles.btnBox}>
        <div className={styles.rightBtnGroup}>
          <Button icon={<ReloadOutlined />} onClick={refreshFn}>
            刷新
          </Button>
          <Popover
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placement="bottomRight"
            visible={showOptionsChecked}
            onVisibleChange={(visible) => {
              setShowOptionChecked(visible);
            }}
            content={optionsCheckedContent}
            trigger="click"
          >
            <Button
              icon={<SettingOutlined />}
              onClick={() => {
                setShowOptionChecked(true);
              }}
            >
              列设置
            </Button>
          </Popover>
        </div>
      </div>
      <div>
        <ConfigProvider renderEmpty={emptyContent}>
          <Table
            loading={loading}
            columns={columnsList.filter((i) => i.checked)}
            dataSource={dataSource}
            scroll={{ x: "max-content" }}
            onChange={handleTableChange}
            pagination={pagination}
          />
        </ConfigProvider>
      </div>
      <ToPlayModal {...toPlayModalProps} />
      {showCode && <Addvip handleCancel={() => setShowCode(false)} />}
    </div>
  );
};

export default HotVideo;
