import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>
      <Form.Item key='6750' name='title' label='新的标题' className ='searchFormItem'><Input placeholder='新的标题' /></Form.Item>


    
      <Form.Item key='6748' name='question_tags' label='问题标签' className ='searchFormItem'><Input placeholder='问题标签' /></Form.Item>


    
      <Form.Item key='6753' label='上榜次数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='6753_start' name='top_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='6753_end' name='top_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='6737' label='阅读量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='6737_start' name='read_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='6737_end' name='read_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='6754' label='点赞量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='6754_start' name='like_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='6754_end' name='like_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='6744' label='关注人数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='6744_start' name='follow_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='6744_end' name='follow_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='6739' label='回答人数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='6739_start' name='answer_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='6739_end' name='answer_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='6751' label='知指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='6751_start' name='zhi_index_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='6751_end' name='zhi_index_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='6743' label='第一回答数量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='6743_start' name='first_zan_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='6743_end' name='first_zan_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='6749' label='第二赞同数量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='6749_start' name='second_zan_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='6749_end' name='second_zan_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
</Fragment>