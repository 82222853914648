import React, { useState } from "react";
import { Tabs } from "antd";
import HotVideo from "./hotVideo";
import DisassembleCase from "./disassembleCase";
import MyDisassemble from "./myDisassemble";
import MyConllection from "./myConllection";
import styles from "./index.module.scss";
import { tabsList } from './common';

const { TabPane } = Tabs;

const ShipinPage = () => {
  const storageTab = sessionStorage.getItem('selectedTab');
  const [selectedTab, setSelectedTab] = useState(storageTab || "hot-video");

  // tab改变时进行缓存
  const selectedTabChange = (key) => {
    setSelectedTab(key);
    sessionStorage.setItem("selectedTab", key);
    sessionStorage.removeItem("searchParams");
    sessionStorage.removeItem("pagination");
  };

  return (
    <div className={styles.shipinContainer}>
      <div className={styles.tabContainer}>
        <Tabs activeKey={selectedTab} onChange={selectedTabChange}>
          {tabsList.map(i => (
            <TabPane tab={i.title} key={i.key}></TabPane>
          ))}
        </Tabs>
      </div>
      <div>
        {selectedTab === "hot-video" && <HotVideo />}
        {selectedTab === "disassemble-case" && <DisassembleCase />}
        {selectedTab === "my-disassembled" && <MyDisassemble setSelectedTab={setSelectedTab}/>}
        {selectedTab === "my-conllection" && <MyConllection />}
      </div>
    </div>
  );
};

export default ShipinPage;
