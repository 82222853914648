import { Form, Input, DatePicker, Select, InputNumber, message, Cascader } from 'antd';
import { useEffect, Fragment, useState } from 'react';
import { getDataList, gettradeList, getcontentTypeList } from '../../service'
import { region, followers_age_list, followers_gender_list } from '../../constant'
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => {
  const [typeList, setTypeList] = useState(JSON.parse(sessionStorage.getItem('dTypeList')) || [])
  const [tradeList, setTradeList] = useState(JSON.parse(sessionStorage.getItem('dTypeList')) || [])
  useEffect(() => {
    getSelectListFun()
  }, [])
  const changeContentTypeList = (list) => {
    return list?.map(i => {
      i.value = i.content_type
      i.label = i.content_type
      if (i.children) {
        changeContentTypeList(i.children)
      } 
      return i
    })
  }
  const getSelectListFun = async () => {
    message.destroy()
    try {
      if (!sessionStorage.getItem('dTypeList')) {
        const res = await getcontentTypeList();
        sessionStorage.setItem('dTypeList', JSON.stringify(changeContentTypeList(res)))
        setTypeList(changeContentTypeList(res))
      }
      if (!sessionStorage.getItem('dTradeList')) {
        const res = await gettradeList();
        sessionStorage.setItem('dTradeList', JSON.stringify(res))
        setTradeList(res)
      }
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
  };
  return <Fragment>
    <Form.Item key='3004' name='nickname' label='抖音昵称' className='searchFormItem'><Input placeholder='请输入抖音昵称' /></Form.Item>
    <Form.Item key='introduction' name='introduction' label='达人描述' className='searchFormItem'><Input placeholder='请输入达人描述' /></Form.Item>

    <Form.Item key='32062' name='content_type' label='行业' className='searchFormItem'>
    <Cascader options={typeList} 
    multiple   maxTagCount="responsive" 
    key='content_typeCascader'  placeholder="请选择行业"  getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]} />
    </Form.Item>

    <Form.Item key='3017' label='粉丝' className='searchFormItem'>
      <Input.Group compact >
        <Form.Item key='3017_start' name='followers_num_start' className='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
        <Form.Item key='3017_end' name='followers_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
      </Input.Group>
    </Form.Item>
    <Form.Item key='3018' label='获赞' className='searchFormItem'>
      <Input.Group compact >
        <Form.Item key='3018_start' name='like_num_start' className='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
        <Form.Item key='3018_end' name='like_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
      </Input.Group>
    </Form.Item>

    <Form.Item key='3019' label='作品' className='searchFormItem'>
      <Input.Group compact >
        <Form.Item key='3019_start' name='works_num_start' className='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
        <Form.Item key='3019_end' name='works_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
      </Input.Group>
    </Form.Item>


    <Form.Item key='followers_age' name='followers_age' label='粉丝年龄' className='searchFormItem'>
      <Select
        placeholder='请选择粉丝年龄'
        allowClear
        mode="multiple"
        showArrow={true}
        // key={dataSource?.filter(i => i.checked === true)[0]?.title}
        getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
      >
        {
          followers_age_list?.map(i =>
            <Option key={i} value={i.value}>{i.label}</Option>
          )
        }
      </Select>
    </Form.Item>
    <Form.Item key='followers_gender' name='followers_gender' label='粉丝性别' className='searchFormItem'>
    <Select
        placeholder='请选择粉丝性别'
        allowClear
        // mode="multiple"
        showArrow={true}
        // key={dataSource?.filter(i => i.checked === true)[0]?.title}
        getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
      >
        {
          followers_gender_list?.map(i =>
            <Option key={i} value={i.value}>{i.label}</Option>
          )
        }
      </Select>
    
    </Form.Item>

    <Form.Item key='region' name='region' label='地区' className='searchFormItem'>
    <Cascader options={region} key='regionCascader' placeholder="请选择省市地区"  getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]} />
    </Form.Item>
  </Fragment>
}