import { useState, useEffect } from 'react';
import { Pagination, Button, message } from 'antd'
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss'
import PartItem from '../../../../components/videopartItem';
import { disassembleList, teardownsList } from './../../service';
import nocj from '@/assets/img/nocj.png'
import { Fragment } from 'react';
const PartList = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState(null)
  const [pagination, setPagination] = useState({
    pageNo: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true, // 显示可改变每页数量
    pageSizeOptions: [20, 50, 100, 500], // 每页数量选项
  });
  useEffect(() => {
    getteardownsListFun(
      {
        pageNo: 1,
        pageSize: 10,
        video_id: props?.video_id
      }
    )
    // getDisassembleList({
    //   pageNo: 1,
    //   pageSize: 10,
    //   dt_id:props?.dt_id,
    // })
  }, [props?.video_id])
  const getDisassembleList = async (pagination) => {
    const res = await disassembleList(
      {
        dt_id: props?.dt_id,
        pageNo: pagination.pageNo,
        pageSize: pagination.pageSize
      }
    )
    setList(res.list || [])
    setPagination({
      pageNo: pagination.pageNo,
      pageSize: pagination.pageSize,
      total: res.count
    })
  }
  const toDisassemble = () => {
    history.push(`/videoDisassemble/${props?.video_id}/0`)
  }
  const getteardownsListFun = async (pagination) => {
    message.destroy()
    setLoading(true)
    try {
      const res = await teardownsList({
        pageNo: pagination.pageNo,
        pageSize: pagination.pageSize,
        video_id: props?.video_id
      });
      setList(res.list || [])
      setPagination({
        pageNo: pagination.pageNo,
        pageSize: pagination.pageSize,
        total: res.count
      })
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  const handleTableChange = (pageNo, pageSize) => {
    // pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    // setPagination({ ...pagination });
    getteardownsListFun({
      pageNo: pageNo,
      pageSize: pageSize,
      video_id: props.video_id
    });
  };
  return <div className={styles.partBox}>
    <div className={styles.partList}>
      {
        list !== null && !!list.length && list?.map(i => <PartItem hideCollect={props.hideCollect} item={i} key={i} />) || (
          <div className={styles.empty}>
            {
              !!list && (
                <img src={nocj} alt="" />
              )
            }
            {
              !!list && (
                <Button type={'primary'} style={{ width: '120px' }} onClick={toDisassemble}>去拆解</Button>
              )
            }

          </div>
        )
      }
    </div>
    {
      !!list?.length && (
        <Pagination onChange={handleTableChange} current={pagination.pageNo} pageSizeOptions={pagination.pageSizeOptions} total={pagination.total} showTotal={(total, range) => { return `总共${total} 条记录` }} />
      ) || null
    }
  </div>
}

export default PartList