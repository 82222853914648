import { CaretRightOutlined } from '@ant-design/icons';
import { message, Spin, Tooltip } from 'antd'
import showcode from "@/assets/img/showcode.png";
import { useState, useEffect } from 'react';
import { worksList } from '../constant'
import PartItem from '../../../components/videopartItem';

import zf from '@/assets/img/zf.png'
import v2 from '@/assets/img/v2.png'
import v3 from '@/assets/img/v3.png'
import { dsvtInfo, getInfo } from '../service'
import styles from './index.module.scss'
const Works = (props) => {
  const [info, setInfo] = useState(null)
  const [dsvtresInfo, setDsvtresInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [url, seturl] = useState(null)

  useEffect(() => {
    dsvtInfoFun()
    worksList[0] = {
      "title": "视频信息", "dataIndex": "info", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": false, "note": "", "name": "视频信息", "key": "info", render: (tags, record, i) => (
        <div className='worksInfo'>
          <div className="worksimg">
            <img src={record?.cover_img} alt="" />
            <div className="play" onClick={() => toPlay(record?.url || null, i)}>
              <CaretRightOutlined />
            </div>
          </div>
          <div className="infotitle" onClick={() => tocode(record)}>
            {record.title}
          </div>
        </div>
      )
    }
  }, [props.match.params.video_id, props.match.params.dsvt_id])
  const dsvtInfoFun = async () => {
    message.destroy()
    setLoading(true)
    try {
      const dsvtres = await dsvtInfo(props.match.params.dsvt_id);
      const res = await getInfo(props.match.params.video_id);
      setDsvtresInfo(dsvtres)
      setInfo(res)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };

  const tocode = (r) => {
    window.open('https://www.iesdouyin.com/share/video/' + r?.video_id)
  }
  const toPlay = (url, i) => {
    seturl(i)
  }
  const toVideo = () => {
    window.open('https://www.iesdouyin.com/share/video/' + info.video_id)
  }
  const toIndex = () => {
    // history.push('/works/' + props.match.params.dt_id)
    window.open('https://www.douyin.com/user/' + info?.sec_uid)
  }
  return <div className={styles.works}>
    <Spin size="large" spinning={loading}>
      <div className={styles.bread}>
        <div className={styles.breadItem}>
          拆解案例详情
        </div>
        {/* <div className={styles.teach} onClick={toTutorials}>
          <FileTextOutlined style={{ color: '#25B864', marginRight: '4px' }} />
          页面使用教程
        </div> */}
      </div>
      <div className={styles.worksContent}>
        <div className={styles.woksLeft}>
          <div className={styles.recommend}>

            <div className={styles.topTenList}>
              {
                !!info?.url && (
                  <video controls key={info?.url}
                    controlsList="nodownload"
                    name="media">
                    <source src={info?.url} type="video/mp4" />
                  </video>
                )
              }
              <div className={styles.topTenListTitle} onClick={toVideo}>
                {
                  info?.title
                }
                {/* {topTenList[index]?.title} */}
              </div>
              {
                !!info?.comment_num && (
                  <div className={styles.videoInfo}>
                    <div className={styles.infoItem}>
                      <img src={zf} alt="" />&nbsp;
                      {info?.forward_num}
                    </div>
                    <div className={styles.infoItem}>
                      <img src={v2} alt="" />&nbsp;
                      {info?.like_num}
                    </div>
                    <div className={styles.infoItem}>
                      <img src={v3} alt="" />&nbsp;
                      {info?.comment_num}
                    </div>
                  </div>
                )
              }
              <div className={styles.user_message} >
              <div className={styles.userBox} onClick={toIndex}>
                <div className={styles.user_img}>
                  <img src={info?.head_img} alt="" />
                </div>
                <p>{info?.nickname}</p>
                <Tooltip
                  placement="bottomLeft"
                  getPopupContainer={() => document.getElementById("root")}
                  overlayClassName="codeTooltip"
                  title={
                    <>
                      <img className="dareninfocode" src={info?.qr_code} />
                      <div className="dareninfocodeTitle">
                        抖音扫一扫，查看达人主页
                      </div>
                    </>
                  }
                  color="#ffffff"
                >
                  {
                    !!info?.qr_code && (
                    <div className={styles.code_icon}>
                      <img src={showcode} alt="" />
                    </div>
                    )
                  }
                </Tooltip>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className={styles.woksRight}>

          {
            !!dsvtresInfo && <PartItem item={dsvtresInfo} hideCollect={true} />
          }
        </div>
      </div>
    </Spin>
  </div>
}
export default Works