let emotional_element = [
  {"title":'爱情', 'inner':'',checked: false},
  {"title":'亲情', 'inner':'',checked: false},
  {"title":'友情', 'inner':'',checked: false},
  {"title":'自我成长', 'inner':'',checked: false},
  {"title":'其他', 'inner':'',checked: false},
]


let mood_element = [
  {"title":'愤怒', 'inner':'',checked: false},
  {"title":'好奇', 'inner':'',checked: false},
  {"title":'怀旧', 'inner':'',checked: false},
  {"title":'愧疚', 'inner':'',checked: false},

  {"title":'焦虑', 'inner':'',checked: false},
  {"title":'暖心', 'inner':'',checked: false},
  {"title":'爱国', 'inner':'',checked: false},
  {"title":'其他', 'inner':'',checked: false},
]

let other_element = [
  {"title":'地域', 'inner':'',checked: false},
  {"title":'群体', 'inner':'',checked: false},
  {"title":'时间线', 'inner':'',checked: false},
  {"title":'热点', 'inner':'',checked: false},
  {"title":'其他', 'inner':'',checked: false},
]
let title_template = [
  {"title":'疑问（揭秘）式', 'inner':'',selected: false},
  {"title":'疑问+结果式', 'inner':'',selected: false},
  {"title":'疑问+强烈代入式', 'inner':'',selected: false},
  {"title":'用户+结果+方法式', 'inner':'',selected: false},
  {"title":'方法+预防', 'inner':'',selected: false},
  {"title":'常见痛苦+方法引导+结果', 'inner':'',selected: false},
  {"title":'直接点明主旨', 'inner':'',selected: false},
  {"title":'用户+好奇事件+疑问', 'inner':'',selected: false},
  {"title":'言论惊悚，打破常规', 'inner':'',selected: false},
  {"title":'以偏概全+言论偏激', 'inner':'',selected: false},

  {"title":'其他', 'inner':'',selected: false},
]

let quotation_type = [
  {"title":'疑问型', 'inner':'',selected: false},
  {"title":'搞笑型（玩梗）', 'inner':'',selected: false},
  {"title":'金句型', 'inner':'',selected: false},
  {"title":'描述型', 'inner':'',selected: false},
  {"title":'其他', 'inner':'',selected: false},
]

let photography_way  = [
  {"title":'单人对镜', 'inner':'',selected: false},
  {"title":'侧方位拍摄', 'inner':'',selected: false},
  {"title":'采访回答', 'inner':'',selected: false},
  {"title":'其他', 'inner':'',selected: false},
]

let purpose = [
  {"title":'上热门（涨粉）', 'inner':'',selected: false},
  {"title":'直接变现（卖货）', 'inner':'',selected: false},
  {"title":'广告变现', 'inner':'',selected: false},

  {"title":'引流获客（私信）', 'inner':'',selected: false},
  {"title":'直播间导流', 'inner':'',selected: false},
  {"title":'其他', 'inner':'',selected: false},
]

let point_element = [
  {"title":'热点', 'inner':'',checked: false, placeholder: "热点是什么"},
  {"title":'槽点', 'inner':'',checked: false, placeholder: "槽点是什么"},
  {"title":'争议点', 'inner':'',checked: false, placeholder: "争议点是什么"},
  {"title":'共情点', 'inner':'',checked: false, placeholder: "共情点是什么"},
  {"title":'价值点', 'inner':'',checked: false, placeholder: "价值点是什么"},
  
]

let end_template = [
  {"title":'情感共鸣', 'inner':'',checked: false, placeholder: "结尾是什么"},
  {"title":'放金句', 'inner':'',checked: false, placeholder: "结尾是什么"},
  {"title":'拉高格局', 'inner':'',checked: false, placeholder: "结尾是什么"},
  {"title":'反转', 'inner':'',checked: false, placeholder: "结尾是什么"},
  {"title":'概括总结', 'inner':'',checked: false, placeholder: "结尾是什么"},

  {"title":'制造稀缺感、紧迫感', 'inner':'',checked: false, placeholder: "结尾是什么"},
  {"title":'引导指示', 'inner':'',checked: false, placeholder: "结尾是什么"},
  {"title":'其他', 'inner':'',checked: false, placeholder: "结尾是什么"},
  
]
let reusability = [
  {"title":'话题传播性', 'inner':'',checked: false},
  {"title":'相关性', 'inner':'',checked: false},
  {"title":'时效性', 'inner':'',checked: false},
  {"title":'价值性', 'inner':'',checked: false},
  {"title":'其他', 'inner':'',checked: false},
  // {"title":'50岁+', 'inner':'',checked: false},
]




let editing_skill = [
  {"title":'画中画（穿插了视频、图片、表情包等）', 'inner':'',checked: false},
  {"title":'字幕（巧用文字颜色、字号、字体等）', 'inner':'',checked: false},
  {"title":'配乐（巧用配乐、特效，使用热门音乐等）', 'inner':'',checked: false},
  {"title":'剪辑手法（加倍速、剪气口、优秀片段前置等）', 'inner':'',checked: false},
  {"title":'其他', 'inner':'',checked: false},
]

let provide_value = [
  {"title":'知识/利益', 'inner':'',checked: false, placeholder: "请具体描述"},

  {"title":'情感共鸣', 'inner':'',checked: false, placeholder: "请具体描述"},
  {"title":'开心快乐', 'inner':'',checked: false, placeholder: "请具体描述"},
  {"title":'社交谈资', 'inner':'',checked: false, placeholder: "请具体描述"},
  {"title":'其他', 'inner':'',checked: false, placeholder: "请具体描述"},
]
let start_template = [
  {"title":'疑问式开头', 'inner':'',selected: false},

  {"title":'悬疑挑战式开头', 'inner':'',selected: false},
  {"title":'提出独特见解式开头（标新立异）', 'inner':'',selected: false},
  {"title":'开头藏钩子，放福利', 'inner':'',selected: false},
  {"title":'常见痛苦+方法+福利', 'inner':'',selected: false},
  {"title":'惊悚式开头', 'inner':'',selected: false},
  {"title":'行业+预防', 'inner':'',selected: false},
  {"title":'其他', 'inner':'',selected: false},
]
const guide = [
  {"title":'评论区引导' },
  {"title":'动图引导'},
  {"title":'内容引导'},
  {"title":'无明显动作'},
]
export { provide_value, emotional_element, mood_element, other_element, title_template, quotation_type, point_element, end_template,reusability,editing_skill, start_template, photography_way, purpose, guide }