import Searchform from './search';
// import { columns } from '@/src/utils/formdata.js';
import styles from './index.module.scss';
import { Table, Tooltip, Checkbox, Button, message, Popover, Modal, Tag, Input } from 'antd'
import { SettingOutlined, ReloadOutlined, ExclamationCircleOutlined, QuestionCircleOutlined, FileTextOutlined, UploadOutlined } from '@ant-design/icons';
import { GlobalContext } from '@/App'
import { useState, useEffect, Fragment, useContext } from 'react';
import { getDataList, searchPre } from './service'
import { columnsList, pk_id } from './constant'
import { tagColor } from '@/utils/global'
import { routerList } from '@/utils/constant'
import moment from 'moment'
const { Search } = Input;
const List = (props) => {
  const [loading, setLoading] = useState(false)
  const [_columns, setColumns] = useState([]);
  // const []
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showColumnsSelect, setShowColumnsSelect] = useState(false);
  const [isSearch, setIsSearch] = useState(true);
  const [searchParams, setSearchParams] = useState({})
  const { isLogin } = useContext(GlobalContext);
  const [tableFixed, setTableFixed] = useState(false)
  const [baseData, setBaseData] = useState(null)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showTotal: (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` },
    defaultPageSize: 20,
    showSizeChanger: true, // 显示可改变每页数量
    pageSizeOptions: [20, 50, 100, 500], // 每页数量选项
  });
  const root = document.getElementById('root')
  const getheight = (e) => {
    let rootId = document.getElementById('root')?.scrollTop;
    let tableBox = document.getElementById('tableBox')?.offsetTop;
    if (rootId + document.getElementsByTagName('body')[0].clientHeight >= 1510) {
      document.getElementsByClassName('ant-pagination')[0].style.position = 'fixed'
      if (document.getElementById('tableBox')) {
        document.getElementById('tableBox').style.paddingBottom = '64px'
      }
    } else {
      if (document.getElementsByClassName('ant-pagination')[0]) {
        document.getElementsByClassName('ant-pagination')[0].style.position = 'static'
      }
      if (document.getElementById('tableBox')) {
        document.getElementById('tableBox').style.paddingBottom = '0'
      }
    }
    if (rootId >= tableBox - 80) {
      setTableFixed(true)
    } else {
      setTableFixed(false)
    }
  }
 
  useEffect(() => {
    const columns = columnsList?.filter(i => {
      try {
        return i?.list_show === 1;
      } catch (error) {
        console.error('searchFormList', error);
      }
    }).map(j => {
      try {
        const name = j?.name
        let configs = j?.config?.split(',')

        if (!!j?.config && configs?.length) {
          j.render = (colum) => {
            return configs?.map(configItem => {
              if (colum == configItem?.split('|')[1] && configItem?.split('|')[2]) {
                let text = configItem?.split('|')[0]
                let color = configItem?.split('|')[2] || ''
                return (
                  <Tag color={tagColor[color] || ''} key={colum}>
                    {text}
                  </Tag>
                );
              } else if (colum == configItem?.split('|')[1] && !configItem?.split('|')[2]) {
                return configItem?.split('|')[0]
              } else if (colum !== configItem?.split('|')[1]) {
                return '-'
              }
              else {
                return ''
              }
            })
          }
        }
        if (j?.type == 7 || j?.type == 12 || j?.type == 25) {
          j.render = (colum) => {
            return (
              moment(colum * 1000).format('YYYY/MM/DD')
            )
          }
        }
        if (j?.type == 8) {
          j.render = (colum) => {
            return (
             <img src={colum} style={{width: '30px', height: '30px'}} />
            )
          }
        }

        if (!!j?.align) {
          j.render = (colum) => {
            return (
             <span style={{textAlign: j?.align || 'left'}}>{colum}</span>
            )
          }
        }

        
        if (!!j?.note) {
          j.title = () => {
            return <Fragment> <Tooltip title={j?.note}>
              <QuestionCircleOutlined />
            </Tooltip> {name}</Fragment>
          }
        }
        return j
      } catch (error) {
        console.error('searchFormListconfig', error);
      }
    })

    // !!baseData && setColumns([...columns])
    setColumns([...columns])
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    root.addEventListener('scroll', getheight)
    return () => {
      root.removeEventListener("scroll", getheight)
    }
  }, [])

  const dumpDataFun = async () => {
    if (!!selectedRowKeys.length) {
      setLoading(true)
      try {
        const id_list = selectedRowKeys.toString();
        const field_list = _columns.filter(i => i?.checked).map(i => i?.dataIndex).toString()
        window.open(`http://api.yinliu.club/jkapi/ZhihuHot/dumpData?id_list=${id_list}&field_list=${field_list}`)
      } catch (errorInfo) {
        message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
        console.log('Failed:', errorInfo);
      }
      setLoading(false)
    } else {
      message.destroy()
      message.error('请选择导出项')
    }
  };

  const getDataListFun = async ({...params}) => {
    setLoading(true)
    message.destroy()
    try {
      await searchPre(params?.url)
      const res = await getDataList({...params});
      if (res?.code == 1) {

        const list = res?.list.map(item => {
          item.key = item[pk_id]
          return item
        })
        setPagination({ ...params?.pagination, total: res?.count })
        message.info(`共为您推荐${res?.count || 0}条数据`)
        setBaseData(list || [])
      } else if (res?.code == 2) {
        Modal.confirm({
          title: '该功能仅限会员使用',
          icon: <ExclamationCircleOutlined />,
          content: '致力于提高十倍的引流获客效率',
          okText: '去购买会员',
          cancelText: '取消',
          onOk() {
            console.log('OK');
          },
          onCancel() {
            console.log('Cancel');
          },
        });
        const list = res?.list.map(item => {
          item.key = item[pk_id]
          return item
        })
        setPagination({ ...params?.pagination, total: res?.count })
        message.info(`共为您推荐${res?.count || 0}条数据`)
        setBaseData(list || [])
      }
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setLoading(false)
  };
  const onSearch = (params) => {
    setSearchParams({...searchParams,...params})
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    getDataListFun({
      ...params,
      ...searchParams,
      is_search: true,
      order: "",
      pageNo: 1,
      pageSize: 20,
      sort: "",
      pagination
    })
  }

  const onSearchF = (url) => {
    setSearchParams({...searchParams,url})
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    getDataListFun({
      url,
      is_search: true,
      order: "",
      pageNo: 1,
      pageSize: 20,
      sort: "",
      pagination
    })
  }
  const refresh = () => {
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    getDataListFun({
      ...searchParams,
      is_search: true,
      order: "",
      pageNo: 1,
      pageSize: 20,
      sort: "",
      pagination
    })
  }

  const handleTableChange = (pagination, filters, sorter) => {
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    // setPagination({ ...pagination });
    getDataListFun({
      ...searchParams,
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
      sort: !!sorter.field ? `${sorter.field}` : '',
      order: !!sorter.order ? sorter.order : '',
      pagination
    });
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // const rowSelection = null
  const changeColumns = (e, index) => {
    if (e.target.checked && _columns?.filter((element) => {
      return element.checked;
    }).length >= 30) {
      message.error('最多选择30列');
      return;
    }
    console.log(_columns, index)
    _columns[index].checked = e.target.checked;
    setColumns([..._columns]);
  };

  const SortableItem = (({ value, sortIndex }) => {
    let { key, name } = value;
    let title = value?.name
    return <div key={`line_${key}`} className={styles.uiContentItem} style={{ zIndex: 1031 }}>
      <Checkbox checked={value.checked} disabled={value.disabled} onChange={(e) => {
        changeColumns(e, sortIndex);
      }} > {title.length > 10 ?
        <Tooltip title={title}>
          <span style={{ cursor: 'pointer' }} >{title.slice(0, 10)}...</span>
        </Tooltip>:
        title
        }</Checkbox>
    </div>;
  });
  const SortableList = (({ items }) => {
    return (
      <div className={styles.uiSelectBox} >
        {
          items?.map((element, index) => {
            return <SortableItem key={`item-${index}`} value={element} sortIndex={index} index={index} />;
          })
        }
      </div>
    );
  });
  const getContent = () => {
    return <SortableList distance={10} items={_columns} lockAxis={'y'} />
  }

  const toTutorials = () => {
    window.open(
      routerList?.filter(i => i?.path === props?.match?.path)[0]?.tutorials_url
    )
  }

  return <div className={styles.list}>
    <div className={styles.bread}>
      <div className={styles.breadItem}>
        {
          routerList?.filter(i => i?.path === props?.match?.path)[0]?.name
        }
      </div>
      <div className={styles.teach} onClick={toTutorials}>
        <FileTextOutlined style={{ color: '#25B864', marginRight: '4px' }} />
        页面使用教程
      </div>
    </div>
    <div className={styles.searchBox}>
      <Search
        placeholder="请输入您要查询的知乎问题链接"
        enterButton="查询"
        // maxLength={20}
        size="large"
        onSearch={onSearchF}
      />
    </div>
    <Searchform isSearch={isSearch} onSearch={onSearch} />
    <div className={styles.listBtn}>
      <div className={styles.btnLeft}>
        <Button style={{ fontSize: '16px'}} icon={<UploadOutlined />} type={!!selectedRowKeys.length ? "primary" : ""} onClick={dumpDataFun} >导出</Button>
      </div>
      <div className={styles.btnRight}>
        {/* <Button icon={<SearchOutlined style={{fontSize: '16px'}} />} onClick={() => setIsSearch(!isSearch)} /> */}
        <Button icon={<ReloadOutlined style={{ fontSize: '16px' }} />} style={{ marginRight: '8px' }} onClick={refresh}>刷新</Button>
        <Popover getPopupContainer={triggerNode => triggerNode.parentNode} placement="bottomRight" visible={showColumnsSelect} onVisibleChange={(visible) => {
          setShowColumnsSelect(visible);
        }} content={getContent()} trigger="click">
          <div className={styles.selectBtn} onClick={() => {
            setShowColumnsSelect(true);
          }}>
            <Button icon={<SettingOutlined style={{ fontSize: '16px' }} />}>列设置</Button>

          </div>
        </Popover>
      </div>
    </div>
    <div className={tableFixed ? 'tableFixed' : 'table'} id='tableBox'>
      <Table rowSelection={rowSelection} loading={loading} columns={_columns.filter(i => i?.checked)} dataSource={baseData}
        scroll={{ x: 'max-content' }}
        onChange={handleTableChange} pagination={pagination} />
    </div>
  </div>
}
export default List;

