import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>
      <Form.Item key='3004' name='keyword' label='关键词' className ='searchFormItem'><Input placeholder='关键词' /></Form.Item>

    
      <Form.Item key='3016' label='总搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3016_start' name='baidu_index_all_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3016_end' name='baidu_index_all_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>

    
      <Form.Item key='3008' label='移动搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3008_start' name='bidword_wisepv_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3008_end' name='bidword_wisepv_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>

    
      <Form.Item key='3007' label='PC搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3007_start' name='bidword_pcpv_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3007_end' name='bidword_pcpv_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>

    
      <Form.Item key='3202' label='公众号数量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3202_start' name='account_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3202_end' name='account_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>

    
      <Form.Item key='3206' name='is_same_name' label='公众号重名' className = 'searchFormItem'>
        <Select
            placeholder='公众号重名'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='2' value='2'>小程序同名</Option>
                <Option key='1' value='1'>是</Option>
                <Option key='0' value='0'>否</Option>
        </Select>
      </Form.Item>

    
      <Form.Item key='3011' label='竞价公司数量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3011_start' name='bidword_companycount_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3011_end' name='bidword_companycount_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>

    

      <Form.Item key='3013' label='SEM点击价格' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3013_start' name='sem_price_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3013_end' name='sem_price_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>
    
      <Form.Item key='4240' name='age' label='年龄分布(岁)	' className = 'searchFormItem'>
        <Select
            placeholder='年龄分布(岁)	'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>&lt;19</Option>
                <Option key='2' value='2'>20~29</Option>
                <Option key='3' value='3'>30~39</Option>
                <Option key='4' value='4'>40~49</Option>
                <Option key='5' value='5'>50+</Option>
        </Select>
      </Form.Item>

    

      <Form.Item key='4241' label='女生比例(%)' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4241_start' name='woman_ratio_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4241_end' name='woman_ratio_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>
    

      <Form.Item key='4242' label='男生比例(%)' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4242_start' name='man_ratio_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4242_end' name='man_ratio_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>
    
      <Form.Item key='3009' label='百度搜索结果' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3009_start' name='search_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3009_end' name='search_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>

    
</Fragment>