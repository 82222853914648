import React, { useState } from "react";
import ShowContent from "../ShowContent";
import ReadAllModal from "../ReadAllModal";
import moment from "moment";
import styles from "./index.module.scss";

const ReadCase = (props) => {
  const { dsvtresInfo } = props;
  const [visible, setVisible] = useState(false);

  const readAllProps = {
    visible,
    setVisible,
    dsvtresInfo,
    title: dsvtresInfo?.base_info?.title,
    date: moment(dsvtresInfo?.base_info?.create_time * 1000).format(
      "YYYY/MM/DD HH:mm"
    ),
  };

  return (
    <div className={styles.readCaseContainer}>
      <div className={styles.title} title={dsvtresInfo?.base_info?.title}>
        {dsvtresInfo?.base_info?.title}
      </div>
      <div className={styles.author}>{dsvtresInfo?.base_info?.name}</div>
      <div className={styles.date}>
        {moment(dsvtresInfo?.base_info?.create_time * 1000).format(
          "YYYY/MM/DD HH:mm"
        )}
      </div>
      {/* 因此处隐藏显示，所以仅显示两项 */}
      <div className={styles.content}>
        {!!dsvtresInfo && <ShowContent dsvtresInfo={dsvtresInfo} />}
      </div>
      <div className={styles.readBtn} onClick={() => setVisible(true)}>
        阅读全文
      </div>
      {!!dsvtresInfo && <ReadAllModal {...readAllProps} />}
    </div>
  );
};

export default ReadCase;
