import { useEffect, useState } from 'react';
import styles from './index.module.scss'
import zf from '@/assets/img/zf.png'
const Info = (props) => {
  const [tags, setTags] = useState([])
  const { record } = props;
  const { cover_img, trade, content_type, title, forward_num } = record;
  useEffect(() => {
    setTags(...[trade?.split(',')])
  }, [trade, content_type])
  const tocode = () => {
    // window.open('https://www.douyin.com/user/' + sec_uid)
  }
  return <div className={styles.info} onClick={props?.handleClick}>
    {/* <Tooltip title="点击可查看达人主页"> */}
    <img src={cover_img} alt="" />
    {/* </Tooltip> */}
    <div className={styles.infoBox}>
      <div className={styles.infoTop}>
        {/* <Tooltip title="点击可查看达人主页"> */}
        <div className={styles.title} onClick={tocode}>
          {title}
        </div>
      </div>
      <div className={styles.infoItem}>
        <img src={zf} alt="" />&nbsp;
        {forward_num}
      </div>
    </div>
  </div>
};
export default Info