import styles from './index.module.scss'
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { message, Spin } from 'antd';
import { collectTeardowns } from '../../pages/Video/service'
import moment from 'moment';
import { useState, Fragment } from 'react';
const PartItem = (props) => {
  const [loading, setLoading] = useState(false)
  const { item } = props;
  const { analyze_info, base_info } = item;

  const { comprehensive, copy, film_clip, overview, topic } = analyze_info;
  const { create_time, dsvt_id, is_collect, name, title, video_title } = base_info;

  const { evaluation, guide, provide_value, purpose } = comprehensive;

  const {
    document_analyze,
    end_template,
    frame_structure,
    point_element,
    quotation,
    quotation_type,
    start_template,
    title_analyze,
    title_template,
  } = copy;
  const {
    editing_skill,
    photography_way,
    scene
  } = film_clip;
  const {
    body_movements,
    expression_management,
    language_tone,
  } = scene
  const {
    element,
    reusability,
    topic_selection
  } = topic
  const {
    emotional_element,
    mood_element,
    other_element,
  } = element
  let partList = [
    {
      label: '概述 ',
      content: [
        {
          inner: overview,
          key: 'overview',
          name: '账号概述：'
        },
      ]
    },
    {
      label: '选题 ',
      content: [
        {
          inner: topic_selection,
          key: 'topic_selection',
          name: '视频的选题：'
        },
        {
          inner: emotional_element,
          key: 'emotional_element',
          name: '选题包含情感元素：'
        },
        {
          inner: mood_element,
          key: 'mood_element',
          name: '选题包含情绪元素：'
        },
        {
          inner: other_element,
          key: 'other_element',
          name: '选题包含其他元素：'
        },
        {
          inner: reusability,
          key: 'reusability',
          name: '该选题复用性体现在：'
        },
      ],
    },
    {
      label: '文案 ',
      content: [
        {
          inner: title_analyze,
          key: 'title_analyze',
          name: '该视频的标题：'
        },
        {
          inner: title_template,
          key: 'title_template',
          name: '该标题所属模板：'
        },

        {
          inner: quotation,
          key: 'quotation',
          name: '该视频引用语：'
        },
        {
          inner: quotation_type,
          key: 'quotation_type',
          name: '该视频引用语类型：'
        },

        {
          inner: start_template,
          key: 'start_template',
          name: '该视频开头部分符合模板：'
        },
        {
          inner: point_element,
          key: 'point_element',
          name: '该视频中间部分，包含爆点元素：'
        },

        {
          inner: end_template,
          key: 'end_template',
          name: '该视频结尾部分符合模板：'
        },
        {
          inner: frame_structure,
          key: 'frame_structure',
          name: '视频文案的框架结构：'
        },

        {
          inner: evaluation,
          key: 'evaluation',
          name: '该视频文案有哪些亮点或不足：'
        },
      ]
    },
    {
      label: '拍摄/剪辑',
      content: [
        {
          inner: photography_way,
          key: 'photography_way',
          name: '该视频的拍摄方式：'
        },

        {
          inner: expression_management,
          key: 'expression_management',
          name: '该达人的镜头表现力-表情管理：'
        },
        {
          inner: body_movements,
          key: 'body_movements',
          name: '该达人的镜头表现力-肢体动作如何：'
        },
        {
          inner: language_tone,
          key: 'language_tone',
          name: '该达人的镜头表现力-语言声调如何：'
        },
        {
          inner: editing_skill,
          key: 'editing_skill',
          name: '该视频用了哪些剪辑技巧吸引用户：'
        },
      ]
    },
    {
      label: '综合分析',
      content: [
        {
          inner: provide_value,
          key: 'provide_value',
          name: '该视频给用户提供价值：'
        },
        {
          inner: purpose,
          key: 'purpose',
          name: '该视频的主要目的：'
        },
        {
          inner: guide,
          key: 'guide',
          name: '引导方式：'
        },
        {
          inner: document_analyze,
          key: 'document_analyze',
          name: '该视频有哪些可借鉴的亮点和需要改进的不足：'
        },
      ]
    }
  ]
  const [isCollect, setIsCollect] = useState(is_collect == 0 ? true : false)
  const changecollectTeardowns = async () => {
    message.destroy();
    setLoading(true);
    try {
      const res = await collectTeardowns(dsvt_id);
      message.success('操作成功')
      setIsCollect(!isCollect)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setLoading(false);
  }
  const renderContent = (contentItem) => {
    let content = JSON.parse(JSON.stringify(contentItem.content))
    content = content.filter(i => !!i.inner)
    if (content.length != 0) {
      try {
        return content.map(item => {

          if (item.key === 'guide') {
            return <div className={styles.slotItem}><span>{item?.name}</span>
              {
                JSON.parse(item?.inner)?.map(i => <span className={styles.slotSpan}>{i} </span>)
              }
            </div>
          }
          else if (item.key === 'editing_skill') {

            return <div className={styles.slotItem}><span>{item?.name}</span>
              {
                JSON.parse(item?.inner)?.map(i => <span className={styles.slotSpan}>{i?.title === '其他' ? i?.inner : i?.title}</span>)
              }
            </div>
          }
          else if (item.key === 'emotional_element' || item.key === 'mood_element' || item.key === 'other_element' || item.key === 'title_template' || item.key === 'quotation_type' || item.key === 'photography_way' || item.key === 'purpose') {
            return <div className={styles.slotItem}><span>{item?.name}</span>
              {
                JSON.parse(item?.inner)?.map(i => <span className={styles.slotSpan}>{i?.title === '其他' ? i?.inner : i?.title}</span>)
              }
            </div>
          } else if (item.key === 'reusability' || item.key === 'point_element' || item.key === 'end_template' || item.key === 'provide_value' || item.key === 'start_template') {
            return <div className={styles.slotItem}><span>{item?.name}</span>
              {
                JSON.parse(item?.inner)?.map(i => <span className={styles.slotSpan}>{i?.title}{i?.inner ? `（${i?.inner}）` : ''}</span>)
              }
            </div>
          }
          else {
            return <div className={styles.slotItem}>
              <span>
                {item.name}
              </span>
              <span>{item.inner}</span>
            </div>
          }
        })
      } catch (errorInfo) {
        message.error(errorInfo?.msg || '@@@系统正在卖力成长中，请稍后重试！');
        console.log('Failed:', errorInfo);
      }
    }
  }
  return <div className={styles.partItem}>
    <Spin size="large" spinning={loading}>
      <div className={styles.title}>
        {props.item?.base_info?.title}
      </div>
      <div className={styles.time}>
        {
          moment(create_time * 1000).format('YYYY/MM/DD HH:mm')
        }
      </div>
      {
        partList?.filter(i => {
          if (!!i?.content?.filter(i => !!i.inner).length) {
            return i;
          }
        })?.map((i, index) => {
          return <div className={styles.partTab}>
            <div className={styles.partTabTitle}>
              {index + 1}、{i.label}
            </div>
            <div className={styles.partTabcontent}>
              <Fragment>
                {
                  !!i && (
                    <Fragment>
                      <div className={styles.contenItem}>
                        <div className={styles.contentInner}>
                          {
                            renderContent(i)
                          }
                          {/* {
                            !!analyze_info && renderContent(i)
                          } */}
                          {/* {i.inner} */}
                        </div>
                      </div>
                    </Fragment>
                  ) || null
                }
              </Fragment>


            </div>

          </div>
        })
      }
      {
        !props?.hideCollect && (
          <div className={styles.operation}>
            {
              isCollect && (
                <div className={styles.collect} onClick={changecollectTeardowns}>
                  <StarOutlined /><span>&nbsp;收藏</span>
                </div>
              ) || (
                <div className={styles.unCollect}>
                  <StarFilled /><span className={styles.collectT} onClick={changecollectTeardowns}>&nbsp;已收藏</span>
                </div>
              )
            }
          </div>
        ) || null
      }
    </Spin>
  </div>
}
export default PartItem;