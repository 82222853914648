import styles from './index.module.scss';
import code1 from '@/assets/img/code1.png'
import code2 from '@/assets/img/code2.png'
import { Layout } from 'antd';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import logo from '@/assets/img/flogo.png';
const { Footer } = Layout;
export default () => {
  const [history] = useState(useHistory())
  // const root = 
  useEffect(() => {
    changeStyle(history.location.pathname)
    if (history.location.pathname === '/user') {
      history.push('/user/myProject')
    }
  }, [history.location.pathname])
  const changeStyle = (pathname) => {
    if (pathname === '/home') {
      document.getElementById('root').style.background = '#FFFFFF'
      // document.getElementById('footerBox').style.background='#FFFFFF'

    } else {
      document.getElementById('root').style.background = '#F7F7F7'
      // document.getElementById('footerBox').style.background='#F7F7F7'
    }
  }
  return (
    <Footer className={styles.footerBox} id='footerBox'>
      <div className={styles.footer}>
        <div className={styles.footerLogo}>
          <div className={styles.tel}>
            <img className={styles.logo} src={logo} alt='开课吧' />
            <div className={styles.title}>
            工具&训练营，获取低成本蓝海流量的增长获客平台
            </div>
            <div className={styles.innerT}>
              <span className={styles.vip}>
              会员须知：
              </span>
            感谢你成为我们最早的一批用户
            </div>
          </div>
          <div className={styles.concept}>
          <div className={styles.innerT}>
                我们的价值观
            </div>
            <div className={styles.innerI}>
                数据驱动 、技术驱动 、创新驱动 、增长黑客 大道至简 、改变行业 、客户第一 、使用方便
            </div>
          </div>
          <div className={styles.code}>
              <img src={code1} alt="" />
              <img src={code2
              } alt="" />
              <div>加微信，领取200份引流资料包</div>
          </div>
        </div>
      </div>
        <div className={styles.copyRight}>
        备案号：浙ICP备19036316号-5
        </div>
    </Footer>
  )
}