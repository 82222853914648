import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>
      <Form.Item key='7093' name='keyword' label='关键词' className ='searchFormItem'><Input placeholder='关键词' /></Form.Item>


    
      <Form.Item key='7094' name='title' label='知乎问题标题' className ='searchFormItem'><Input placeholder='知乎问题标题' /></Form.Item>


    
      <Form.Item key='7096' label='总搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='7096_start' name='baidu_index_all_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='7096_end' name='baidu_index_all_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='7097' label='移动搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='7097_start' name='bidword_wisepv_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='7097_end' name='bidword_wisepv_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='7098' label='PC搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='7098_start' name='bidword_pcpv_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='7098_end' name='bidword_pcpv_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='7099' label='移动排名' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='7099_start' name='rank_wise_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='7099_end' name='rank_wise_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='7100' label='PC排名' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='7100_start' name='rank_pc_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='7100_end' name='rank_pc_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='7101' name='question_tags' label='问题标签' className ='searchFormItem'><Input placeholder='问题标签' /></Form.Item>


    
      <Form.Item key='7102' label='阅读量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='7102_start' name='read_num_last_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='7102_end' name='read_num_last_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='7104' label='10日新增阅读' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='7104_start' name='read_add_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='7104_end' name='read_add_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='7105' label='关注人数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='7105_start' name='follow_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='7105_end' name='follow_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='7106' label='回答人数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='7106_start' name='answer_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='7106_end' name='answer_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='7107' label='知指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='7107_start' name='zhi_index_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='7107_end' name='zhi_index_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='7108' label='首答赞同数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='7108_start' name='first_zan_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='7108_end' name='first_zan_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='7109' label='二答赞同数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='7109_start' name='second_zan_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='7109_end' name='second_zan_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='7110' name='question_status' label='问题状态' className = 'searchFormItem'>
        <Select
            placeholder='问题状态'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='0' value='0'>问题关闭</Option>
                <Option key='1' value='1'>正常</Option>
                <Option key='2' value='2'>无法打开</Option>
                <Option key='3' value='3'>获取失败</Option>
        </Select>
      </Form.Item>


    


    
</Fragment>