import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>
      <Form.Item key='4368' name='keyword' label='关键词' className ='searchFormItem'><Input placeholder='关键词' /></Form.Item>


    
      <Form.Item key='4377' name='is_ad' label='是否存在竞价广告' className = 'searchFormItem'>
        <Select
            placeholder='存在竞价广告'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>是</Option>
                <Option key='0' value='0'>否</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='4370' label='排名' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4370_start' name='rank_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4370_end' name='rank_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4369' name='type' label='搜索引擎' className = 'searchFormItem'>
        <Select
            placeholder='搜索引擎'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>百度PC</Option>
                <Option key='2' value='2'>百度移动</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='4371' label='对应搜索引擎指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4371_start' name='search_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4371_end' name='search_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4372' name='is_baijiahao' label='是否存在' className = 'searchFormItem'>
        <Select
            placeholder='是否存在'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>是</Option>
                <Option key='0' value='0'>否</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='4373' name='title' label='百家号文章标题' className ='searchFormItem'><Input placeholder='百家号文章标题' /></Form.Item>


    
      <Form.Item key='4375' name='match_type' label='是否完全匹配' className = 'searchFormItem'>
        <Select
            placeholder='是否完全匹配'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>是</Option>
                <Option key='0' value='0'>否</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='4376' label='百度搜索结果数量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4376_start' name='search_result_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4376_end' name='search_result_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
</Fragment>