const sign_analyze = [
  {"title":'账号内容', 'inner':'',checked: false},
  {"title":'人设介绍', 'inner':'',checked: false},
  {"title":'信任背书', 'inner':'',checked: false},
  {"title":'信息（联系方式、直播时间）', 'inner':'',checked: false},
  {"title":'相关引导（关注、私聊）', 'inner':'',checked: false},
  {"title":'其他', 'inner':'',checked: false},
]
const name_analyze = [
  {"title":'行业/职业+昵称', 'inner':'',selected: false},
  {"title":'个性化名字', 'inner':'',selected: false},
  {"title":'其他', 'inner':'',selected: false},
]
const head_img_analyze = [
  {"title":'真人', 'inner':'',selected: false},
  {"title":'文字或logo', 'inner':'',selected: false},
  {"title":'其他', 'inner':'',selected: false},
]
const character_analyze = [
  {"title":'老板/高管', 'inner':'',selected: false},
  {"title":'创业者（个人or小团队）', 'inner':'',selected: false},
  {"title":'上班族', 'inner':'',selected: false},
  {"title":'学生', 'inner':'',selected: false},
  {"title":'其他', 'inner':'',selected: false},
]
const daily_style_analyze = [
  {"title":'休闲家居', 'inner':'',selected: false},
  {"title":'运动', 'inner':'',selected: false},
  {"title":'商务', 'inner':'',selected: false},
  {"title":'潮流', 'inner':'',selected: false},
  {"title":'职业装', 'inner':'',selected: false},
  {"title":'其他', 'inner':'',selected: false},
]
const appearance_analyze = [
  {"title":'成熟/知性', 'inner':'',selected: false},
  {"title":'青涩', 'inner':'',selected: false},
  {"title":'阳光', 'inner':'',selected: false},
  {"title":'不修边幅（邋遢）', 'inner':'',selected: false},
  {"title":'其他', 'inner':'',selected: false},
]
const scene_analyze = [
  {"title":'办公室', 'inner':'',selected: false},
  {"title":'书房', 'inner':'',selected: false},
  {"title":'茶室', 'inner':'',selected: false},
  {"title":'车内', 'inner':'',selected: false},
  {"title":'室外', 'inner':'',selected: false},
  {"title":'场景不固定', 'inner':'',selected: false},
  {"title":'其他', 'inner':'',selected: false},
]
const realization_analyze = [
  {"title":'知识付费'},
  {"title":'课程分销'},
  {"title":'私域引流'},
  {"title":'项目加盟'},
  {"title":'广告变现'},
  {"title":'视频带货'},
  {"title":'直播音浪'},
  {"title":'线下店铺引流'},
  {"title":'好物推荐'},
  {"title":'中视频补贴'},
]
const age_distribution_analyze = [
  {"title":'20岁以下' },
  {"title":'20岁-30岁'},
  {"title":'30岁-40岁'},
  {"title":'40岁-50岁'},
  {"title":'50岁+'},
]

export { sign_analyze, name_analyze, head_img_analyze, character_analyze, daily_style_analyze, appearance_analyze, scene_analyze, realization_analyze, age_distribution_analyze }