import styles from './index.module.scss'
import { tools } from '@/utils';
import { Button, Input, Form, message, Radio } from 'antd';
import { NavLink } from 'react-router-dom';
import { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '@/App'
import moment from 'moment';
import md5 from 'js-md5'
import { getVerifyCode, wxBind, smsToken, getTicket, register } from '../service'
import routerList from '@/utils/constant';

const {getApiHost} = tools;
let { hostname } = window.location;
let signtime
const Register = (props) => {
  const { changeVisble } = props;
  const [registerForm] = Form.useForm();
  const history = useHistory();
  const { setLogin } = useContext(GlobalContext);
  const [verify, setVerify] = useState('')
  const [disabl, setDisabl] = useState(false)
  const [ticketUrl, setTicket] = useState(null)
  const [countTime, setCountTime] = useState(0)
  const [verifyDate, setVerifyDate] = useState(md5(Date.parse(moment()).toString()))
  const [registerType, setRegisterType] = useState(0)
  const [scene_id, setScene_id] = useState('')
  const { getFieldValue, getFieldError } = registerForm;
  useEffect(async () => {
    try {
      // message.info('请绑定微信或注册账号')
      const ticket = await getTicket()
      setVerify(verify)
      setTicket(ticket?.url)
      setScene_id(ticket?.scene_id)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！', 1);
    }
  }, [])
  useEffect(() => {
    signtime = setTimeout(() => {
      if (countTime > 0) {
        setCountTime(c => c - 1); // ✅ 在这不依赖于外部的 `count` 变量
      }
    }, 1000);
    if (countTime === 0) {
      clearTimeout(signtime)
    }
  }, [countTime])
  const validationPhone = (rule, data) => {
    if (!data) {
      return Promise.reject('手机号不能为空');
    } else {
      // let myreg = /^1|9\d{10}$/
      let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!myreg.test(data)) {
        return Promise.reject('手机号必须为11位有效数字');
      } else {
        return Promise.resolve();
      }
    }
  }
  const validationCode = (rule, data) => {
    if (!data) {
      return Promise.reject('图形验证码不能为空');
    } else {
      // let myreg = /^1|9\d{10}$/
      if (data.length !== 4) {
        return Promise.reject('请输入正确的4位验证码');
      } else {
        return Promise.resolve();
      }
    }
  }
  const registerFun = useCallback(async (params) => {
    try {
      // const { sms_token } = await smsToken()
      const type = props.type
      const scene_id = props.scene_id
      const res = await wxBind({...params,type, scene_id, captcha_id: verifyDate})
      // tools?.setAuthorization(res?.token)
      // sessionStorage.setItem('s_id', res?.s_id)
      // tools?.setAuthorization(res?.token)
      if (res?.code === 3) {
        changeVisble('wxbind','register', props.scene_id)
        // history.push('/wxbind/'+'register/' + props.match.params.scene_id + '/' + props.match.params.from)
        message.info('手机号未注册，请先注册')
        registerForm.resetFields()
      } else {
        message.success(props.type=== 'login' ? '登录成功' : '注册成功')
        changeVisble('')
        setLogin(res?.user_info || {})
        sessionStorage.setItem('isLogin',true)
      }
      setCountTime(0)
      clearTimeout(signtime)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || errorInfo[0]?.errorFields[0] || '系统正在卖力成长中，请稍后重试！', 1);
    }
  }, [verifyDate, props.type])
  const sendcode = useCallback(async () => {
    if (!!countTime) {
      return
    }
    try {
      const mobile = await registerForm.validateFields(['mobile']);
      const { sms_token } = await smsToken()
      !!mobile && await getVerifyCode({ ...mobile, sms_token })
      setCountTime(60)
      message.success('验证码已发送')
    } catch (errorInfo) {
      console.log('errorInfo验证码发送', errorInfo.msg)
      message.error(errorInfo?.msg || errorInfo?.errorFields[0]?.errors[0] || '系统正在卖力成长中，请稍后重试！', 1);
    }
  }, [countTime, registerForm])
  const toRegister = async () => {
    const NewFormvalues = await registerForm.validateFields();
    registerFun(NewFormvalues)
  }

  const goBack = (e) => {
    if (e.target.id === 'wxregister') {
      changeVisble('')
      // history.push('/' + props.match.params.from)
    }
  }

  return <div className={styles.register} id='wxregister' onClick={goBack}>
    <div className={styles.registerForm}>
      {
        !registerType && (
          <Form form={registerForm} name="control-hooks" >
            <Form.Item
              label=''
              name='mobile'
              rules={[{ required: true, validator: validationPhone }]}
            >
              <Input
                type={'mobile'}

                maxLength={11}
                placeholder="请输入手机号"
                // moneyKeyboardAlign="left"
              />
            </Form.Item>

            {/* </div> */}
            <Form.Item
              name={'code'}
              rules={[{ required: true, message: '短信验证码不能为空' }]}
            >
              <Input
                type={'number'}
                name={'code'}
                placeholder="请输入短信验证码"
                suffix={<span style={{ color: '#1890FF' }} onClick={sendcode}>{!!countTime ? countTime + 's' : '发送验证码'}</span>}
                {...getFieldValue('code', {
                  onChange() { }, // have to write original onChange here if you need
                  rules: [{ required: true, message: '短信验证码不能为空' }],
                })}
              />
            </Form.Item>
           
            <Form.Item
              name='verify'
              rules={[{ required: true, validator: validationCode}]}
              key={props.type}
            >
              <Input
                placeholder="验证码"
                suffix={<img key={verifyDate} src={`${getApiHost()}jkapi/Login/verify?captcha_id=${verifyDate}`}  onClick={() => setVerifyDate(md5(Date.parse(moment()).toString()))} />}
              />
            </Form.Item>
          </Form>
        ) || (
          <div className={styles.code}>
            <img src={ticketUrl} alt="微信扫描二维码登录" />
          </div>
        )
      }
      {
        !registerType && (
          <div className={styles.btn}>
            <Button type="primary" disabled={disabl} onClick={toRegister}>{props.type === 'login' ? '登录':'注册'}
            </Button>
          </div>
        )
      }
    </div>
  </div>
}
export default Register;