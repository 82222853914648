import request from '@/utils/request';

export const hotVideoRequest = (params) => {
    return request({
        url: '/jkapi/DouyinShortVideo/index',
        method: 'POST',
        data: { ...params }
    })
}

export const disassembleCaseRequest = (params) => {
    return request({
        url: '/jkapi/DouyinShortVideoTeardowns/TeardownsList',
        method: 'POST',
        data: { ...params }
    })
}

export const myDisassembleRequest = (params) => {
    return request({
        url: '/jkapi/DouyinShortVideoTeardowns/myTeardowns',
        method: 'POST',
        data: { ...params }
    })
}

export const changeStatusRequest = (params) => {
    return request({
        url: '/jkapi/DouyinShortVideoTeardowns/changeStatus',
        method: 'POST',
        data: { ...params }
    })
}

export const myCollectionRequest = (params) => {
    return request({
        url: '/jkapi/DouyinVideoTeardownsCollect/myCollect',
        method: 'POST',
        data: { ...params }
    })
}

export const cancelConllectRequest = (params) => {
    return request({
        url: '/jkapi/DouyinVideoTeardownsCollect/collectTeardowns',
        method: 'POST',
        data: { ...params }
    })
}