import React, { useEffect, useState } from "react";
import { Button, message, Tooltip } from "antd";
import ReadCase from "./components/ReadCase";
import Part from "../disassemble/part";
import zf from "@/assets/img/zf.png";
import v2 from "@/assets/img/v2.png";
import v3 from "@/assets/img/v3.png";
import showcode from "@/assets/img/showcode.png";
import { getInfo, getPeopleNum, dsvtInfo } from "../service";
import styles from "./index.module.scss";

const LearnToDisassemble = (props) => {
  // 视频详情
  const [info, setInfo] = useState(null);
  const [peopleNum, setPeopleNum] = useState(0);
  const [dsvtresInfo, setDsvtresInfo] = useState(null);

  useEffect(() => {
    getDismantlingCase();
    getInfoFun();
    getPeopleNumFun();
    document.getElementById("root").scrollTop = 0;
  }, []);

  // 获取视频信息
  const getInfoFun = async () => {
    message.destroy();
    try {
      const res = await getInfo(props.match.params.video_id);
      setInfo(res);
    } catch (errorInfo) {
      message.error(errorInfo?.msg || "系统正在卖力成长中，请稍后重试！");
      console.log("Failed:", errorInfo);
    }
  };

  // 获取拆解人数
  const getPeopleNumFun = async () => {
    message.destroy();
    try {
      const res = await getPeopleNum(props.match.params.video_id);
      setPeopleNum(res || 0);
    } catch (errorInfo) {
      message.error(errorInfo?.msg || "系统正在卖力成长中，请稍后重试！");
      console.log("Failed:", errorInfo);
    }
  };

  //   获取推荐案例
  const getDismantlingCase = async () => {
    message.destroy();
    try {
      const res = await dsvtInfo({ dsvt_id: props.match.params.firstdsvt_id });
      setDsvtresInfo(res);
    } catch (errorInfo) {
      message.error(errorInfo?.msg || "系统正在卖力成长中，请稍后重试！");
      console.log("Failed:", errorInfo);
    }
  };

  const toVideo = () => {
    window.open(`https://www.iesdouyin.com/share/video/${info.video_id}`);
  };

  const toIndex = () => {
    window.open(`https://www.douyin.com/user/${info?.sec_uid}`);
  };

  const readCaseProps = {
    dsvtresInfo,
  };

  return (
    <div className={styles.learnContainer}>
      <div className={styles.bread}>
        <div className={styles.breadItem}>学拆解</div>
      </div>
      <div className={styles.learnContent}>
        <div className={styles.learnLeft}>
          {!!dsvtresInfo && <ReadCase {...readCaseProps} />}
          <div className={styles.recommend}>
            <div className={styles.topTenList}>
              <div className={styles.videoBox}>
                {
                  !!info?.url && (
                    <video
                      controls
                      key={"disssmblevideo"}
                      controlsList="nodownload"
                      name="media"
                      src={info?.url}
                    ></video>
                  )
                }
              </div>
              <div className={styles.topTenListTitle} onClick={toVideo}>
                {info?.title}
              </div>
              <div className={styles.videoInfo}>
                <div className={styles.infoItem}>
                  <img src={zf} alt="" />
                  &nbsp;
                  {info?.forward_num}
                </div>
                <div className={styles.infoItem}>
                  <img src={v2} alt="" />
                  &nbsp;
                  {info?.like_num}
                </div>
                <div className={styles.infoItem}>
                  <img src={v3} alt="" />
                  &nbsp;
                  {info?.comment_num}
                </div>
              </div>
              <div className={styles.userInfo}>
                <div
                  className={styles.userImgContainer}
                  onClick={() =>
                    window.open(`https://www.douyin.com/user/${info?.sec_uid}`)
                  }
                >
                  <img src={info?.head_img} alt="" />
                </div>
                <span
                  className={styles.userName}
                  onClick={() =>
                    window.open(`https://www.douyin.com/user/${info?.sec_uid}`)
                  }
                >
                  {info?.nickname}
                </span>
                <Tooltip
                  placement="bottomLeft"
                  getPopupContainer={() => document.getElementById("root")}
                  overlayClassName="codeTooltip"
                  title={
                    <>
                      <img className="dareninfocode" src={info?.qr_code} />
                      <div className="dareninfocodeTitle">
                        抖音扫一扫，查看达人主页
                      </div>
                    </>
                  }
                  color="#ffffff"
                >
                  <div className={styles.codeIcon}>
                    <img src={showcode} alt="" />
                  </div>
                </Tooltip>
              </div>
              <Button
                className={styles.toIndexBtn}
                style={{ fontSize: "16px",height: "40px" }}
                onClick={toIndex}
              >
                前往达人作品主页
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.learnRight}>
          <div className={styles.peopleNum}>
            已有{peopleNum}人参与此视频拆解
          </div>
          <Part
            video_id={props.match.params.video_id}
            dsvt_id={props.match.params.seconddsvt_id}
            learnDsvt_id={props.match.params.firstdsvt_id}
            isLearn={true}
          />
        </div>
      </div>
    </div>
  );
};

export default LearnToDisassemble;
