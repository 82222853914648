// import { columns } from '@/src/utils/formdata.js';
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss';
import { save, dsvtInfo } from './../../service';
import { Button, Form, Input, Select, message, Spin } from 'antd'
import { emotional_element, mood_element, other_element, title_template, quotation_type, photography_way, purpose, point_element, end_template, reusability, editing_skill, start_template, guide, provide_value } from './constant'
import Check from './checks';
import SelectCheck from './selectCheck'
import Selects from './select'
const { Option } = Select;
const { TextArea } = Input;
const Part = (props) => {
  //isLearn是学拆解页面进入的，learnDsvt_id是被学的案例id
  const { isLearn, learnDsvt_id } = props;
  const [partForm] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    document.getElementById('root').scrollTop = 0;
    // getDisassembleInfo()
    if (props?.dsvt_id != 0) {
      getDisassembleInfo()
    } else {
      resetCanstant()
      partForm.resetFields();
      partForm.setFieldsValue({
        emotional_element, mood_element, other_element, title_template, quotation_type, photography_way, purpose, point_element, end_template, reusability, editing_skill, start_template, provide_value
      });
    }
  }, [props.video_id, props.dsvt_id])
  const resetCanstant = () => {
    reusability.map(i => { i.checked = false; i.inner = ''; return i; })
    end_template.map(i => { i.checked = false; i.inner = ''; return i; })
    editing_skill.map(i => { i.checked = false; i.inner = ''; return i; })
    provide_value.map(i => { i.checked = false; i.inner = ''; return i; })
    emotional_element.map(i => { i.checked = false; i.inner = ''; return i; })
    mood_element.map(i => { i.checked = false; i.inner = ''; return i; })
    other_element.map(i => { i.checked = false; i.inner = ''; return i; })
    point_element.map(i => { i.checked = false; i.inner = ''; return i; })
    end_template.map(i => { i.checked = false; i.inner = ''; return i; })


    start_template.map(i => { i.selected = false; i.inner = ''; return i; })
    title_template.map(i => { i.selected = false; i.inner = ''; return i; })
    quotation_type.map(i => { i.selected = false; i.inner = ''; return i; })
    photography_way.map(i => { i.selected = false; i.inner = ''; return i; })
    purpose.map(i => { i.selected = false; i.inner = ''; return i; })
  }
  const getDisassembleInfo = async () => {
    setLoading(true)
    try {
      const res = await dsvtInfo(props.dsvt_id)
      restoreForm(res)
    } catch (errorInfo) {
      console.log(errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 600);
  };
  const restoreForm = (params) => {
    const { analyze_info, base_info } = params;
    
    const { comprehensive, copy, film_clip, overview, topic } = analyze_info;
    const { create_time, dsvt_id, is_collect, name, title, video_title } = base_info;
  
    const {
       evaluation,
      //  guide,
      //   provide_value, 
      //   purpose 
      } = comprehensive;
   
    const {
      document_analyze,
      // end_template,
      frame_structure,
      // point_element,
      quotation,
      // quotation_type,
      // start_template,
      title_analyze,
      // title_template,
    } = copy;
    const {
    //   editing_skill,
    // photography_way,
    scene
    } = film_clip;
    const {
      body_movements,
      expression_management,
      language_tone,
    } = scene
    const {
      // element, 
      // reusability, 
      topic_selection
    } = topic
    let newValue = {

      emotional_element: !!analyze_info?.topic?.element?.emotional_element && JSON.parse(analyze_info?.topic?.element?.emotional_element)?.map(i => {
        i.checked = true;
        return i
      }).concat(emotional_element).filter((item, index, self) => {
        return self.findIndex(el => el.title == item.title) === index
      }) || emotional_element,

      mood_element: !!analyze_info?.topic?.element?.mood_element && JSON.parse(analyze_info?.topic?.element?.mood_element)?.map(i => {
        i.checked = true;
        return i
      }).concat(mood_element).filter((item, index, self) => {
        return self.findIndex(el => el.title == item.title) === index
      }) || mood_element,

      other_element: !!analyze_info?.topic?.element?.other_element && JSON.parse(analyze_info?.topic?.element?.other_element)?.map(i => {
        i.checked = true;
        return i
      }).concat(other_element).filter((item, index, self) => {
        return self.findIndex(el => el.title == item.title) === index
      }) || other_element,

      title_template: !!analyze_info?.copy?.title_template && JSON.parse(analyze_info?.copy?.title_template)?.map(i => {
        i.selected = true;
        return i
      }).concat(title_template).filter((item, index, self) => {
        return self.findIndex(el => el.title == item.title) === index
      }) || title_template,
      quotation_type: !!analyze_info?.copy?.quotation_type && JSON.parse(analyze_info?.copy?.quotation_type)?.map(i => {
        i.selected = true;
        return i
      }).concat(quotation_type).filter((item, index, self) => {
        return self.findIndex(el => el.title == item.title) === index
      }) || quotation_type,
      photography_way: !!analyze_info?.film_clip?.photography_way && JSON.parse(analyze_info?.film_clip?.photography_way)?.map(i => {
        i.selected = true;
        return i
      }).concat(photography_way).filter((item, index, self) => {
        return self.findIndex(el => el.title == item.title) === index
      }) || photography_way,
      purpose: !!analyze_info?.comprehensive?.purpose && JSON.parse(analyze_info?.comprehensive?.purpose)?.map(i => {
        i.selected = true;
        return i
      }).concat(purpose).filter((item, index, self) => {
        return self.findIndex(el => el.title == item.title) === index
      }) || purpose,
      provide_value: !!analyze_info?.comprehensive?.provide_value && JSON.parse(analyze_info?.comprehensive?.provide_value)?.map(i => {
        i.checked = true;
        return i
      }).concat(provide_value).filter((item, index, self) => {
        return self.findIndex(el => el.title == item.title) === index
      }) || provide_value,

      point_element: !!analyze_info?.copy?.point_element && JSON.parse(analyze_info?.copy?.point_element)?.map(i => {
        i.checked = true;
        return i
      }).concat(point_element).filter((item, index, self) => {
        return self.findIndex(el => el.title == item.title) === index
      }) || point_element,

      end_template: !!analyze_info?.copy?.end_template && JSON.parse(analyze_info?.copy?.end_template)?.map(i => {
        i.checked = true;
        return i
      }).concat(end_template).filter((item, index, self) => {
        return self.findIndex(el => el.title == item.title) === index
      }) || end_template,

      reusability: !!analyze_info?.topic?.reusability && JSON.parse(analyze_info?.topic?.reusability)?.map(i => {
        i.checked = true;
        return i
      }).concat(reusability).filter((item, index, self) => {
        return self.findIndex(el => el.title == item.title) === index
      }) || reusability,
      editing_skill: !!analyze_info?.film_clip?.editing_skill && JSON.parse(analyze_info?.film_clip?.editing_skill)?.map(i => {
        i.checked = true;
        return i
      }).concat(editing_skill).filter((item, index, self) => {
        return self.findIndex(el => el.title == item.title) === index
      }) || editing_skill,

      start_template: !!analyze_info?.copy?.start_template && JSON.parse(analyze_info?.copy?.start_template)?.map(i => {
        i.selected = true;
        return i
      }).concat(start_template).filter((item, index, self) => {
        return self.findIndex(el => el.title == item.title) === index
      }) || start_template,
      guide: analyze_info?.comprehensive?.guide && JSON.parse(analyze_info?.comprehensive?.guide) || [],

      frame_structure,
      document_analyze,
      evaluation,
      quotation,
      overview,
      title_analyze,
      topic_selection,
      expression_management,
      body_movements,
      language_tone,
      title
    }
    partForm.setFieldsValue({
      ...newValue
    })

  }

  const changedForm = async (formvalues, status) => {
    message.destroy()
    try {
      const { dsvt_id } = props;
      let { reusability, editing_skill, provide_value, start_template, title_template, mood_element, other_element, emotional_element, point_element, end_template, quotation_type, photography_way, purpose, guide } = formvalues;

      guide = JSON.stringify(guide);
      if (guide === '[]') {
        guide = ''
      }
      let new_reusability = JSON.stringify(reusability?.filter(i => i.checked)?.map(i => {
        return {
          title: i.title,
          inner: i.inner
        }
      }))
      if (new_reusability === '[]') {
        new_reusability = ''
      }
      let new_editing_skill = JSON.stringify(editing_skill.filter(i => i.checked)?.map(i => {
        return {
          title: i.title,
          inner: i.inner
        }
      }))
      if (new_editing_skill === '[]') {
        new_editing_skill = ''
      }
      let new_start_template = JSON.stringify(start_template.filter(i => i.selected)?.map(i => {
        return {
          title: i.title,
          inner: i.inner
        }
      }))
      if (new_start_template === '[]') {
        new_start_template = ''
      }
      let new_title_template = JSON.stringify(title_template.filter(i => i.selected)?.map(i => {
        return {
          title: i.title,
          inner: i.inner
        }
      }))
      if (new_title_template === '[]') {
        new_title_template = ''
      }
      let new_mood_element = JSON.stringify(mood_element.filter(i => i.checked)?.map(i => {
        return {
          title: i.title,
          inner: i.inner
        }
      }))
      if (new_mood_element === '[]') {
        new_mood_element = ''
      }
      let new_other_element = JSON.stringify(other_element.filter(i => i.checked)?.map(i => {
        return {
          title: i.title,
          inner: i.inner
        }
      }))
      if (new_other_element === '[]') {
        new_other_element = ''
      }
      let new_emotional_element = JSON.stringify(emotional_element.filter(i => i.checked)?.map(i => {
        return {
          title: i.title,
          inner: i.inner
        }
      }))
      if (new_emotional_element === '[]') {
        new_emotional_element = ''
      }
      let new_point_element = JSON.stringify(point_element.filter(i => i.checked)?.map(i => {
        return {
          title: i.title,
          inner: i.inner
        }
      }))
      if (new_point_element === '[]') {
        new_point_element = ''
      }
      let new_end_template = JSON.stringify(end_template.filter(i => i.checked)?.map(i => {
        return {
          title: i.title,
          inner: i.inner
        }
      }))
      if (new_end_template === '[]') {
        new_end_template = ''
      }
      let new_quotation_type = JSON.stringify(quotation_type.filter(i => i.selected)?.map(i => {
        return {
          title: i.title,
          inner: i.inner
        }
      }))
      if (new_quotation_type === '[]') {
        new_quotation_type = ''
      }
      let new_photography_way = JSON.stringify(photography_way.filter(i => i.selected)?.map(i => {
        return {
          title: i.title,
          inner: i.inner
        }
      }))
      if (new_photography_way === '[]') {
        new_photography_way = ''
      }
      let new_purpose = JSON.stringify(purpose.filter(i => i.selected)?.map(i => {
        return {
          title: i.title,
          inner: i.inner
        }
      }))
      if (new_purpose === '[]') {
        new_purpose = ''
      }
      let new_provide_value = JSON.stringify(provide_value.filter(i => i.checked)?.map(i => {
        return {
          title: i.title,
          inner: i.inner
        }
      }))
      if (new_provide_value === '[]') {
        new_provide_value = ''
      }

      const values = { reusability: new_reusability, editing_skill: new_editing_skill, start_template:new_start_template, title_template:new_title_template, mood_element: new_mood_element, other_element: new_other_element, emotional_element: new_emotional_element, point_element:new_point_element, end_template:new_end_template, quotation_type:new_quotation_type, photography_way: new_photography_way, purpose: new_purpose, provide_value: new_provide_value, guide }
      let video_id = props.video_id
      const params = {
        ...formvalues,
        ...values,
        status,
        tag: isLearn ? 0 : 1,
        video_id,
        l_dsvt_id: isLearn ? learnDsvt_id : undefined,
        dsvt_id: dsvt_id === '0'? undefined : dsvt_id,
      }
      if (status === 0) {
        await save({ ...params })
        message.success('草稿保存成功')
        setTimeout(() => {
          history.push(`/video`)
        }, 1000);
      } else if (status === 1) {
        await save({ ...params })
        message.success('提交成功')
        setTimeout(() => {
          history.push(`/video`)
        }, 1000);
      }
     


    } catch (errorInfo) {
      message.error(errorInfo?.msg || '提交失败')
      console.log(errorInfo);
    }
  }
  const handleShare = async () => {
    try {
      // partForm.submit()
      const formvalues = await partForm.getFieldsValue();
      const status = 0
      changedForm(formvalues, status)
      // formvalues.moment(dateParams.startT, 'YYYY/MM/DD')
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };
  const handleOk = async () => {
    try {
      partForm.submit()
      const formvalues = await partForm.validateFields();
      const status = 1
      changedForm(formvalues, status)
      // formvalues.moment(dateParams.startT, 'YYYY/MM/DD')
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };
  const change_guidee = useCallback((e, dataSource, item) => {
    const guide = dataSource
    const val = e.target.value
    guide?.map(i => {
      if (val === i.title) {
        i.title = val
        i.checked = e.target.checked
      }
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = val
        }
      }
      return i;
    })
    partForm.setFieldsValue({
      'guide':
        guide
    })
  }, [partForm])

  const change_point_element = useCallback((e, dataSource, item) => {
    const point_element = dataSource
    const val = e.target.value
    point_element?.map(i => {
      if (val === i.title) {
        i.title = val
        i.checked = e.target.checked
      }
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = val
        }
      }
      return i;
    })
    partForm.setFieldsValue({
      'point_element':
        point_element
    })
  }, [partForm])
  const change_end_template = useCallback((e, dataSource, item) => {
    const end_template = dataSource
    const val = e.target.value
    end_template?.map(i => {
      if (val === i.title) {
        i.title = val
        i.checked = e.target.checked
      }
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = val
        }
      }
      return i;
    })
    partForm.setFieldsValue({
      'end_template':
        end_template
    })
  }, [partForm])

  const change_provide_value = useCallback((e, dataSource, item) => {
    const provide_value = dataSource
    const val = e.target.value
    provide_value?.map(i => {
      if (val === i.title) {
        i.title = val
        i.checked = e.target.checked
      }
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = val
        }
      }
      return i;
    })
    partForm.setFieldsValue({
      'provide_value':
        provide_value
    })
  }, [partForm])
  const change_reusability = useCallback((e, dataSource, item) => {
    const reusability = dataSource
    const val = e.target.value
    reusability?.map(i => {
      if (val === i.title) {
        i.title = val
        i.checked = e.target.checked
      }
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = val
        }
      }
      return i;
    })
    partForm.setFieldsValue({
      'reusability':
        reusability
    })
  }, [partForm])
  const change_editing_skill = useCallback((e, dataSource, item) => {

    const editing_skill = dataSource
    editing_skill?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = e.target.value
        }
      } else if (e.some(t => t === i.title)) {
        i.checked = true
      } else if (!e.some(t => t === i.title)) {
        i.checked = false
      }
      return i;
    })
    partForm.setFieldsValue({
      'editing_skill':
        editing_skill
    })
  }, [partForm])
  const change_start_template = useCallback((e, dataSource, item) => {
    const start_template = dataSource
    start_template?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = e.target.value
        }
      } else if (e === i.title) {
        i.selected = true
      } else {
        i.selected = false
      }
      return i;
    })
    partForm.setFieldsValue({
      'start_template':
        start_template
    })
  }, [partForm])

  const change_emotional_element = useCallback((e, dataSource, item) => {
    const emotional_element = dataSource
    emotional_element?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = e.target.value
        }
      } else if (e.some(t => t === i.title)) {
        i.checked = true
      } else if (!e.some(t => t === i.title)) {
        i.checked = false
      }
      return i;
    })
    partForm.setFieldsValue({
      'emotional_element':
        emotional_element
    })
  }, [partForm])
  const change_mood_element = useCallback((e, dataSource, item) => {
    const mood_element = dataSource
    mood_element?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = e.target.value
        }
      } else if (e.some(t => t === i.title)) {
        i.checked = true
      } else if (!e.some(t => t === i.title)) {
        i.checked = false
      }
      return i;
    })
    partForm.setFieldsValue({
      'mood_element':
        mood_element
    })
  }, [partForm])
  const change_title_template = useCallback((e, dataSource, item) => {
    const title_template = dataSource
    title_template?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = e.target.value
        }
      } else if (e === i.title) {
        i.selected = true
      } else {
        i.selected = false
      }
      return i;
    })
    partForm.setFieldsValue({
      'title_template':
        title_template
    })
  }, [partForm])
  const change_quotation_type = useCallback((e, dataSource, item) => {
    const quotation_type = dataSource
    quotation_type?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = e.target.value
        }
      } else if (e === i.title) {
        i.selected = true
      } else {
        i.selected = false
      }
      return i;
    })
    partForm.setFieldsValue({
      'quotation_type':
        quotation_type
    })
  }, [partForm])
  const change_photography_way = useCallback((e, dataSource, item) => {
    const photography_way = dataSource
    photography_way?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = e.target.value
        }
      } else if (e === i.title) {
        i.selected = true
      } else {
        i.selected = false
      }
      return i;
    })
    partForm.setFieldsValue({
      'photography_way':
        photography_way
    })
  }, [partForm])
  const change_purpose = useCallback((e, dataSource, item) => {
    const purpose = dataSource
    purpose?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = e.target.value
        }
      } else if (e === i.title) {
        i.selected = true
      } else {
        i.selected = false
      }
      return i;
    })
    partForm.setFieldsValue({
      'purpose':
        purpose
    })
  }, [partForm])

  const change_other_element = useCallback((e, dataSource, item) => {
    const other_element = dataSource
    other_element?.map(i => {
      if (e?.type === 'blur') {
        if (item.title === i.title) {
          i.inner = e.target.value
        }
      } else if (e.some(t => t === i.title)) {
        i.checked = true
      } else if (!e.some(t => t === i.title)) {
        i.checked = false
      }
      return i;
    })
    partForm.setFieldsValue({
      'other_element':
        other_element
    })
  }, [partForm])


  const check_reusability = (rule, value) => {
    if (Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.checked))
      if (checvalue === '[]' || !checvalue) {
        return Promise.reject('请选择该选题的复用性')
      } else {
        let uninner = JSON.parse(checvalue)?.filter(i => !i.inner)
        if (uninner.length === 0) {
          return Promise.resolve();
        } else {
          return Promise.reject('请输入' + uninner[0]?.title)
        }

      }
    }
  }

  const check_point_element = (rule, value) => {
    if (Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.checked))
      if (checvalue === '[]' || !checvalue) {
        return Promise.reject('请选择该视频中间部分，包含哪些爆点元素')
      } else {
        let uninner = JSON.parse(checvalue)?.filter(i => !i.inner)
        if (uninner.length === 0) {
          return Promise.resolve();
        } else {
          return Promise.reject('请输入' + uninner[0]?.title)
        }

      }
    }
  }

  const check_end_template = (rule, value) => {
    if (Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.checked))
      if (checvalue === '[]' || !checvalue) {
        return Promise.reject('请选择该视频结尾部分符合哪种模板')
      } else {
        let uninner = JSON.parse(checvalue)?.filter(i => !i.inner)
        if (uninner.length === 0) {
          return Promise.resolve();
        } else {
          return Promise.reject('请输入' + uninner[0]?.title)
        }

      }
    }
  }
  const check_provide_value = (rule, value) => {
    if (Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.checked))
      if (checvalue === '[]' || !checvalue) {
        return Promise.reject('请选择该视频提供的价值')
      } else {
        let uninner = JSON.parse(checvalue)?.filter(i => !i.inner)
        if (uninner.length === 0) {
          return Promise.resolve();
        } else {
          return Promise.reject('请输入' + uninner[0]?.title)
        }

      }
    }
  }

  const check_emotional_element = (rule, value) => {
    if (Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.checked))
      if (checvalue === '[]' || !checvalue) {
        return Promise.reject('该选题包含哪些情感元素')
      } else {
        let uninner = JSON.parse(checvalue)?.filter(i => i.title === '其他')
        if (uninner.length === 0) {
          return Promise.resolve();
        } else {
          if (!!uninner[0].inner) {
            return Promise.resolve();
          } else {
            return Promise.reject('请输入其他情感元素')
          }
        }

      }
    }
  }
  const check_mood_element = (rule, value) => {
    if (Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.checked))
      if (checvalue === '[]' || !checvalue) {
        return Promise.reject('该选题包含哪些情绪元素')
      } else {
        let uninner = JSON.parse(checvalue)?.filter(i => i.title === '其他')
        if (uninner.length === 0) {
          return Promise.resolve();
        } else {
          if (!!uninner[0].inner) {
            return Promise.resolve();
          } else {
            return Promise.reject('请输入其他情绪元素')
          }
        }

      }
    }
  }
  const check_other_element = (rule, value) => {
    if (Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.checked))
      if (checvalue === '[]' || !checvalue) {
        return Promise.reject('该选题包含哪些其他元素')
      } else {
        let uninner = JSON.parse(checvalue)?.filter(i => i.title === '其他')
        if (uninner.length === 0) {
          return Promise.resolve();
        } else {
          if (!!uninner[0].inner) {
            return Promise.resolve();
          } else {
            return Promise.reject('请输入其他元素')
          }
        }

      }
    }
  }
  const check_title_template = (rule, value) => {
    if (Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.selected))
      if (checvalue === '[]' || !checvalue) {
        return Promise.reject('请选择该标题模版')
      } else {
        let uninner = JSON.parse(checvalue)?.filter(i => i.title === '其他')
        if (uninner.length === 0) {
          return Promise.resolve();
        } else {
          if (!!uninner[0].inner) {
            return Promise.resolve();
          } else {
            return Promise.reject('请输入其他模版')
          }
        }

      }
    }
  }


  const check_quotation_type = (rule, value) => {
    if (Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.selected))
      if (checvalue === '[]' || !checvalue) {
        return Promise.reject('请选择该引用语类型')
      } else {
        let uninner = JSON.parse(checvalue)?.filter(i => i.title === '其他')
        if (uninner.length === 0) {
          return Promise.resolve();
        } else {
          if (!!uninner[0].inner) {
            return Promise.resolve();
          } else {
            return Promise.reject('请输入其他类型')
          }
        }

      }
    }
  }

  const check_editing_skill = (rule, value) => {
    if (Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.checked))
      if (checvalue === '[]' || !checvalue) {
        return Promise.reject('请选择该剪辑技巧吸引用户')
      } else {
        let uninner = JSON.parse(checvalue)?.filter(i => i.title === '其他')

        if (uninner.length === 0) {
          return Promise.resolve();
        } else {
          if (!!uninner[0].inner) {
            return Promise.resolve();
          } else {
            return Promise.reject('请输入其他剪辑技巧')
          }
        }

      }
    }
  }


  const check_photography_way = (rule, value) => {
    if (Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.selected))
      if (checvalue === '[]' || !checvalue) {
        return Promise.reject('请选择该视频的拍摄方式')
      } else {
        let uninner = JSON.parse(checvalue)?.filter(i => i.title === '其他')
        if (uninner.length === 0) {
          return Promise.resolve();
        } else {
          if (!!uninner[0].inner) {
            return Promise.resolve();
          } else {
            return Promise.reject('请输入其他拍摄方式')
          }
        }

      }
    }
  }

  const check_purpose = (rule, value) => {
    if (Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.selected))
      if (checvalue === '[]' || !checvalue) {
        return Promise.reject('请选择该视频的主要目的')
      } else {
        let uninner = JSON.parse(checvalue)?.filter(i => i.title === '其他')
        if (uninner.length === 0) {
          return Promise.resolve();
        } else {
          if (!!uninner[0].inner) {
            return Promise.resolve();
          } else {
            return Promise.reject('请输入该视频的其他目的')
          }
        }

      }
    }
  }



  const check_start_template = (rule, value) => {
    if (Array.isArray(value)) {
      const checvalue = JSON.stringify(value?.filter(i => i.selected))
      if (checvalue === '[]' || !checvalue) {
        return Promise.reject('请选择该视频开头部分符合哪种模板')
      } else {
        let uninner = JSON.parse(checvalue)?.filter(i => i.title === '其他')

        if (uninner.length === 0) {
          return Promise.resolve();
        } else {
          if (!!uninner[0].inner) {
            return Promise.resolve();
          } else {
            return Promise.reject('请输入其他模板')
          }
        }

      }
    }
  }


  const toList = () => {
    history.push(`/video`)
  }

  return <div className={styles.part}>
    <Spin size="large" spinning={loading}>
      <Form
        name="partForm"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        colon={false}
        form={partForm}
        className={'partForm'}
      >
        <Form.Item
          label="拆解分析标题"
          name="title"
          rules={[{ required: true, message: '请输入分析标题' }]}
        >
          <TextArea autoSize={{ minRows: 2, maxRows: 2 }} placeholder='请输入'/>
        </Form.Item>
        <div className={styles.titleBox}>
          <span className={styles.title}>
            概述
          </span >
          <span className={styles.content}>
            （每个视频都是账号的一部分，先了解账号基本信息，有助于视频拆解）
          </span>
        </div>
        <Form.Item
          label="账号概述，请用50字描述该账号："
          name="overview"
          rules={[{ required: false, message: '请输入' }]}
        >
          <TextArea maxLength={200} autoSize={{ minRows: 2, maxRows: 2 }} placeholder="为哪些人群解决了什么问题？变现方式有哪些？"/>
        </Form.Item>
        <div className={styles.titleBox}>
          <span className={styles.title}>
            选题
          </span >
          <span className={styles.content}>
            （选题不是标题，指的是该视频的主题、方向）
          </span>
        </div>
        <Form.Item
          label="该视频的选题是什么："
          name="topic_selection"

          rules={[{ required: true, message: "该视频的选题是什么" }]}
        >
          <TextArea autoSize={{ minRows: 2, maxRows: 2 }} maxLength={50} placeholder='请输入'/>
        </Form.Item>

        <Form.Item
          label="该选题包含哪些元素--情感元素："
          name="emotional_element"

          rules={[{ required: true, validator: check_emotional_element }]}
        >
          <SelectCheck dataSource={partForm.getFieldValue('emotional_element')} onChange={change_emotional_element} />
        </Form.Item>
        <Form.Item
          label="该选题包含哪些元素--情绪元素："
          name="mood_element"
          rules={[{ required: true, validator: check_mood_element }]}
        // rules={[{ required: true}]}
        >
          <SelectCheck dataSource={partForm.getFieldValue('mood_element')} onChange={change_mood_element} />
        </Form.Item>

        <Form.Item
          label="该选题包含哪些元素--其他元素："
          name="other_element"

          rules={[{ required: true, validator: check_other_element }]}
        >
          <SelectCheck dataSource={partForm.getFieldValue('other_element')} onChange={change_other_element} />
        </Form.Item>

        <Form.Item
          label="该选题复用性体现在哪些方面："
          name="reusability"
          // style={{marginTop: '-32px'}}
          rules={[{ required: true, validator: check_reusability }]}
        >
          <Check dataSource={partForm.getFieldValue('reusability')}  onChange={change_reusability} />

        </Form.Item>

        <div className={styles.titleBox}>
          <span className={styles.title}>
            文案
          </span >
          <span className={styles.content}>
            （文案部分包含标题、开头、中间、结尾、框架等方面）
          </span>
        </div>

        <Form.Item
          label="该视频的标题是什么："
          name="title_analyze"
          rules={[{ required: true, message: '该视频的标题是什么' }]}
        >
          <TextArea maxLength={200} placeholder='标题指视频封面或视频第一帧的文字' autoSize={{ minRows: 2, maxRows: 2 }} />
        </Form.Item>


        <Form.Item
          label="该标题属于哪种模板："
          name="title_template"

          rules={[{ required: true, validator: check_title_template }]}
        >
          <Selects dataSource={partForm.getFieldValue('title_template')} onChange={change_title_template} />
        </Form.Item>

        <Form.Item
          label="该视频引用语是什么："
          name="quotation"

          rules={[{ required: true, message: "请输入该视频的引用语" }]}
        >
          <TextArea maxLength={50} autoSize={{ minRows: 2, maxRows: 2 }} placeholder='引用语指视频左下方的文字'/>
        </Form.Item>



        <Form.Item
          label="该引用语属于哪一类型："
          name="quotation_type"

          rules={[{ required: true, validator: check_quotation_type }]}
        >
          <Selects dataSource={partForm.getFieldValue('quotation_type')} onChange={change_quotation_type} />
        </Form.Item>


        <Form.Item
          label="该视频开头部分符合哪种模板："
          name="start_template"
          // style={{marginTop: '-32px'}}
          rules={[{ required: true, validator: check_start_template }]}
        >
          <Selects dataSource={partForm.getFieldValue('start_template')} onChange={change_start_template} />

        </Form.Item>

        <Form.Item
          label="该视频中间部分，包含哪些爆点元素："
          name="point_element"
          // style={{marginTop: '-32px'}}
          rules={[{ required: true, validator: check_point_element}]}
        >
          <Check dataSource={partForm.getFieldValue('point_element')} onChange={change_point_element} />

        </Form.Item>

        <Form.Item
          label="该视频结尾部分符合哪种模板："
          name="end_template"
          // style={{marginTop: '-32px'}}
          rules={[{ required: true, validator: check_end_template}]}
        >
          <Check dataSource={partForm.getFieldValue('end_template')}  onChange={change_end_template} />

        </Form.Item>
        <Form.Item
          label="整个视频文案的框架结构是怎么样的："
          name="frame_structure"
          rules={[{ required: true, message: '请输入' }]}
        >
          <TextArea autoSize={{ minRows: 2, maxRows: 2 }} placeholder='常见的行文逻辑有三段式和递进式，请提炼出文案大纲'/>
        </Form.Item>

        <Form.Item
          label="该视频文案有哪些亮点或不足："
          name="evaluation"
          rules={[{ required: true, message: '请输入' }]}
        >
          <TextArea autoSize={{ minRows: 2, maxRows: 2 }} placeholder='亮点和不足都需分析'/>
        </Form.Item>

        <div className={styles.titleBox}>
          <span className={styles.title}>
            拍摄/剪辑
          </span >
          <span className={styles.content}>

          </span>
        </div>

        <Form.Item
          label="该视频的拍摄方式是什么："
          name="photography_way"

          rules={[{ required: true, validator: check_photography_way }]}
        >
          <Selects dataSource={partForm.getFieldValue('photography_way')} onChange={change_photography_way} />
        </Form.Item>
        <Form.Item
          label="该达人的镜头表现力-表情管理如何："
          name="expression_management"
          rules={[{ required: true, message: '请选择' }]}
        >
          <Select
            allowClear
            placeholder="请选择"
          >
            <Option key={'expression_management不好'} value={'不好'}>不好</Option>
            <Option key={'expression_management良好'} value={'良好'}>良好</Option>
            <Option key={'expression_management优秀'} value={'优秀'}>优秀</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="该达人的镜头表现力-肢体动作如何："
          name="body_movements"
          rules={[{ required: true, message: '请选择' }]}
        >
          <Select
            allowClear
            placeholder="请选择"
          >
            <Option key={'body_movements不好'} value={'不好'}>不好</Option>
            <Option key={'body_movements良好'} value={'良好'}>良好</Option>
            <Option key={'body_movements优秀'} value={'优秀'}>优秀</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="该达人的镜头表现力-语言声调如何："
          name="language_tone"
          rules={[{ required: true, message: '请选择' }]}
        >
          <Select
            allowClear
            placeholder="请选择"
          >
            <Option key={'language_tone不好'} value={'不好'}>不好</Option>
            <Option key={'language_tone良好'} value={'良好'}>良好</Option>
            <Option key={'language_tone优秀'} value={'优秀'}>优秀</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="该视频用了哪些剪辑技巧吸引用户："
          name="editing_skill"
          // style={{marginTop: '-32px'}}
          rules={[{ required: true, validator: check_editing_skill }]}
        >
          <SelectCheck dataSource={partForm.getFieldValue('editing_skill')} onChange={change_editing_skill} />

        </Form.Item>
        <div className={styles.titleBox}>
          <span className={styles.title}>
            综合分析
          </span >
          <span className={styles.content}>
            （分析完视频的选题、文案和拍剪后，再来做个总结吧）
          </span>
        </div>
        <Form.Item
          label="该视频给用户提供了哪些价值："
          name="provide_value"
          // style={{marginTop: '-32px'}}
          rules={[{ required: true, validator: check_provide_value }]}
        >
          <Check dataSource={partForm.getFieldValue('provide_value')} onChange={change_provide_value} />

        </Form.Item>

        <Form.Item
          label="该视频的主要目的是什么："
          name="purpose"

          rules={[{ required: true, validator: check_purpose }]}
        >
          <Selects dataSource={partForm.getFieldValue('purpose')} onChange={change_purpose} />
        </Form.Item>

        <Form.Item
          label="为达到目的，视频用了哪些引导方式："
          name="guide"
          // style={{marginTop: '-32px'}}
          rules={[{ required: true, message: "请输入引导方式" }]}
        >
          <Select
            mode="multiple"
            allowClear
            showArrow={true}
            placeholder="请选择"
          >
            {
              guide?.map(i => {
                return <Option key={'guide' + i.title} value={i.title}>{i.title}</Option>
              })
            }
          </Select>
        </Form.Item>


        <Form.Item
          label="该视频有哪些亮点和不足："
          name="document_analyze"
          rules={[{ required: true, message: '请输入' }]}
        >
          <TextArea autoSize={{ minRows: 2, maxRows: 2 }} placeholder='可从选题、文案、拍剪等角度分析'/>
        </Form.Item>


        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" style={{ marginRight: '16px' }} onClick={handleOk}>
            发布
          </Button>
          <Button style={{ marginRight: '16px' }} onClick={handleShare}>
            保存为草稿
          </Button>
          <Button onClick={toList}>
            取消
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  </div>
}
export default Part;

