export const tagList = [
    { title: '跟拆', key: 0, color: 'rgba(255, 180, 56, 0.08);', textColor: '#FFB438' },
    { title: '原创', key: 1, color: 'rgba(26, 179, 148, 0.08)', textColor: '#1AB394' },
];
export const statusList = [
    { title: '草稿', key: 0, color: 'rgba(255, 180, 56, 0.08)', textColor: '#FFB438' },
    { title: '已发布', key: 1, color: 'rgba(26, 179, 148, 0.08)', textColor: '#1AB394' },
    { title: '已隐藏', key: 2, color: 'rgba(247, 53, 63, 0.08)', textColor: '#E5303A' },
];
export const tabsList = [
    { title: '爆款视频', key: 'hot-video' },
    { title: '拆解案例', key: 'disassemble-case' },
    { title: '我拆解的', key: 'my-disassembled' },
    { title: '我收藏的', key: 'my-conllection' },
]