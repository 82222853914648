export function setLocal(key = '', value = {}) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    if (typeof value === 'string') {
      localStorage.setItem(key, value);
    }
  }
}

export function getLocal(key) {
  if (key) {
    const lr = localStorage.getItem(key);
    try {
      return lr ? JSON.parse(lr) : '';
    } catch (e) {
      return '';
    }
  }
}

export function setSession(key, value) {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log('sessionStorage set', e);
  }
}

export function getSession(key) {
  if (key) {
    try {
      const lr = sessionStorage.getItem(key);
      return lr ? JSON.parse(lr) : null;
    } catch (e) {
      console.log('sessionStorage get', e);
      return null;
    }
  }
}

export default {
  setLocal,
  getLocal,
  setSession,
  getSession,
};
