import { useHistory } from 'react-router-dom';
import { message, Tooltip, Button } from 'antd'
import { useState, useEffect, useContext } from 'react';
import Part from './part'
import showcode from "@/assets/img/showcode.png";
import { getInfo, getPeopleNum } from './../service'
import zf from '@/assets/img/zf.png'
import v2 from '@/assets/img/v2.png'
import v3 from '@/assets/img/v3.png'
import styles from './index.module.scss'
import ShowVip from '@/components/showVip';
import Addvip from '@/components/addVip'
import { GlobalContext } from '@/App'
const Works = (props) => {
  const history = useHistory();
  const { user_info, setloginVis } = useContext(GlobalContext);
  const [showAddVip, setShowAddVip] = useState(false)
  const [info, setInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [peopleNum, setPeopleNum] = useState(0)
  useEffect(() => {
    if (!!props.match.params.video_id) {
      getInfoFun()
      getPeopleNumFun()
    }
    if (!user_info?.is_vip) {
      document.getElementById('root').scrollTop = 0
      document.getElementById('root').style.overflow = 'hidden'
    } else {
      document.getElementById('root').style.overflow = 'auto'
    }
    return resetscroll
  }, [props.match.params.video_id, user_info?.is_vip])
  const resetscroll = () => {
    document.getElementById('root').style.overflow = 'auto'
  }
  // useEffect(() =>  {
  //   if (!!topTenList.length) {
  //     setIndex(0)
  //   }
  // }, [topTenList])
  const getPeopleNumFun = async () => {
    message.destroy()
    setLoading(true)
    try {
      const res = await getPeopleNum(props.match.params.video_id);
      setPeopleNum(res || 0)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setLoading(false)
  };
  const goVip = () => {
    if (!!user_info) {
      setShowAddVip(true)
    } else {
      setloginVis(true)
      // history.push('/login')
    }
  }
  const getInfoFun = async () => {
    message.destroy()
    setLoading(true)
    try {
      const res = await getInfo(props.match.params.video_id);
      setInfo(res)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setLoading(false)
  };

  const toTutorials = () => {
    // window.open(
    //   routerList?.filter(i => i?.path === props?.match?.path)[0]?.tutorials_url
    // )
  }
  const toVideo = () => {
    window.open('https://www.iesdouyin.com/share/video/' + info.video_id)
  }
  const toIndex = () => {
    // history.push('/works/' + props.match.params.dt_id)
    window.open('https://www.douyin.com/user/' + info?.sec_uid)
  }
  return <div className={styles.works}>
    <div className={styles.bread}>
      <div className={styles.breadItem}>
        视频拆解
      </div>
      {/* <div className={styles.teach} onClick={toTutorials}>
        <FileTextOutlined style={{ color: '#25B864', marginRight: '4px' }} />
        页面使用教程
      </div> */}
    </div>
    <div className={styles.worksContent}>
      <div className={styles.woksLeft}>
        {/* {
          !!info && (
            <InfoItem record={info} />
          )
        } */}
        <div className={styles.recommend}>

          <div className={styles.topTenList}>
            {
              !!info?.url && (
              <video controls key={'disssmblevideo'}
                controlsList="nodownload"
                name="media">
                <source src={info?.url} type="video/mp4" />
              </video>
              )
            }
            <div className={styles.topTenListTitle} onClick={toVideo}>
              {
                info?.title
              }
              {/* {topTenList[index]?.title} */}
            </div>
            <div className={styles.videoInfo}>
              <div className={styles.infoItem}>
                <img src={zf} alt="" />&nbsp;
                {info?.forward_num}
              </div>
              <div className={styles.infoItem}>
                <img src={v2} alt="" />&nbsp;
                {info?.like_num}
              </div>
              <div className={styles.infoItem}>
                <img src={v3} alt="" />&nbsp;
                {info?.comment_num}
              </div>
            </div>
            <div className={styles.user_message}>
            <div className={styles.userBox} onClick={toIndex}>
                <div className={styles.user_img}>
                  <img src={info?.head_img} alt="" />
                </div>
                <p>{info?.nickname}</p>
                <Tooltip
                  placement="bottomLeft"
                  getPopupContainer={() => document.getElementById("root")}
                  overlayClassName="codeTooltip"
                  title={
                    <>
                      <img className="dareninfocode" src={info?.qr_code} />
                      <div className="dareninfocodeTitle">
                        抖音扫一扫，查看达人主页
                      </div>
                    </>
                  }
                  color="#ffffff"
                >
                  <div className={styles.code_icon}>
                    <img src={showcode} alt="" />
                  </div>
                </Tooltip>
                </div>
              </div>
               <Button className='toIndex' style={{ fontSize: '16px',marginTop: '14px',height: '40px' }} onClick={toIndex} >前往达人作品主页</Button>
          </div>
        </div>
      </div>
      <div className={styles.woksRight}>
        <div className={styles.peopleNum}>
          已有{
            peopleNum
          }人参与此视频拆解
        </div>
        <Part video_id={props.match.params.video_id} dsvt_id={props.match.params.dsvt_id} />
        {
          !user_info?.is_vip && (
            <ShowVip goVip={goVip}></ShowVip>
          )
        }
        {showAddVip && (
          <Addvip handleCancel={() => setShowAddVip(false)} />
        )
        }
      </div>
    </div>
  </div>
}
export default Works