// import { columns } from '@/src/utils/formdata.js';
import styles from './index.module.scss';
import { Table, notification, Tooltip, Checkbox, Button, message, Popover, Modal, Tag, Tabs } from 'antd'
import { controller_name } from './constant'
import { SettingOutlined, ReloadOutlined, ExclamationCircleOutlined, QuestionCircleOutlined, FileTextOutlined, UploadOutlined } from '@ant-design/icons';
import { GlobalContext } from '@/App'
import Video from '@/components/video'
import { useState, useEffect, Fragment, useContext } from 'react';
import { getDataList } from './service'
import { columnsList, pk_id } from './constant'
import { tagColor } from '@/utils/global'
import { checkRole } from '@/utils/service'
import { routerList } from '@/utils/constant'
import Group from './group'
import UnGroup from './unGroup'
import All from './all'
import moment from 'moment'
const { TabPane } = Tabs;
const List = (props) => {
  const [loading, setLoading] = useState(false)
  const [dTab,setdTab] = useState(sessionStorage.getItem('bTab') || '1')
  const [_columns, setColumns] = useState([]);
  // const []
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showColumnsSelect, setShowColumnsSelect] = useState(false);
  const [isSearch, setIsSearch] = useState(true);
  const [searchParams, setSearchParams] = useState({})
  const { isLogin } = useContext(GlobalContext);
  const [tableFixed, setTableFixed] = useState(false)
  const [baseData, setBaseData] = useState(null)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showTotal: (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` },
    defaultPageSize: 20,
    showSizeChanger: true, // 显示可改变每页数量
    pageSizeOptions: [20, 50, 100, 500], // 每页数量选项
  });
  const root = document.getElementById('root')
  const getheight = (e) => {
    let rootId = document.getElementById('root')?.scrollTop;
    let tableBox = document.getElementById('tableBox')?.offsetTop;
    if (rootId + document.getElementsByTagName('body')[0].clientHeight >= 1510) {
      if (document.getElementsByClassName('ant-pagination')[0]) {
        document.getElementsByClassName('ant-pagination')[0].style.position = 'fixed'
      }
      if (document.getElementById('tableBox')) {
        document.getElementById('tableBox').style.paddingBottom = '64px'
      }
    } else {
      if (document.getElementsByClassName('ant-pagination')[0]) {
        document.getElementsByClassName('ant-pagination')[0].style.position = 'static'
      }
      if (document.getElementById('tableBox')) {
        document.getElementById('tableBox').style.paddingBottom = '0'
      }
    }
    if (rootId >= tableBox - 80) {
      setTableFixed(true)
    } else {
      setTableFixed(false)
    }
  }
  useEffect(() => {
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    // getDataListFun({
    //   pageNo: 1,
    //   pageSize: 20,
    //   sort: '',
    //   order: '',
    //   pagination
    // })
    // root.addEventListener('scroll', getheight)
    // return () => {
    //   root.removeEventListener("scroll", getheight)
    // }
  }, [])
  useEffect(() => {
    console.log('columnsList', columnsList)
    !!baseData && setColumns([...columnsList])
  }, [baseData])

  const dumpDataFun = async () => {
    if (!!selectedRowKeys.length) {
      setLoading(true)
      try {
        await checkRole({
          url: `/jkapi/${controller_name}/dumpData`
        })
        const id_list = selectedRowKeys.toString();
        const field_list = _columns.filter(i => i?.checked).map(i => i?.dataIndex).toString()
        window.open(`http://api.yinliu.club/jkapi/${controller_name}/dumpData?id_list=${id_list}&field_list=${field_list}`)
      } catch (errorInfo) {
        message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
        console.log('Failed:', errorInfo);
      }
      setLoading(false)
    } else {
      message.destroy()
      message.error('请选择导出项')
    }
  };

  const getDataListFun = async (params, isSearch) => {
    setLoading(true)
    notification.destroy()
    message.destroy()
    try {
      const res = await getDataList(params);
      if (res?.code == 1) {

        const list = res?.list.map(item => {
          item.key = item[pk_id]
          return item
        })
        setPagination({ ...pagination,current: params.pageNo, pageSize: params.pageSize, total: res?.count })
        // message.info(`共为您推荐${res?.count || 0}条数据`)
        !!isSearch && notification.open({
          message: '',
          maxCount: 1,
          description:<span>本次搜索为您筛选出{res?.count || 0}条数据!</span>,
        }) 
        setBaseData(list || [])
      } else if (res?.code == 2) {
        Modal.confirm({
          title: '该功能仅限会员使用',
          icon: <ExclamationCircleOutlined />,
          content: '致力于提高十倍的引流获客效率',
          okText: '去购买会员',
          cancelText: '取消',
          onOk() {
            console.log('OK');
          },
          onCancel() {
            console.log('Cancel');
          },
        });
        const list = res?.list.map(item => {
          item.key = item[pk_id]
          return item
        })
        setPagination({ ...pagination,current: params.pageNo, pageSize: params.pageSize, total: res?.count })
        // message.info(`共为您推荐${res?.count || 0}条数据`)
        // !!isSearch && notification.open({
        //   message: '',
        //   maxCount: 1,
        //   closeIcon: null,
        //   description:<span>本次搜索为您筛选出{res?.count || 0}条数据!</span>,
        // }) 
        setBaseData(list || [])
      }
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setLoading(false)
  };
  const onSearch = (params, isSearch) => {
    setSearchParams(params)
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    getDataListFun({
      ...params,
      is_search: true,
      order: "",
      pageNo: 1,
      pageSize: 20,
      sort: "",
      pagination
    },isSearch)
  }
  const refresh = () => {
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    getDataListFun({
      ...searchParams,
      is_search: true,
      order: "",
      pageNo: 1,
      pageSize: 20,
      sort: "",
      pagination
    }, false)
  }

  const handleTableChange = (pagination, filters, sorter) => {
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    // setPagination({ ...pagination });
    getDataListFun({
      ...searchParams,
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
      sort: !!sorter.field ? `${sorter.field}` : '',
      order: !!sorter.order ? sorter.order : '',
      pagination
    });
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // const rowSelection = null
  const changeColumns = (e, index) => {
    if (e.target.checked && _columns?.filter((element) => {
      return element.checked;
    }).length >= 30) {
      message.error('最多选择30列');
      return;
    }
    console.log(_columns, index)
    _columns[index].checked = e.target.checked;
    setColumns([..._columns]);
  };

  const SortableItem = (({ value, sortIndex }) => {
    let { key, name } = value;
    let title = value?.name
    return <div key={`line_${key}`} className={styles.uiContentItem} style={{ zIndex: 1031 }}>
      <Checkbox checked={value.checked} disabled={value.disabled} onChange={(e) => {
        changeColumns(e, sortIndex);
      }} > {title.length > 10 ?
        <Tooltip title={title} color={'black'}>
          <span style={{ cursor: 'pointer' }} >{title.slice(0, 10)}...</span>
        </Tooltip>:
        title
        }</Checkbox>
    </div>;
  });
  const SortableList = (({ items }) => {
    return (
      <div className={styles.uiSelectBox} >
        {
          items?.map((element, index) => {
            return <SortableItem key={`item-${index}`} value={element} sortIndex={index} index={index} />;
          })
        }
      </div>
    );
  });
  const getContent = () => {
    return <SortableList distance={10} items={_columns} lockAxis={'y'} />
  }

  const toTutorials = () => {
    window.open(
      routerList?.filter(i => i?.path === props?.match?.path)[0]?.tutorials_url
    )
  }

  const changeTab = (key) => {
    setdTab(key)
    sessionStorage.setItem("bTab", key);
    sessionStorage.removeItem("bsearchParams");
    sessionStorage.removeItem("bpagination");
  }
  
  return <div className={styles.list}>
    {/* <Video /> */}
    <div className={styles.bread}>
      <div className={styles.breadItem}>
      <Tabs defaultActiveKey="1" activeKey={dTab} onChange={changeTab}>
        <TabPane tab="未分组" key="1">
        </TabPane>
        <TabPane tab="已分组" key="2">
        </TabPane>
        <TabPane tab="全部" key="3">
        </TabPane>
      </Tabs>
      </div>
      {/* <div className={styles.teach} onClick={toTutorials}>
        <FileTextOutlined style={{ color: '#25B864', marginRight: '4px' }} />
        页面使用教程
      </div> */}
    </div>
    {dTab === '1' && <UnGroup />}
    {dTab === '2' && <Group />}
    {dTab === '3' && <All />}
  </div>
}
export default List;

