// import { columns } from '@/src/utils/formdata.js';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { Form, DatePicker, Radio, Checkbox, message, Popover, Modal, Tag, Input } from 'antd'

import { commodityList, calculatePrice, inquireQuestion } from './service';
const AddCount = (props) => {
  const [addForm] = Form.useForm();
  const handleOk = () => {
  };

  const handleCancel = () => {
  };
  const validationPhone = (rule, data) => {
    if (!data) {
      return Promise.reject('手机号不能为空');
    } else {

      // let myreg = /^1|9\d{10}$/
      let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!myreg.test(data)) {
        return Promise.reject('手机号必须为11位有效数字');
      } else {
        return Promise.resolve();
      }
    }
  }
  return <Modal title="添加子账号" visible={true} onOk={handleOk} onCancel={handleCancel} className={styles.addCount}>
    <Form
      {...{
        // labelCol: { span: 10 },
        wrapperCol: { span: 24 },
      }}
      // width={'100%'}
      layout={'Vertical'}
      form={addForm}
      colon={false}
    >
      <Form.Item key='mobile' name='mobile' label='手机号' rules={[{ required: true, validator: validationPhone }]}><Input placeholder='请输入手机号'  type={'mobile'}
                maxLength={11} /></Form.Item>
      <Form.Item key='username' name='username' label='用户名' ><Input placeholder='请输入用户名' /></Form.Item>
      <Form.Item key='role_id' name='role_id' label='权限' >
        <Checkbox.Group options={[
          { label: 'Apple', value: 'Apple' },
          { label: 'Pear', value: 'Pear' },
          { label: 'Orange', value: 'Orange' },
        ]} />

      </Form.Item>
      <Form.Item key='end_time' name='end_time' label='到期时间（只能选择小于自己会员的截止日期)' ><DatePicker /></Form.Item>
      <Form.Item key='status' name='status' label='状态' >  <Radio.Group >
        <Radio value={1}>正常</Radio>
        <Radio value={0}>禁用</Radio>
      </Radio.Group></Form.Item>
    </Form>
  </Modal>
}
export default AddCount;

