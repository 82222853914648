import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  InputNumber,
  Row,
  Typography,
  Select,
  message,
} from "antd";
import {
  DownOutlined,
  UpOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import styles from "./index.module.scss";
import "./searchItem.scss";
import { tagList } from "../../common";

const { Text } = Typography;
const { Option } = Select;

const SearchComponents = (props) => {
  const { setSearchParams, searchForm, setIsSearchBtn, setSearchFlag } = props;
  const [isFold, setIsFold] = useState(true); // 默认为true，折叠
  const selectedTab = sessionStorage.getItem("selectedTab");
  const isLogin = sessionStorage.getItem("isLogin");

  const searchFn = () => {
    if (isLogin === 'false'|| !isLogin) {
      message.error('请先登录');
      return;
    }
    const values = searchForm.getFieldValue();
    setSearchParams(values);
    setIsSearchBtn(true);
    !!setSearchFlag && setSearchFlag(true);
  };

  const resetFn = () => {
    if (isLogin === 'false'|| !isLogin) {
      message.error('请先登录');
      return;
    }
    searchForm.resetFields();
    setSearchParams({});
    setIsSearchBtn(false);
    !!setSearchFlag && setSearchFlag(true);
  };

  return (
    <div className={isFold ? styles.searchFold : styles.searchUnfold}>
      <Form layout={"inline"} form={searchForm}>
        <Row>
          {!["disassemble-case", "my-disassembled", "my-conllection"].includes(
            selectedTab
          ) && (
            <Form.Item label="视频关键词" name="title" className="longInput">
              <Input placeholder="请输入" />
            </Form.Item>
          )}
          {["disassemble-case", "my-disassembled", "my-conllection"].includes(
            selectedTab
          ) && (
            <Form.Item
              label="视频关键词"
              name="video_title"
              className="longInput"
            >
              <Input placeholder="请输入" />
            </Form.Item>
          )}

          {(selectedTab === "hot-video" || !selectedTab) && (
            <Form.Item
              label="拆解次数"
              name="dismantle_num"
              className="shortInput"
            >
              <Input.Group>
                <Form.Item name="dismantle_num_start">
                  <InputNumber placeholder="起" min={0} />
                </Form.Item>
                <Form.Item name="dismantle_num_end">
                  <InputNumber placeholder="止" min={0} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          )}
          <Form.Item label="粉丝数" name="followers_num" className="shortInput">
            <Input.Group>
              <Form.Item name="followers_num_start">
                <InputNumber placeholder="起" min={0} />
              </Form.Item>
              <Form.Item name="followers_num_end">
                <InputNumber placeholder="止" min={0} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item label="点赞数" name="like_num" className="shortInput">
            <Input.Group>
              <Form.Item name="like_num_start">
                <InputNumber placeholder="起" min={0} />
              </Form.Item>
              <Form.Item name="like_num_end">
                <InputNumber placeholder="止" min={0} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          {["disassemble-case", "my-disassembled", "my-conllection"].includes(selectedTab) && (
            <Form.Item label="转发数" name="forward_num" className="shortInput">
              <Input.Group>
                <Form.Item name="forward_num_start">
                  <InputNumber placeholder="起" min={0} />
                </Form.Item>
                <Form.Item name="forward_num_end">
                  <InputNumber placeholder="止" min={0} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          )}
          <div
            className={isFold ? '' : styles.searchBtnUnfold}
          >
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
                onClick={searchFn}
              >
                搜索
              </Button>
              <Button
                htmlType="button"
                icon={<SyncOutlined />}
                onClick={resetFn}
              >
                重置
              </Button>
              <Text
                onClick={() => setIsFold(!isFold)}
                style={{
                  color: "#1AB394",
                  padding: "4px 16px",
                  cursor: "pointer",
                }}
              >
                {isFold ? "展开" : "收起"}
                <span style={{ padding: "0px 2px" }}>
                  {!isFold ? <UpOutlined /> : <DownOutlined />}
                </span>
              </Text>
            </Form.Item>
          </div>
          {!isFold && (
            <>
              { !["disassemble-case", "my-disassembled", "my-conllection"].includes(selectedTab) && (
                <Form.Item
                label="转发数"
                name="forward_num"
                className="shortInput"
              >
                <Input.Group>
                  <Form.Item name="forward_num_start">
                    <InputNumber placeholder="起" min={0} />
                  </Form.Item>
                  <Form.Item name="forward_num_end">
                    <InputNumber placeholder="止" min={0} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              )}
              <Form.Item
                label="评论数"
                name="comment_num"
                className="shortInput"
              >
                <Input.Group>
                  <Form.Item name="comment_num_start">
                    <InputNumber placeholder="起" min={0} />
                  </Form.Item>
                  <Form.Item name="comment_num_end">
                    <InputNumber placeholder="止" min={0} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              {selectedTab === "disassemble-case" && (
                <Form.Item label="标签" name="tag" className="selectInput">
                  <Select allowClear>
                    {tagList.map((i) => (
                      <Option value={i.key}>{i.title}</Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default SearchComponents;
