import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>
      <Form.Item key='4325' name='keyword' label='关键词' className ='searchFormItem'><Input placeholder='关键词' /></Form.Item>


    
      <Form.Item key='4343' label='预计百度搜索流量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4343_start' name='baidu_search_traffic_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4343_end' name='baidu_search_traffic_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4342' name='is_ad' label='是否存在竞价广告' className = 'searchFormItem'>
        <Select
            placeholder='存在竞价广告'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>是</Option>
                <Option key='0' value='0'>否</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='4329' label='百度总搜索人数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4329_start' name='baidu_index_all_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4329_end' name='baidu_index_all_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4332' name='title' label='帖子标题' className ='searchFormItem'><Input placeholder='帖子标题' /></Form.Item>


    
      <Form.Item key='4335' label='回帖数量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4335_start' name='answer_count_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4335_end' name='answer_count_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    

      <Form.Item key='4334' name='write_time' label='帖子发布时间' className = 'searchFormItem'>
        <RangePicker suffixIcon={() => null} format={'YYYY/MM/DD'} />
      </Form.Item>

    
      <Form.Item key='4336' name='tieba_name' label='所属贴吧' className ='searchFormItem'><Input placeholder='所属贴吧' /></Form.Item>


    
      <Form.Item key='4337' name='is_exist_bazhu' label='是否存在吧主' className = 'searchFormItem'>
        <Select
            placeholder='是否存在吧主'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>是</Option>
                <Option key='0' value='0'>否</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='4338' name='is_apply' label='是否可以申请吧主' className = 'searchFormItem'>
        <Select
            placeholder='是否申请吧主'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>是</Option>
                <Option key='0' value='0'>否</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='4327' label='PC排名' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4327_start' name='rank_pc_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4327_end' name='rank_pc_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4330' label='百度PC搜索人数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4330_start' name='bidword_pcpv_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4330_end' name='bidword_pcpv_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4331' label='百度移动搜索人数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4331_start' name='bidword_wisepv_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4331_end' name='bidword_wisepv_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
</Fragment>