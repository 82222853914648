import { FileTextOutlined, CaretRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { message, Spin } from 'antd'
import { useState, useEffect } from 'react';
import Video from '@/components/video';
import { worksList } from '../../constant'
import Infos from '@/components/info'
import PartItem from '@/components/partItem';
import { getInfo, getTalentVideoList, getRecommendList, disassembleInfo } from '../../service'
import styles from './index.module.scss'
const Works = (props) => {
  const history = useHistory();
  const [info, setInfo] = useState(null)
  const [mydisassembleInfo, setMydisassembleInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [url, seturl] = useState(null)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 4,
    total: 0,
    // showTotal: (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` },
    // defaultPageSize: 3,
    // showSizeChanger: true, // 显示可改变每页数量
    // pageSizeOptions: [20, 50, 100, 500], // 每页数量选项
  });
  const [recommendList, setRecommendList] = useState([])
  const [talentVideoList, setTalentVideoList] = useState([])
  // useEffect(() => {
  //   getInfoFun()
  //   getRecommendListFun()
  //   getTalentVideoListFun({
  //     pageNo: 1,
  //     pageSize: 4,
  //     dt_id: props.match.params.dt_id
  //   })
  // }, [props.match.params.dt_id])
  useEffect(() => {
    getInfoFun()
    getDisassembleInfoFun()
  }, [props.match.params.dt_id, props.match.params.dtte_id])
  const getTalentVideoListFun = async (parms) => {
    message.destroy()
    setLoading(true)
    try {
      const res = await getTalentVideoList(parms);
      setTalentVideoList(res?.list || [])
      setPagination({
        current: parms?.pageNo || 1,
        pageSize: 4,
        total: res?.count
      })
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  const getInfoFun = async () => {
    message.destroy()
    setLoading(true)
    try {
      const res = await getInfo(props.match.params.dt_id);
      setInfo(res)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  const getDisassembleInfoFun = async () => {
    message.destroy()
    setLoading(true)
    try {
      const res = await disassembleInfo(props.match.params.dtte_id);
      setMydisassembleInfo(res)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  
  const getRecommendListFun = async () => {
    message.destroy()
    setLoading(true)
    try {

      const last_id = recommendList?.length != 0 ? recommendList[3]?.dt_id : null

      const res = await getRecommendList({
        dt_id: props.match.params.dt_id,
        last_id
      });
      setRecommendList(res?.list || [])
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    // pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    setPagination({ ...pagination });
    getTalentVideoListFun({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
      dt_id: props.match.params.dt_id,
      sort: !!sorter.field ? `${sorter.field}` : '',
      order: !!sorter.order ? sorter.order : '',
    });
  };
  const toTutorials = () => {
    // window.open(
    //   routerList?.filter(i => i?.path === props?.match?.path)[0]?.tutorials_url
    // )
  }
  const tocode = (r) => {
    window.open('https://www.iesdouyin.com/share/video/' + r?.video_id)
  }
  const toClose = () => {
    seturl(null)
  }
  const toPre = () => {
    if (url === 0) {
      message.destroy()
      message.warning('已经是第一个')
    } else {
      seturl(url - 1)
    }
  }
  const toNext = () => {
    if (url === 3) {
      message.destroy()
      message.warning('已经是最后一个')
    } else {
      seturl(url + 1)
    }
  }
  const toPlay = (url, i) => {
    seturl(i)
  }
  const handleClick = (i) => {
    history.push('/works/' + i?.dt_id)
  }

  return <div className={styles.works}>
    <Spin size="large" spinning={loading}>

      {
        !!talentVideoList[url] && (
          <Video close={toClose} pre={toPre} next={toNext} url={talentVideoList[url]?.url} />
        )
      }
      <div className={styles.bread}>
        <div className={styles.breadItem}>
          拆解分析
        </div>
        {/* <div className={styles.teach} onClick={toTutorials}>
          <FileTextOutlined style={{ color: '#25B864', marginRight: '4px' }} />
          页面使用教程
        </div> */}
      </div>
      <div className={styles.worksContent}>
        <div className={styles.woksLeft}>
          {
            !!info && (
              // <InfoItem record={info} />
              <Infos record={info} />
            )
          }
          <div className={styles.infoList}>
            <div className={styles.infoItem}>
              <div className={styles.itemTitle}>作品</div>
              <div className={styles.itemInner}>
                {
                  info?.works_num
                }
              </div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.itemTitle}>粉丝</div>
              <div className={styles.itemInner}>
                {
                  info?.followers_num
                }
              </div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.itemTitle}>获赞</div>
              <div className={styles.itemInner}>
                {
                  info?.like_num
                }
              </div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.itemTitle}>近10天粉丝增量</div>
              <div className={styles.itemInner}>
                {
                  info?.followers_num_add
                }
              </div>
            </div>
          </div>
        </div>
        <div className={styles.woksRight}>
          {
            !!mydisassembleInfo && (
              <PartItem item={mydisassembleInfo} hideCollect={true} />
            )
          }
        </div>
      </div>
    </Spin>
  </div>
}
export default Works