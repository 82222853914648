// import { NewContract } from '@/components/business/new.contract';
import request from '@/utils/request';
/***
 * 搜索接口
 * @param params
 */
 export async function member() {
  return request({
    url: `jkapi/Member/index`,
    method: 'POST',
  });
}

/***
 * 添加子账号
 * @param params
 */
 export async function subAdd(param) {
  return request({
    url: `jkapi/Member/subAdd`,
    method: 'POST',
    data: param
  });
}

/***
 * 子账号列表
 * @param params
 */
 export async function subList(param) {
  return request({
    url: `jkapi/Member/subList`,
    method: 'POST',
    data: param
  });
}
