import { FileTextOutlined, SyncOutlined, CaretRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { message, Table, Spin, Button } from 'antd'
import { useState, useEffect } from 'react';
import Video from '@/components/video';
import { worksList } from './../../constant'
import InfoItem from '@/components/disbleInfo'
import Infos from '@/components/info'
import PartItem from '@/components/partItem';
import { getInfo, getRecommendDisbleList, disassembleInfo } from './../../service'
import styles from './index.module.scss'
const Works = (props) => {
  const history = useHistory();
  const [mydisassembleInfo, setMydisassembleInfo] = useState(null)
  const [info, setInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [url, seturl] = useState(null)
  const [isNext, setIsNext] = useState(true)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 4,
    total: 0,
    // showTotal: (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` },
    // defaultPageSize: 3,
    // showSizeChanger: true, // 显示可改变每页数量
    // pageSizeOptions: [20, 50, 100, 500], // 每页数量选项
  });
  const [recommendList, setRecommendList] = useState([])
  const getDisassembleInfoFun = async () => {
    message.destroy()
    setLoading(true)
    try {
      const res = await disassembleInfo(props.match.params.dtte_id);
      setMydisassembleInfo(res)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  useEffect(() => {
    getInfoFun()
    getRecommendDisbleListFun()
    getDisassembleInfoFun()
    // worksList.push({
    //   "title": "操作", "dataIndex": "cz", "key": "cz", "checked": true, "name": "操作", render: tags => (
    //     // <Fragment>
    //     // <div className="x"></div>
    //     <div className="c"></div>
    //     // </Fragment>
    //   )
    // })
    worksList[0] = {
      "title": "视频信息", "dataIndex": "info", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": false, "note": "", "name": "视频信息", "key": "info", render: (tags, record, i) => (
        <div className='worksInfo'>
          <div className="worksimg">
            <img src={record?.cover_img} alt="" />
            <div className="play" onClick={() => toPlay(record?.url || null, i)}>
              <CaretRightOutlined />
            </div>
          </div>
          <div className="infotitle" onClick={() => tocode(record)}>
            {record.title}
          </div>
        </div>
      )
    }
  }, [props.match.params.dt_id,props.match.params.dtte_id])
  const next = () => {
    isNext && getRecommendDisbleListFun()
  }
  const getInfoFun = async () => {
    message.destroy()
    setLoading(true)
    try {
      const res = await getInfo(props.match.params.dt_id);
      setInfo(res)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  const getRecommendDisbleListFun = async () => {
    message.destroy()
    setLoading(true)
    try {

      const last_id = recommendList?.length != 0 ? recommendList[3]?.dtte_id : null

      const res = await getRecommendDisbleList({
        dt_id: props.match.params.dt_id,
        last_id
      });
      setIsNext(res?.count <= 4 ? false:true)
      setRecommendList(res?.list || [])
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    // pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    setPagination({ ...pagination });
  };
  const toTutorials = () => {
    // window.open(
    //   routerList?.filter(i => i?.path === props?.match?.path)[0]?.tutorials_url
    // )
  }
  const tocode = (r) => {
    window.open('https://www.iesdouyin.com/share/video/' + r?.video_id)
  }
  const toClose = () => {
    seturl(null)
  }
  const toPre = () => {
    if (url === 0) {
      message.destroy()
      message.warning('已经是第一个')
    } else {
      seturl(url - 1)
    }
  }
  const toNext = () => {
    if (url === 3) {
      message.destroy()
      message.warning('已经是最后一个')
    } else {
      seturl(url + 1)
    }
  }
  const toPlay = (url, i) => {
    seturl(i)
  }
  const handleClick = (i) => {
    history.push('/works/' + i?.dt_id + '/1') 
  }

  return <div className={styles.works}>
    <Spin size="large" spinning={loading}>
      <div className={styles.bread}>
        <div className={styles.breadItem}>
          拆解分析
        </div>
        {/* <div className={styles.teach} onClick={toTutorials}>
          <FileTextOutlined style={{ color: '#25B864', marginRight: '4px' }} />
          页面使用教程
        </div> */}
      </div>
      <div className={styles.worksContent}>
        <div className={styles.woksLeft}>
          {
            !!info && (
              // <InfoItem record={info} />
              <Infos record={info} />
            )
          }
          <div className={styles.infoList}>
            <div className={styles.infoItem}>
              <div className={styles.itemTitle}>作品</div>
              <div className={styles.itemInner}>
                {
                  info?.works_num
                }
              </div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.itemTitle}>粉丝</div>
              <div className={styles.itemInner}>
                {
                  info?.followers_num
                }
              </div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.itemTitle}>获赞</div>
              <div className={styles.itemInner}>
                {
                  info?.like_num
                }
              </div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.itemTitle}>近10天粉丝增量</div>
              <div className={styles.itemInner}>
                {
                  info?.followers_num_add
                }
              </div>
            </div>
          </div>
          <div className={styles.recommend}>
            <div className={styles.title}>
            推荐拆解案例
            </div>
            <div className={isNext ? styles.change:styles.unchange}  onClick={next}>
              <SyncOutlined />
              &nbsp;换一批
            </div>
            <div className={styles.recommendList}>
              {
                recommendList?.map(i => !!i && <InfoItem record={i} handleClick={() => handleClick(i)} /> || null)
              }
            </div>
          </div>
        </div>
        <div className={styles.woksRight}>
        {
            !!mydisassembleInfo && (
              <PartItem item={mydisassembleInfo} hideCollect={true} />
            )
          }
        </div>
      </div>
    </Spin>
  </div>
}
export default Works