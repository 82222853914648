import styles from './index.module.scss'
import { tools } from '@/utils';
import { Button, Input, Form, message, Radio } from 'antd';
import { NavLink } from 'react-router-dom';
import { useState, useEffect, useCallback, useContext } from 'react';
import host from '@/config/env'
import { useHistory } from 'react-router-dom';
import { mobileTest } from '@/utils/global'
import { GlobalContext } from '@/App'
import { getVerifyCode, mobileLogin, smsToken, checkLogin } from '../service'
import code from '@/assets/img/code.png'
import mobile from '@/assets/img/mobile.png'
import axios from 'axios';
let signtime
let loginInterval;
const apiHost = host?.apiHost
const Login = (props) => {
  const { changeVisble } = props;
  const [loginForm] = Form.useForm();
  const history = useHistory();
  const { setLogin } = useContext(GlobalContext);
  const [disabl, setDisabl] = useState(false)
  const [ticketUrl, setTicket] = useState(null)
  // const [sms_token, setSms_token] = useState('')
  const [countTime, setCountTime] = useState(0)
  const [loginType, setLoginType] = useState(0)
  const { getFieldValue, getFieldError } = loginForm;
  useEffect(async () => {
    try {
      loginForm.setFieldsValue({
        type: 1
      })
      getTicket();
      // const ticket = await getTicket()
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！', 1);
    }
  }, [loginType])
  
  useEffect(() => {
    signtime = setTimeout(() => {
      if (countTime > 0) {
        setCountTime(c => c - 1); // ✅ 在这不依赖于外部的 `count` 变量
      }
    }, 1000);
    if (countTime === 0) {
      clearTimeout(signtime)
    }
  }, [countTime])

  const getTicket = async () => {
    axios.post('https://yinliu.club/api/Member/getTicket').then(res => {
      const ticket = res.data.data;
      setTicket(ticket?.url)
      if (!!loginType) {
        loginInterval = setInterval(() => {
          getStatus(ticket?.scene_id);
        }, 2500)
      } else {
        clearInterval(loginInterval)
      }
    })
  }

  const getStatus = async (scene_id) => {
    if (!scene_id) return
    axios.post(`${apiHost}jkapi/Member/checkLogin`, {
      scene_id,
      app: 'member'
    })
      .then(res => {
        if (res?.data?.data?.code === 1) {
          message.success('登录成功')
          setLogin(res?.data?.data?.user_info || 'user')
          tools?.setAuthorization(res?.data?.data?.token)
          sessionStorage.setItem('isLogin',true)
          history.push(history.location.pathname)
          changeVisble('')
          clearInterval(loginInterval)
          
          setTimeout(() => {
            history.go(history.location.pathname)
          }, 1000);
        }
        if (res?.data?.data?.code === 2) {
          const scene_id = res?.data?.data?.scene_id
          // history.replace('/wxbind/'+'login/' + scene_id + '/')
          changeVisble('wxbind', 'login', scene_id)
          clearInterval(loginInterval)
        }
      })
      .catch(errorInfo => {
        message.error(errorInfo?.msg || '登录失败，请稍后重试！');
        console.log('loginFailed:', errorInfo);
        clearInterval(loginInterval)
      })
  }
  const validationPhone = (rule, data) => {
    if (!data) {
      return Promise.reject('手机号不能为空');
    } else {

      // let myreg = /^1|9\d{10}$/
      let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!myreg.test(data)) {
        return Promise.reject('手机号必须为11位有效数字');
      } else {
        return Promise.resolve();
      }
    }
  }
  const loginFun = useCallback(async (params) => {
    try {
      const { sms_token } = await smsToken()
      const res = await mobileLogin({ ...params, sms_token})
      tools?.setAuthorization(res?.token)
      // sessionStorage.setItem('s_id', res?.s_id)
      setLogin(res?.user_info || {})
     
      message.success('登录成功')
      changeVisble('')
      setCountTime(0)
      clearTimeout(signtime)
      setTimeout(() => {
        history.go(history.location.pathname)
      }, 1000);
    } catch (errorInfo) {
      console.log('errorInfo', errorInfo)
      message.error(errorInfo?.msg || errorInfo[0]?.errorFields[0] || '系统正在卖力成长中，请稍后重试！', 1);
    }
  // }, [props.match.params.from])  
  }, [props.changeVisble])
  const sendcode = useCallback(async () => {
    if (!!countTime) {
      return
    }
    try {
      const mobile = await loginForm.validateFields(['mobile']);
      const { sms_token } = await smsToken()
      !!mobile && await getVerifyCode({ ...mobile, sms_token })
      setCountTime(60)
      message.success('验证码已发送')
    } catch (errorInfo) {
      console.log('errorInfo验证码发送', errorInfo?.msg)
      if (errorInfo?.errorFields) {
        message.error(errorInfo?.errorFields[0]?.errors[0] || '系统正在卖力成长中，请稍后重试！', 1);
        return
      }
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！', 1);
    }
  }, [countTime, loginForm])
  const toLogin = async () => {
    try {
      const NewFormvalues = await loginForm.validateFields();
      loginFun(NewFormvalues)
    } catch (errorInfo) {
      console.log(errorInfo)
    }
  }
  const goBack = (e) => {
    if (e.target.id === 'login') {
      clearInterval(loginInterval)
      changeVisble('')
      // history.push('/' + props.match.params.from)
    }
  }
  
  return <div className={styles.login} id='login' onClick={goBack}>
    <div className={styles.loginForm}>
      <div className={styles.title}>
        {
          loginType && '扫码登录' || '短信登录'
        }
      </div>
      <div className={styles.loginType} onClick={() => setLoginType(!loginType)}>

        {
          loginType && (
            <img src={mobile} alt="短信登录" />
          ) || (
            <img src={code} alt="扫码登录" />
          )
        }
      </div>
      {
        !loginType && (
          <Form form={loginForm} name="control-hooks"  >
            <Form.Item
              label=''
              name='mobile'
              validateTrigger={'onBlur'}
              rules={[{ required: true, validator: validationPhone }]}
            >
              <Input
                type={'mobile'}
                maxLength={11}
                placeholder="请输入手机号"
                
                // clear
                // moneyKeyboardAlign="left"
              />
            </Form.Item>

            <Form.Item
              name={'code'}
              rules={[{ required: true, message: '短信验证码不能为空' }]}
            >
              <Input
                type={'number'}
                name={'code'}
                placeholder="请输入短信验证码"
                // clear
                suffix={<span style={{ color: '#1890FF' }} onClick={sendcode}>{!!countTime ? countTime + 's' : '发送验证码'}</span>}
                {...getFieldValue('code', {
                  onChange() { }, // have to write original onChange here if you need
                  rules: [{ required: true, message: '短信验证码不能为空' }],
                })}
              />
            </Form.Item>
            <Form.Item
              name={'type'}
            >
              <Radio.Group >
                <Radio value={1}>主账号</Radio>
                <Radio value={2}>子账号</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        ) || (
          <div className={styles.code}>
            <img src={ticketUrl} alt="微信扫描二维码登录" />
          </div>
        )
      }
      {
        !loginType && (
          <div className={styles.btn}>
            <Button type="primary" disabled={disabl} onClick={toLogin}>登录
            </Button>
          </div>
        )
      }
      {
        loginType && (
          <div className={styles.codemark}>
            打开微信扫一扫，关注公众号后即可登录/注册
          </div>
        ) || (
          <div className={styles.mark}>
            没有账号？
            <span className={styles.toRegister} onClick={() => changeVisble('register')}>去注册</span>
            {/* <NavLink to={'/register/'} exact
              activeClassName="active" key={'register'}>去注册</NavLink> */}
            {/* <link rel="stylesheet" href="" /> */}
          </div>
        )
      }
    </div>
  </div>
}
export default Login;