import { FileTextOutlined, SyncOutlined, CaretRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { message, Table, Spin, Button, ConfigProvider, Tooltip } from 'antd'
import { useState, useEffect, useContext } from 'react';
import Video from '@/components/video';
import { worksList } from './../constant'
import InfoItem from '@/components/infoItem'
import Infos from '@/components/info'
import PartList from './partList';
import workemp from '@/assets/img/workemp.png'
import { getInfo, getTalentVideoList, getRecommendList } from './../service'
import styles from './index.module.scss'
import ShowVip from '@/components/showVip';
import Addvip from '@/components/addVip'
import { GlobalContext } from '@/App'
const Works = (props) => {
  const history = useHistory();
  const { user_info, setloginVis } = useContext(GlobalContext);
  const [showAddVip, setShowAddVip] = useState(false)
  const [info, setInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [url, seturl] = useState(null)
  const [worksTab, setWorksTab] = useState(0)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 4,
    total: 0,
    // showTotal: (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` },
    // defaultPageSize: 3,
    // showSizeChanger: true, // 显示可改变每页数量
    // pageSizeOptions: [20, 50, 100, 500], // 每页数量选项
  });
  const [recommendList, setRecommendList] = useState([])
  const [talentVideoList, setTalentVideoList] = useState([])
  useEffect(() => {
    setWorksTab(props.match.params.tab)
    getInfoFun()
    getRecommendListFun()
    getTalentVideoListFun({
      pageNo: 1,
      pageSize: 4,
      dt_id: props.match.params.dt_id
    })
    worksList[4] = {
      "title": "操作", "dataIndex": "cz", "key": "cz", "checked": true, "name": "操作", render: (tags,record) => (
        <Tooltip title={'视频拆解'} color={'black'}>
        <div className="c" onClick={() => toDisble(record)}></div>
      </Tooltip>
      )
    }
    worksList[0] = {
      "title": "视频信息", "dataIndex": "info", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": false, "note": "", "name": "视频信息", "key": "info", render: (tags, record, i) => (
        <div className='worksInfo'>
          <div className="worksimg">
            <img src={record?.cover_img} alt="" />
            <div className="play" onClick={() => toPlay(record?.url || null, i)}>
              <CaretRightOutlined />
            </div>
          </div>
          <div className="infotitle" onClick={() => tocode(record)}>
            {record.title}
          </div>
        </div>
      )
    }
    if (!user_info?.is_vip) {
      document.getElementById('root').scrollTop = 0
      document.getElementById('root').style.overflow = 'hidden'
      document.getElementById('footerBox').style.display = 'none'
      
    } else {
      document.getElementById('root').style.overflow = 'auto'
      document.getElementById('footerBox').style.display = 'block'
    }
    return resetscroll
  }, [props.match.params.dt_id, props.match.params.tab, user_info?.is_vip])
  const resetscroll = () => {
    document.getElementById('footerBox').style.display = 'block'
    document.getElementById('root').style.overflow = 'auto'
  }
  const next = () => {
    getRecommendListFun()
  }
  const getTalentVideoListFun = async (parms) => {
    message.destroy()
    setLoading(true)
    try {
      const res = await getTalentVideoList(parms);
      setTalentVideoList(res?.list || [])
      setPagination({
        current: parms?.pageNo || 1,
        pageSize: 4,
        total: res?.count
      })
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  const toDisble = (r) => {
    history.push(`/videoDisassemble/${r.video_id}/0`)
  }
  const getInfoFun = async () => {
    message.destroy()
    setLoading(true)
    try {
      const res = await getInfo(props.match.params.dt_id);
      setInfo(res)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  const getRecommendListFun = async () => {
    message.destroy()
    setLoading(true)
    try {

      const last_id = recommendList?.length != 0 ? recommendList[3]?.dt_id : null

      const res = await getRecommendList({
        dt_id: props.match.params.dt_id,
        last_id
      });
      setRecommendList(res?.list || [])
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    // pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    setPagination({ ...pagination });
    getTalentVideoListFun({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
      dt_id: props.match.params.dt_id,
      sort: !!sorter.field ? `${sorter.field}` : '',
      order: !!sorter.order ? sorter.order : '',
    });
  };
  const toTutorials = () => {
    // window.open(
    //   routerList?.filter(i => i?.path === props?.match?.path)[0]?.tutorials_url
    // )
  }
  const tocode = (r) => {
    window.open('https://www.iesdouyin.com/share/video/' + r?.video_id)
  }
  const toClose = () => {
    seturl(null)
  }
  const toPre = () => {
    if (url === 0) {
      message.destroy()
      message.warning('已经是第一个')
    } else {
      seturl(url - 1)
    }
  }
  const toNext = () => {
    if (url === 3) {
      message.destroy()
      message.warning('已经是最后一个')
    } else {
      seturl(url + 1)
    }
  }
  const toPlay = (url, i) => {
    seturl(i)
  }
  const handleClick = (i) => {
    history.push(`/works/${i?.dt_id}/0`)
  }

  const renderEmpty = () => {
    return <div className={styles.empty}>
      {!!talentVideoList && (
        <img src={workemp} alt="" />) || null}
    </div>
  }
  const goVip = () => {
    if (!!user_info) {
      setShowAddVip(true)
    } else {
      setloginVis(true)
      // history.push('/login')
    }
  }
  return <div className={styles.works}>
    <Spin size="large" spinning={loading}>

      {
        !!talentVideoList[url] && (
          <Video close={toClose} pre={toPre} next={toNext} url={talentVideoList[url]?.url} />
        )
      }
      <div className={styles.bread}>
        <div className={styles.breadItem}>
          账号详情
        </div>
        {/* <div className={styles.teach} onClick={toTutorials}>
          <FileTextOutlined style={{ color: '#25B864', marginRight: '4px' }} />
          页面使用教程
        </div> */}
      </div>
      <div className={styles.worksContent}>
        <div className={styles.woksLeft}>
          {
            !!info && (
              // <InfoItem record={info} />
              <Infos record={info} />
            )
          }
          <div className={styles.infoList}>
            <div className={styles.infoItem}>
              <div className={styles.itemTitle}>作品</div>
              <div className={styles.itemInner}>
                {
                  info?.works_num
                }
              </div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.itemTitle}>粉丝</div>
              <div className={styles.itemInner}>
                {
                  info?.followers_num
                }
              </div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.itemTitle}>获赞</div>
              <div className={styles.itemInner}>
                {
                  info?.like_num
                }
              </div>
            </div>
            <div className={styles.infoItem}>
              <div className={styles.itemTitle}>近10天粉丝增量</div>
              <div className={styles.itemInner}>
                {
                  info?.followers_num_add
                }
              </div>
            </div>
          </div>
          <div className={styles.recommend}>
            <div className={styles.title}>
              推荐达人
            </div>
            <div className={styles.change} onClick={next}>
              <SyncOutlined />
              &nbsp;换一批
            </div>
            <div className={styles.recommendList}>
              {
                recommendList?.map(i => !!i && <InfoItem showcode={false} record={i} handleClick={() => handleClick(i)} /> || null)
              }
            </div>
          </div>
        </div>
        <div className={styles.woksRight}>
          <div className={styles.btnRight}>
            <Button type={worksTab === '0' ? "primary" : ''} onClick={() => setWorksTab('0')}>作品</Button>
            <Button type={worksTab === '1' ? "primary" : ''} onClick={() => setWorksTab('1')}>拆解案例</Button>
          </div>
          {
            worksTab === '1' && (
              <PartList dt_id={props.match.params.dt_id} />
            ) || (
              <ConfigProvider renderEmpty={() => renderEmpty()}>
                <Table className={'worksTable'} columns={worksList} dataSource={talentVideoList}
                  scroll={{ x: 'max-content' }}
                  onChange={handleTableChange} pagination={pagination} />
              </ConfigProvider>
            )
          }
          {
          !user_info?.is_vip && (
            <ShowVip goVip={goVip}></ShowVip>
          )
        }
          {showAddVip && (
            <Addvip handleCancel={() => setShowAddVip(false)} />
          )
          }
        </div>
      </div>
    </Spin>
  </div>
}
export default Works