import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>

      <Form.Item key='3563' name='platfrom_id' label='平台' className = 'searchFormItem'>
        <Select
            placeholder='平台'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>微信</Option>
                <Option key='2' value='2'>抖音</Option>
                <Option key='3' value='3'>知乎</Option>
                <Option key='4' value='4'>今日头条</Option>
                <Option key='5' value='5'>实体商家</Option>
                <Option key='6' value='6'>小红书</Option>
                <Option key='7' value='7'>百度</Option>
                <Option key='8' value='8'>豆瓣</Option>
                <Option key='9' value='9'>多平台</Option>
                <Option key='10' value='10'>微博</Option>
                <Option key='11' value='11'>其他平台</Option>
        </Select>
      </Form.Item>
    
      <Form.Item key='3559' name='author' label='作者' className ='searchFormItem'><Input placeholder='作者' /></Form.Item>

    
      <Form.Item key='3558' name='title' label='标题' className ='searchFormItem'><Input placeholder='标题' /></Form.Item>

    
      <Form.Item key='3566' name='origin' label='文章来源' className ='searchFormItem'><Input placeholder='文章来源' /></Form.Item>

    
      <Form.Item key='3561' name='score' label='推荐指数' className = 'searchFormItem'>
        <Select
            placeholder='推荐指数'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='5' value='5'>☆☆☆☆☆</Option>
                <Option key='4' value='4'>☆☆☆☆</Option>
                <Option key='3' value='3'>☆☆☆</Option>
                <Option key='2' value='2'>☆☆</Option>
                <Option key='1' value='1'>☆</Option>
        </Select>
      </Form.Item>

    

      <Form.Item key='3570' name='reelease_time' label='发布时间' className = 'searchFormItem'>
        <RangePicker suffixIcon={() => null} format={'YYYY/MM/DD'} />
      </Form.Item>
    
</Fragment>