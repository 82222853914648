// import { columns } from '@/src/utils/formdata.js';
import styles from './index.module.scss';
import { Table, Tooltip, Checkbox, Button, message, Popover, Modal, Tag, Input } from 'antd'
import { SettingOutlined, ReloadOutlined, ExclamationCircleOutlined, QuestionCircleOutlined, FileTextOutlined, UploadOutlined } from '@ant-design/icons';
import { priceList } from '@/components/goods/constant'
import { useEffect, useState, Fragment } from 'react';
import goods from '@/assets/img/goods.png'
import vip from '@/assets/img/vip.png'
import wx_add from '@/assets/img/wx_add.png'
import { commodityList, calculatePrice, inquireQuestion } from './service';
const List = (props) => {
  const [pList, setPlist] = useState([])
  const [idlist, setIdlist] = useState([])
  const [price, setPrice] = useState(null)
  const [showPopup, setShowPopup] = useState(false)
  useEffect(() => {
    getDataListFun()
  }, [])
  useEffect(() => {
    !!idlist.length && getPriceFun(idlist)
  }, [idlist])
  const getPriceFun = async (idlist) => {
    message.destroy()
    try {
      const res = await calculatePrice({
        choice_product_list: idlist
      });
      setPrice(res)
      // const list = res?.list.map(item => {
      //   item.key = item[pk_id]
      //   return item
      // })
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
  };
  const buy = async () => {
    message.destroy()
    try {
      const res = await inquireQuestion({
        choice_product_list: idlist,
        final_amount: price.current_price
      });
      window.open(res?.url || '')
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
  };

  const getDataListFun = async () => {
    message.destroy()
    try {
      const res = await commodityList();
      setPlist(res)
      setIdlist(res.filter(i => !i.checked)?.map(i => i.c_id))
      // const list = res?.list.map(item => {
      //   item.key = item[pk_id]
      //   return item
      // })
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
  };
  const selectPrice = (item, index) => {
    let newitem = {
      ...item,
      checked: !item.checked
    }
    let list = [...pList]
    list.splice(index, 1, newitem)
    setPlist(list)
    setIdlist(list.filter(i => !i.checked)?.map(i => i.c_id))
  }
  const renderItem = (item, index) => {
    const { title, current_price, description, checked, external_link, introduction } = item;
    return (
      <div className={styles.priceItem} onClick={() => selectPrice(item, index)}>
        <div class={styles.icon_actived} style={{ display: !checked ? 'block' : 'none' }}></div>
        <h3>{title}<span> ￥{current_price}<b>&nbsp;&nbsp;元/年</b></span></h3>
        <p>{introduction}</p>
        <ul>
          {
            description?.map(i => <li>{i}</li>)
          }
        </ul>
        <div class={styles.bottom_info}><a href={external_link}>查看使用教程 &gt;</a></div>
      </div>
    )
  }
  return <div className={styles.goods}>
    <div className={styles.banner}>
      <div className={styles.bannerContent}>
        <div className={styles.title}>
          极客增长
        </div>
        <div className={styles.inner}>
          引流获客的必备工具，助力你挖掘
        </div>
        <div className={styles.bannerBtn} onClick={() => setShowPopup(true)}>
          立即咨询
        </div>
        {
          showPopup && (
            <div className={styles.popup} onClick={() => setShowPopup(false)}>
              <div className={styles.popup_conter}>
                <div className={styles.popup_img}>
                  <img src={wx_add} alt="" />
                </div>
                <p>扫描以上二维码，添加佩奇微信</p>
              </div>
            </div>
          )

        }
      </div>
      <img src={goods} alt="" />
    </div>
    <div className={styles.goodsPrice}>
      <div className={styles.title}>
        产品售价
      </div>
      <div className={styles.priceBox}>
        <div className={styles.priceItemBox}>
          {
            pList.map((item, i) => renderItem(item, i)
            )
          }
          <div className={styles.pay_product_price_content_paybtn}>
            <div className={styles.payTitle}>
              总售价：
            </div>
            <div className={styles.price}>
              <span>
                {price?.pay_money}&nbsp;&nbsp;
              </span>
              <span>
                元&nbsp;&nbsp;
              </span>
              <span>
                原价{price?.total_price}元
              </span>
            </div>
            {
              price?.pay_money && (
                <Fragment>
              <div className={styles.payTitle}>
                续购价格：
              </div>
              <div className={styles.price}>
                <span>
                  {price?.pay_money}&nbsp;&nbsp;
                </span>
                <span>
                  元&nbsp;&nbsp;
                </span>
              </div>
             </Fragment>
              )
            }
            <div className={styles.btn} onClick={buy}>
              立即购买
            </div>
          </div>
        </div>
      </div>

    </div>
    <div className={styles.vipBox}>
      <div className={styles.title}>
        会员方案对比
      </div>
      <div className={styles.vipTable}>
        <img src={vip} alt="" />
      </div>
    </div>
  </div>
}
export default List;

