import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>
      <Form.Item key='3540' name='keyword' label='关键词' className ='searchFormItem'><Input placeholder='关键词' /></Form.Item>


    
      <Form.Item key='3541' name='type' label='词属性' className = 'searchFormItem'><Input placeholder='词属性' /></Form.Item>


    
      <Form.Item key='3542' label='总搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3542_start' name='baidu_index_all_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3542_end' name='baidu_index_all_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3543' label='移动搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3543_start' name='bidword_wisepv_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3543_end' name='bidword_wisepv_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3544' label='PC搜索指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3544_start' name='bidword_pcpv_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3544_end' name='bidword_pcpv_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3545' label='长尾词数量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3545_start' name='long_keyword_count_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3545_end' name='long_keyword_count_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3546' label='竞价公司数量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3546_start' name='bidword_companycount_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3546_end' name='bidword_companycount_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4320' label='搜索画像-年龄分布(岁)	' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4320_start' name='age_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4320_end' name='age_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    


    


    
</Fragment>