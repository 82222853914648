// import { NewContract } from '@/components/business/new.contract';
import request from '@/utils/request';
import { controller_name } from './constant'
/***
 * banner数据
 * @param params
 */
export async function getDataList(params) {
  return request({
    url: `jkapi/${controller_name}/index`,
    method: 'POST',
    data: params,
  });
}

/***
 * 搜索接口
 * @param params
 */
 export async function searchPre(url) {
  return request({
    url: `jkapi/${controller_name}/inquireQuestion`,
    method: 'POST',
    data: {
      url
    },
  });
}