import AccountsRank from '@/pages/AccountsRank'
import AccountsSeach from '@/pages/AccountsSeach'
import { tools } from '@/utils';
import BaijiaHao from '@/pages/BaijiaHao'
import Bddouban from '@/pages/Bddouban'
import GrowthContent from '@/pages/GrowthContent'
import JdGoods from '@/pages/JdGoods'
import KeywordBusiness from '@/pages/KeywordBusiness'
import KeywordHot from '@/pages/KeywordHot'
import KeywordLong from '@/pages/KeywordLong'
import Tieba from '@/pages/Tieba'
import TiebaBbs from '@/pages/TiebaBbs'
import Zhihu from '@/pages/Zhihu'
import ZhihuHot from '@/pages/ZhihuHot'
import ZhihuKeywordQuestion from '@/pages/ZhihuKeywordQuestion'
import ZhihuQuestionDetail from '@/pages/ZhihuQuestionDetail'
import Daren from "@/pages/Daren";
import Benchmarking from "@/pages/Benchmarking"
import Works from '@/pages/Daren/works';
import BenchmarkDetails from '@/pages/Benchmarking/details'
import Disassemble from '@/pages/Daren/disassemble'
import VideoDisassemble from '@/pages/Video/disassemble'
import DisassemblyCase from '@/pages/Video/disassemblyCase/CaseInfo'
import MyDisassembly from '@/pages/Video/myDisassembly'
import PopVideoInfo from '@/pages/Video/PopVideo/PopVideoInfo'

import MyDisassemble from '@/pages/Daren/myDisassemble/myDisassembleInfo'


import MyCollectInfo from '@/pages/Daren/collect/myCollectInfo'
import ShipinPage from '@/pages/ShipinPage';
import LearnToDisassemble from './pages/Video/learnToDisassemble';
import {ConfigProvider} from 'antd';
// import styles from './App.module.scss';
import React, {Suspense} from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { isPc } from "@/utils/global";
import zhCN from 'antd/lib/locale/zh_CN';
import { Layout } from 'antd';
import Header from '@/components/header'
import Footer from '@/components/footer'
import Login from '@/components/auth/login'
import Register from '@/components/auth/register';
import Wxbind from '@/components/auth/wxbind'
import UserInfo from '@/components/user/userInfo'
import Goods from '@/components/goods'
import AddToGroup from '@/pages/Benchmarking/group/addToGroup'
import TiktokHot from '@/pages/TiktokHot'
import GroupList from '@/pages/Benchmarking/group/groupList'

import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zhCN');
export const GlobalContext = React.createContext({});
const { Content } = Layout;
var sensors = window['sensorsDataAnalytic201505'];
@withRouter
class App extends React.Component {
constructor(props) {
super(props);
this.state = {
user_info: JSON.parse(sessionStorage.getItem('user_info')) || null,
loginVis: false
};
}
setloginVis = (vis) => {
  this.setState({
    loginVis: vis
  })
}
setLogin = (user_info) => {
  if (!!user_info) {
    sessionStorage.setItem('isLogin', true)
    sessionStorage.setItem('user_info', JSON.stringify(user_info))
  } else {
    tools?.setAuthorization('')
    sessionStorage.setItem('isLogin', false)
    sessionStorage.setItem('user_info', null)
  }
  this.setState({
    user_info: user_info ? user_info: null,
  })
}

render() {
return (
<ConfigProvider locale={zhCN}>
  <GlobalContext.Provider value={{setLogin: this.setLogin,sensors: sensors, user_info: this.state.user_info,setloginVis: this.setloginVis, loginVis: this.state.loginVis}}>
    <Header key='Header' />
    <Layout className="rootLayout">
      <Content>
        <Switch>
          <Suspense fallback={()=> { }}>
            <Route path='/AccountsRank' component={AccountsRank} key='/AccountsRank' exact />
            <Route path='/AccountsSeach' component={AccountsSeach} key='/AccountsSeach' exact />
            <Route path='/BaijiaHao' component={BaijiaHao} key='/BaijiaHao' exact />
            <Route path='/Bddouban' component={Bddouban} key='/Bddouban' exact />
            <Route path='/GrowthContent' component={GrowthContent} key='/GrowthContent' exact />
            <Route path='/JdGoods' component={JdGoods} key='/JdGoods' exact />
            <Route path='/KeywordBusiness' component={KeywordBusiness} key='/KeywordBusiness' exact />
            <Route path='/KeywordHot' component={KeywordHot} key='/KeywordHot' exact />
            <Route path='/KeywordLong' component={KeywordLong} key='/KeywordLong' exact />
            <Route path='/Tieba' component={Tieba} key='/Tieba' exact />
            <Route path='/TiebaBbs' component={TiebaBbs} key='/TiebaBbs' exact />
            <Route path='/Zhihu' component={Zhihu} key='/Zhihu' exact />
            <Route path='/ZhihuHot' component={ZhihuHot} key='/ZhihuHot' exact />
            <Route path='/ZhihuKeywordQuestion' component={ZhihuKeywordQuestion} key='/ZhihuKeywordQuestion' exact />
            <Route path='/ZhihuQuestionDetail' component={ZhihuQuestionDetail} key='/ZhihuQuestionDetail' exact />

            <Route path='/daren' component={Daren} key='/darenchaijie' exact />
            <Route path='/benchmarking' component={Benchmarking} key='/benchmarking' exact />
            <Route path='/works/:dt_id/:tab' component={Works} key='/Works' exact />
            <Route path='/benchmarkDetails/:dt_id/:sec_uid' component={BenchmarkDetails} key='/BenchmarkDetails' exact />
            
            <Route path='/disassemble/:dt_id/:dtte_id' component={Disassemble} key='/disassemble' exact />

            <Route path='/videoDisassemble/:video_id/:dsvt_id' component={VideoDisassemble} key='/videoDisassemble' exact />
            {/* 学拆解页面 firstdsvt_id是被学的案例id seconddsvt_id是本身案例id */}
            <Route path='/learnToDisassemble/:video_id/:firstdsvt_id/:seconddsvt_id' component={LearnToDisassemble} key='/learnToDisassemble' exact />
            
            <Route path='/myCollectInfo/:dt_id/:dtte_id' component={MyCollectInfo} key='/myCollectInfo' exact />

            <Route path='/myDisassemble/:dt_id/:dtte_id' component={MyDisassemble} key='/MyDisassemble' exact />
            <Route path='/popVideoInfo/:video_id' component={PopVideoInfo} key='/PopVideoInfo' exact />
            <Route path='/disassemblyCase/:dsvt_id/:video_id' component={DisassemblyCase} key='/DisassemblyCase' exact />
            <Route path='/myDisassembly/:dsvt_id/:video_id' component={MyDisassembly} key='/MyDisassembly' exact />
            <Route path='/video' component={ShipinPage} key='/video' exact />
            <Route path='/addToGroup/:dbtg_id/:name' component={AddToGroup} key='/AddToGroup' exact />
            <Route path='/groupList/:dbtg_id' component={GroupList} key='/GroupList' exact />
            <Route path='/register' component={Register} key='/Register' exact />
            <Route path='/userInfo' component={UserInfo} key='/UserInfo' exact />
            <Route path='/goods' component={Goods} key='/Goods' exact />
            <Route path='/wxbind/:type/:scene_id' component={Wxbind} key='/Wxbind' exact />
            <Route path='/TiktokHot' component={TiktokHot} key='/TiktokHot' exact />
            
          </Suspense>
        </Switch>
      </Content>
    </Layout>
    <Footer key='Footer' />
  </GlobalContext.Provider>
</ConfigProvider>
);
}
}

export default App;