import styles from './index.module.scss'
import SearchForm from './searchForm.jsx'
import { Form, Button, message } from 'antd';
import { DownOutlined, UpOutlined, SearchOutlined, SyncOutlined} from '@ant-design/icons';
import moment from 'moment';
import { useState, useEffect } from 'react';
const Search = (props) => {
 
  
  const [isShow, setIsShow] = useState(false);
  const [searchForm] = Form.useForm();
  
  useEffect(() => {
    searchForm.setFieldsValue({
      ...JSON.parse(sessionStorage.getItem('TiktsearchParams')) || {},
      time: [moment(JSON.parse(sessionStorage.getItem('TiktsearchParams'))?.time[0]),moment(JSON.parse(sessionStorage.getItem('TiktsearchParams'))?.time[1])]
    })
  }, [searchForm])
  
  const onReset = async() => {
    await searchForm.resetFields();
    props.onSearch(searchForm.getFieldsValue())
  };
  const onSearch = () => {
    message.destroy()
    props.onSearch(searchForm.getFieldsValue(), true)
    // if (isLogin) {
    //   props.onSearch(searchForm.getFieldsValue())
    // } else {
    //   // history.push('login')
    //   message.info('请先登录')
    // }
  };
  
  return <div className={styles.searchForm}>
     <Form
        layout={'inline'}
        form={searchForm}
      >
        <div className={ !isShow ? 'searchForm': 'searchFormShow'}>
          <SearchForm />
        </div>
        <div className={ !isShow ? 'searchBtn': 'searchBtnShow'}>
          <Form.Item  className={styles.btnBox}>
            <Button type="primary" onClick={onSearch} icon={<SearchOutlined />}>
              搜索
            </Button>
            <Button htmlType="button" onClick={onReset} icon={<SyncOutlined />}>
              重置
            </Button>
            <Button type="text" onClick={() => setIsShow(!isShow)} icon={isShow ? < UpOutlined />:<DownOutlined />} style={{color: '#1AB394',padding: '4px 5px'}}>
              { 
                isShow ? '收起':'展开'
              } 
            </Button>
          </Form.Item>
        </div>
      </Form>
  </div>
}
export default Search;