import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>
      <Form.Item key='3167' name='tieba_name' label='贴吧名称' className ='searchFormItem'><Input placeholder='贴吧名称' /></Form.Item>


    
      <Form.Item key='3178' label='百度PC排名' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3178_start' name='rank_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3178_end' name='rank_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3169' label='关注人数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3169_start' name='follower_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3169_end' name='follower_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3171' label='签到人数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3171_start' name='sign_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3171_end' name='sign_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3173' name='status' label='吧主情况' className = 'searchFormItem'>
        <Select
            placeholder='吧主情况'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>有吧主</Option>
                <Option key='0' value='0'>无吧主</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='3304' name='is_apply' label='是否可申请吧主' className = 'searchFormItem'>
        <Select
            placeholder='是否可申请吧主'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>允许申请</Option>
                <Option key='0' value='0'>不可申请</Option>
                <Option key='10' value='10'>贴吧被封</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='3511' name='cate_one' label='一级分类' className ='searchFormItem'><Input placeholder='一级分类' /></Form.Item>


    
      <Form.Item key='3512' name='cate_two' label='二级分类' className ='searchFormItem'><Input placeholder='二级分类' /></Form.Item>


    
</Fragment>