import React from "react";
import { Modal } from "antd";
import ShowContent from "../ShowContent";
import styles from "./index.module.scss";

const ReadAllModal = (props) => {
  const { visible, setVisible, title, date, dsvtresInfo } = props;
  return (
    <Modal visible={visible} width={860} footer={null} onCancel={() => setVisible(false)}>
      <div className={styles.modalContent}>
        <div className={styles.main}>
          <div className={styles.title}>{title}</div>
          <div className={styles.date}>{date}</div>
          <div className={styles.content}>
            {!!dsvtresInfo && <ShowContent dsvtresInfo={dsvtresInfo} />}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReadAllModal;
