import React, { useRef } from "react";
import { message, Modal, Button } from "antd";
import { CloseOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import v1 from "@/assets/img/zf.png";
import v2 from "@/assets/img/v2.png";
import v3 from "@/assets/img/v3.png";
import styles from "./index.module.scss";
import { useHistory } from "react-router-dom";

const ToPlayModal = (props) => {
  const {
    modalShow,
    setModalShow,
    currentRecord,
    setCurrentRecord,
    changeVideoUrl,
    currentIdx,
    setCurrentIdx,
    pageTotal,
  } = props;
  const videoRef = useRef(null);
  const history = useHistory();
  const selectedTab = sessionStorage.getItem("selectedTab");

  const cancelFn = () => {
    setModalShow(false);
    setCurrentRecord({});
    videoRef.current.pause();
  };

  const showPre = () => {
    if (currentIdx === 0) {
      message.error("当前已经是第一个视频了！");
      return;
    }
    const idx = currentIdx - 1;
    changeVideoUrl(idx);
    setCurrentIdx(idx);
  };

  const showNext = () => {
    if (currentIdx === pageTotal - 1) {
      message.error("当前已经是最后一个视频了！");
      return;
    }
    const idx = currentIdx + 1;
    changeVideoUrl(idx);
    setCurrentIdx(idx);
  };

  const toDismantling = () => {
    history.push(`/videoDisassemble/${currentRecord.video_id}/0`);
  };

  return (
    <Modal
      visible={modalShow}
      onCancel={cancelFn}
      width={840}
      className={styles.videoPlayerModal}
      closable={false}
      footer={null}
    >
      <div className={styles.videoMask}>
        <video
          autoPlay
          controls
          poster={currentRecord.cover_img}
          ref={videoRef}
          src={currentRecord.url}
        ></video>
        <div className={styles.closeIcon} onClick={cancelFn}>
          <CloseOutlined />
        </div>
        <div className={styles.preIcon} onClick={showPre}>
          <UpOutlined />
        </div>
        <div className={styles.nextIcon} onClick={showNext}>
          <DownOutlined />
        </div>
      </div>
      <div className={styles.videoContent}>
        {!["disassemble-case", "my-disassembled", "my-conllection"].includes(
          selectedTab
        ) && (
          <div className={styles.videoTitle} title={currentRecord.title}>
            {currentRecord.title}
          </div>
        )}
        {["disassemble-case", "my-disassembled", "my-conllection"].includes(
          selectedTab
        ) && (
          <div className={styles.videoTitle} title={currentRecord.video_title}>
            {currentRecord.video_title}
          </div>
        )}
        <div className={styles.iconGroup}>
          <ul>
            <li>
              <img src={v1} alt="" />
              &nbsp;
              {currentRecord.forward_num}
            </li>
            <li>
              <img src={v2} alt="" />
              &nbsp;
              {currentRecord.like_num}
            </li>
            <li>
              <img src={v3} alt="" />
              &nbsp;
              {currentRecord.comment_num}
            </li>
          </ul>
        </div>
        <div className={styles.modalBottom}>
          <div className={styles.userInfo}>
            <div className={styles.imgContainer}>
              <img src={currentRecord.head_img} alt="" />
            </div>
            <span>{currentRecord.nickname}</span>
          </div>
          {(selectedTab === "hot-video" || !selectedTab) && (
            <Button type="primary" onClick={toDismantling}>
              视频拆解
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ToPlayModal;
