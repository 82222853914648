import { getAppid } from '@/utils/global'
import request from '@/utils/request';

/***
 * 头部导航栏
 * @param params
 */
 export async function createMenu(params) {
  return request({
    url: 'api/CreateCode/createMenu',
    method: 'get',
    data: params,
  });
}





