import styles from './index.module.scss';
import { CloseOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import {useEffect, useState} from 'react'
const Video = (props) => {
  const [source, setSource] = useState(null)
  useEffect(() => {
    setSource(props.url)
  }, [props.url])
  const next = () => {
    props.next()
  }
  const pre = () => {
    props.pre()
  }
  const close = () => {
    props?.close()
  }
  return <div className={styles.mask}>
    <div className={styles.videoBox}>
      <video controls autoplay="autoplay"
      controlsList="nodownload"
       name="media" key={source}>
        <source src={source} type="video/mp4" />
      </video>
      <div className={styles.btnBox}>
        <div className={styles.close} onClick={close}>
          <CloseOutlined />
        </div>
        <div className={styles.pre} onClick={pre}>
        <UpOutlined />
        </div>
        <div className={styles.next} onClick={next}>
        <DownOutlined />
        </div>
      </div>
    </div> 
  </div>
}

export default Video;