import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>
      <Form.Item key='4173' name='title' label='作者昵称' className ='searchFormItem'><Input placeholder='作者昵称' /></Form.Item>


    
      <Form.Item key='4183' name='is_verify' label='作者认证' className = 'searchFormItem'>
        <Select
            placeholder='作者认证'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>是</Option>
                <Option key='2' value='2'>否</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='4182' name='author_desciption' label='作者描述' className ='searchFormItem'><Input placeholder='作者描述' /></Form.Item>


    
      <Form.Item key='4177' label='回答排名' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4177_start' name='rank_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4177_end' name='rank_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4175' label='回答赞同数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4175_start' name='zan_count_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4175_end' name='zan_count_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4176' label='回答评论数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4176_start' name='comment_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4176_end' name='comment_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4184' label='回答字数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4184_start' name='answer_content_count_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4184_end' name='answer_content_count_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='4185' name='answer_bar' label='回答收录' className ='searchFormItem'><Input placeholder='回答收录' /></Form.Item>


    
      <Form.Item key='4179' name='is_contain_goods' label='好物卡片' className = 'searchFormItem'>
        <Select
            placeholder='好物卡片'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>是</Option>
                <Option key='0' value='0'>否</Option>
        </Select>
      </Form.Item>


    
      <Form.Item key='4186' label='卡片数量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='4186_start' name='goods_count_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='4186_end' name='goods_count_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
</Fragment>