import { Form, Input, DatePicker, Select, InputNumber } from 'antd';
import { useEffect, Fragment } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

export default () => 
<Fragment>
      <Form.Item key='3040' name='title' label='知乎问题标题' className ='searchFormItem'><Input placeholder='知乎问题标题' /></Form.Item>


    


    
      <Form.Item key='3043' label='阅读量' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3043_start' name='read_num_last_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3043_end' name='read_num_last_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3244' label='10日新增阅读' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3244_start' name='read_add_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3244_end' name='read_add_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3046' label='关注人数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3046_start' name='follow_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3046_end' name='follow_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3047' label='回答人数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3047_start' name='answer_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3047_end' name='answer_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3246' label='知指数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3246_start' name='zhi_index_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3246_end' name='zhi_index_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3632' label='首答赞同数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3632_start' name='first_zan_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3632_end' name='first_zan_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3633' label='二答赞同数' className ='searchFormItem'>
        <Input.Group compact >
          <Form.Item key='3633_start' name='second_zan_num_start' className ='searchFormItemStart'><InputNumber max='100000000000' placeholder='起' /></Form.Item>
          <Form.Item key='3633_end' name='second_zan_num_end' ><InputNumber placeholder='止' max='100000000000' /></Form.Item>
        </Input.Group>
      </Form.Item>


    
      <Form.Item key='3634' name='question_status' label='问题状态' className = 'searchFormItem'>
        <Select
            placeholder='问题状态'
            allowClear
            getPopupContainer={triggerNode => document.getElementsByClassName('ant-layout-content')[0]}
          >
                <Option key='1' value='1'>正常</Option>
                <Option key='0' value='0'>问题关闭</Option>
                <Option key='2' value='2'>无法打开</Option>
                <Option key='3' value='3'>获取失败</Option>
        </Select>
      </Form.Item>


    

      <Form.Item key='3635' name='question_create_time' label='问题创建时间	' className = 'searchFormItem'>
        <RangePicker suffixIcon={() => null} format={'YYYY/MM/DD'} />
      </Form.Item>

    
</Fragment>