import { useState, useEffect, Fragment, useContext } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { Table, message } from 'antd';
import { NavLink } from 'react-router-dom';
import { member } from './../service'
import AddCount from '@/components/addCount'
import styles from './index.module.scss';
import moment from 'moment'
const columns = [
  {
    title: '会员权益',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '到期时间',
    dataIndex: 'end_time',
    key: 'end_time',
    render: text => moment(text * 1000).format('YYYY/MM/DD hh:mm:ss'),
  },
  ]
const User = (props) => {
  const [list, setList] = useState([])
  const getMember = async (idlist) => {
    message.destroy()
    try {
      const res = await member();
      setList(res?.list)
      // setPrice(res)
      // const list = res?.list.map(item => {
      //   item.key = item[pk_id]
      //   return item
      // })
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
  };
  useEffect(() => {
    getMember();
  }, [])
  return <div className={styles.user}>
    <div className={styles.bread}>
      <div className={styles.breadItem}>
        个人资料
      </div>
      {/* <div className={styles.teach} onClick={toTutorials}>
        <FileTextOutlined style={{ color: '#25B864', marginRight: '4px' }} />
        页面使用教程
      </div> */}
    </div>
    <div className={styles.content}>
      <div className={styles.name}>
        {list[0]?.username}
        <div className={styles.vip}>
          vip
        </div>
      </div>
      <div className={styles.phone}>
        手机号：{list[0]?.mobile}
      </div>
      <div className={styles.line}></div>
      <div className={styles.resTime}>
     
      注册时间：{moment(list[0]?.create_time * 1000).format('YYYY/MM/DD hh:mm:ss')}
      </div>
      <div className={styles.endTime}>
      会员到期时间：{moment(list[0]?.end_time * 1000).format('YYYY/MM/DD hh:mm:ss')}
      </div>
      <div className={styles.countNum}>
        <div className={styles.countNumBox}>
          子账号数量：{list[0]?.sub_count}/{list[0]?.sub_num}
        </div>
        <NavLink to={'/'} exact
          activeClassName="active" key={'查看子账号'}>查看子账号</NavLink>
        <NavLink to={'/'} exact
          activeClassName="active" key={'添加子账号'}>&nbsp;&nbsp;添加子账号</NavLink>
      </div>
      <div className={styles.line}></div>
      <div className={styles.vipDetail}>
        <div className={styles.title}>
          会员套餐详情
        </div>
        <Table columns={columns} dataSource={list[0]?.role_list} />
        <AddCount />
      </div>
    </div>
  </div>
}
export default User;

