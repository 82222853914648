import { useState, useEffect } from 'react';
import { Pagination, Button } from 'antd'
import { useHistory } from 'react-router-dom';
import styles from './index.module.scss'
import PartItem from '../../../../components/partItem';
import { disassembleList } from './../../service';
import nocj from '@/assets/img/nocj.png'
import { Fragment } from 'react';
const PartList = (props) => {
  const history = useHistory();
  const [list, setList] = useState(null)
  const [pagination, setPagination] = useState({
    pageNo: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true, // 显示可改变每页数量
    pageSizeOptions: [20, 50, 100, 500], // 每页数量选项
  });
  useEffect(() => {
    getDisassembleList({
      pageNo: 1,
      pageSize: 10,
      dt_id: props?.dt_id,
    })
  }, [props?.dt_id])
  const getDisassembleList = async (pagination) => {
    const res = await disassembleList(
      {
        dt_id: props?.dt_id,
        pageNo: pagination.pageNo,
        pageSize: pagination.pageSize
      }
    )
    setList(res.list || [])
    setPagination({
      pageNo: pagination.pageNo,
      pageSize: pagination.pageSize,
      total: res.count
    })
  }
  const toDisassemble = () => {
    history.push(`/disassemble/${props?.dt_id}/0`)
  }
  const handleTableChange = (pageNo, pageSize) => {
    // pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    // setPagination({ ...pagination });
    getDisassembleList({
      pageNo: pageNo,
      pageSize: pageSize,
      dt_id: props.dt_id,
    });
  };
  return <div className={styles.partBox}>
    <div className={styles.partList}>
      {
        list !== null && !!list.length && list?.map(i => <PartItem hideCollect={props.hideCollect} item={i} key={i} />) || (
          <div className={styles.empty}>
            {
              !!list && (
                <img src={nocj} alt="" />
              )
            }
            {
              !!list && (
                <Button type={'primary'} style={{ width: '120px' }} onClick={toDisassemble}>去拆解</Button>
              )
            }

          </div>
        )
      }
    </div>
    {
      !!list?.length && (
        <Pagination onChange={handleTableChange} current={pagination.pageNo} pageSizeOptions={pagination.pageSizeOptions} total={pagination.total} showTotal={(total, range) => { return `总共${total} 条记录` }} />
      ) || null
    }
  </div>
}

export default PartList