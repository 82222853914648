import Search from './search';
import Info from '@/components/info'
// import { columns } from '@/src/utils/formdata.js';
import styles from './index.module.scss';
import { Table, notification, Tooltip, Checkbox, Button, message, Popover, Tabs, ConfigProvider } from 'antd'
import Addvip from '@/components/addVip'
import { SettingOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { GlobalContext } from '@/App'
import { useState, useEffect, useContext } from 'react';
import { getDataList, addBenchmarkTalent } from './../service'
import { pk_id, controller_name, followers_max_age_key } from './../constant'
import { useHistory } from 'react-router-dom';
import { checkRole } from '@/utils/service'
import { routerList } from '@/utils/constant'
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import nodata from '@/assets/img/nodata.png'
const { TabPane } = Tabs;
const List = (props) => {
  const history = useHistory();
  const { user_info } = useContext(GlobalContext);
  const isLogin = sessionStorage.getItem("isLogin");
  const columnsList = [
    {'ellipsis': true},

    {
      "title": "达人信息", "dataIndex": "info", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": false, "note": "", "name": "关键词", "key": "info", render: (key, record) => (
        <Info record={record} hideTag={true} />
      ),
      width:400
    },
    { "title": "拆解次数", "dataIndex": "dismantle_num", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": true, "note": "", "name": "拆解次数", "key": "dismantle_num","width": 120,
    render: (key, record) => (
      <Link to={`works/${record?.dt_id}/1`}>{key}</Link>
    )},

    { "title": "行业", "dataIndex": "content_type", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": false, "note": "", "name": "行业", "key": "content_type",render: (key, record) => (
        <div >{key?.split(',').map(i => <div>{i}</div>)}</div>
    )},

    { "title": "粉丝", "dataIndex": "followers_num", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": true, "note": "", "name": "粉丝", "key": "followers_num" },
    { "title": "获赞", "dataIndex": "like_num", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": true, "note": "", "name": "获赞", "key": "like_num" },
    {
      "title": "作品", "dataIndex": "works_num", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": true, "note": "", "name": "作品", "key": "works_num",width:100, render: (key, record) => {
        const url = `https://www.douyin.com/user/${record?.sec_uid}`
        return <a href={url} target={'_blank'}>{key}</a>
      }
    },
    { "title": "近10天粉丝增量", "dataIndex": "followers_num_add", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": true, "note": "", "name": "近10天粉丝增量", "key": "followers_num_add",width:160 },

    { "title": "粉丝年龄段", "dataIndex": "followers_max_age", "checked": true, "sortDirections": ["ascend", "descend"],width:130, "sorter": true, "note": "", "name": "粉丝年龄段", "key": "followers_max_age",render: (key, record) => (
      <Tooltip title={record?.followers_age?.split(',').map(i => <div>{i}</div>)} color={'black'}>
        <div style={{cursor: 'pointer'}} >{followers_max_age_key[key]}</div>
      </ Tooltip>
    )},
    { "title": "男性比例(%)", "dataIndex": "followers_gender_male", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": true, "note": "", "name": "男性比例", "key": "followers_gender_male",width:130, },
    { "title": "女性比例(%)", "dataIndex": "followers_gender_female", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": true, "note": "", "name": "女性比例", "key": "followers_gender_female",width:130,},

    { "title": "省分布", "dataIndex": "followers_province", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": false, "note": "", "name": "省分布", "key": "followers_province",render: (key, record) => (
      <Tooltip title={record?.followers_province?.split(',').map(i => <div>{i}</div>)} color={'black'}>
        <div style={{cursor: 'pointer'}} >
        {record?.followers_province?.split(',')?.slice(0,3)?.map(i => <div>{i}</div>)}
        </div>
      </ Tooltip>
    ) },
    { "title": "市分布", "dataIndex": "followers_city", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": false, "note": "", "name": "市分布", "key": "followers_city",render: (key, record) => (
      <Tooltip title={record?.followers_city?.split(',').map(i => <div>{i}</div>)} color={'black'}>
        <div style={{cursor: 'pointer'}} >
        {record?.followers_city?.split(',')?.slice(0,3)?.map(i => <div>{i}</div>)}
        </div>
      </ Tooltip>
    )},
    { "title": "粉丝总结", "dataIndex": "join", "checked": true, "sortDirections": ["ascend", "descend"], "sorter": false, "note": "", "name": "市分布", "key": "join",render: (key, record) => (
      <Tooltip title={<div>{record?.followers_conclusion}</div>} color={'black'}>
        <div style={{cursor: 'pointer'}} >
          <div>{followers_max_age_key[record?.followers_max_age]}</div>
          <div>{
             record?.followers_gender_male > record?.followers_gender_female ? '男性居多' :'女性居多'}</div>
        </div>
      </ Tooltip>
    )},
    {
      "title": "操作", "dataIndex": "cz", "key": "cz", "checked": true, "name": "操作", render: (tags, record) => (
        <Fragment>
          <Tooltip title={'账号详情'} color={'black'}>
            <div className="x" onClick={() => toDetail(record)}></div>
          </Tooltip>
          <Tooltip title={'账号拆解'} color={'black'}>
            <div className="c" onClick={() => toDisble(record)}></div>
          </Tooltip>
          <Tooltip title={'加入对标'} color={'black'}>
            <div className="j" onClick={() => addBenchmark(record)}></div>
          </Tooltip>
        </Fragment>
      )
    },
    
  ]
  const [loading, setLoading] = useState(false)
  const [_columns, setColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showColumnsSelect, setShowColumnsSelect] = useState(false);
  const [isSearch, setIsSearch] = useState(true);
  const [searchParams, setSearchParams] = useState(JSON.parse(sessionStorage.getItem("dsearchParams")) || {})
  // const { isLogin } = useContext(GlobalContext);
  const [tableFixed, setTableFixed] = useState(false)
  const [baseData, setBaseData] = useState(null)
  const [vipVis, setVipVis] = useState(false)
  const [pagination, setPagination] = useState({
    current: JSON.parse(sessionStorage.getItem('dpagination'))?.pageNo || 1,
    pageSize: JSON.parse(sessionStorage.getItem('dpagination'))?.pageSize || 20,
    total: 0,
    showTotal: (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` },
    defaultPageSize: 20,
    showSizeChanger: true, // 显示可改变每页数量
    pageSizeOptions: [20, 50, 100, 500], // 每页数量选项
  });
  const root = document.getElementById('root')
  const getheight = (e) => {
    let rootId = document.getElementById('root')?.scrollTop;
    let tableBox = document.getElementById('tableBox')?.offsetTop;
    // if (rootId + document.getElementsByTagName('body')[0].clientHeight >= 1510) {
    //   if (document.getElementsByClassName('ant-pagination')[0]) {
    //     document.getElementsByClassName('ant-pagination')[0].style.position = 'fixed'
    //   }
    //   if (document.getElementById('tableBox')) {
    //     document.getElementById('tableBox').style.paddingBottom = '64px'
    //   }
    // } else {
    //   if (document.getElementsByClassName('ant-pagination')[0]) {
    //     document.getElementsByClassName('ant-pagination')[0].style.position = 'static'
    //   }
    //   if (document.getElementById('tableBox')) {
    //     document.getElementById('tableBox').style.paddingBottom = '0'
    //   }
    // }
    if (rootId >= tableBox - 80) {
      setTableFixed(true)
    } else {
      setTableFixed(false)
    }
  }
  useEffect(() => {
    if(!!user_info && navigator.userAgent.indexOf("Chrome")<0) {
      document.getElementsByTagName('table')[1].style.display='inline-block'
    } else {
      document.getElementsByTagName('table')[1].style.display='inline-block'
    }
  }, [user_info])
  useEffect(() => {
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    getDataListFun({
      ...searchParams,
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
      sort: '',
      order: '',
    })
    setColumns([...columnsList])
    root.addEventListener('scroll', getheight)
    return () => {
      root.removeEventListener("scroll", getheight)
    }
  }, [])
  const dumpDataFun = async () => {
    if (!!selectedRowKeys.length) {
      setLoading(true)
      try {
        await checkRole({
          url: `/jkapi/${controller_name}/dumpData`
        })
        const id_list = selectedRowKeys.toString();
        const field_list = _columns.filter(i => i?.checked).map(i => i?.dataIndex).toString()
        window.open(`http://api.yinliu.club/jkapi/${controller_name}/dumpData?id_list=${id_list}&field_list=${field_list}`)
      } catch (errorInfo) {
        message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
        console.log('Failed:', errorInfo);
      }
      setLoading(false)
    } else {
      message.destroy()
      message.error('请选择导出项')
    }
  };

  const getDataListFun = async (params, isSearch) => {
    setLoading(true)
    notification.destroy()
    message.destroy()
    try {
      const res = await getDataList(params);
      if (res?.code == 1) {
        const list = res?.list.map(item => {
          item.key = item[pk_id]
          return item
        })
        setPagination({ ...pagination, current: params.pageNo, pageSize: params.pageSize, total: res?.count })
        sessionStorage.setItem(
          "dpagination",
          JSON.stringify({
            pageNo: params.pageNo,
            pageSize: params.pageSize,
          })
        );
        !!isSearch && notification.open({
          message: '',
          duration: 2,
          maxCount: 1,
          description: <span>本次搜索为您筛选出{res?.count || 0}条数据!</span>,
        })
        //  setBaseData([])
        setBaseData(list || [])
      } else if (res?.code == 2) {
        setVipVis(true)
        const list = res?.list.map(item => {
          item.key = item[pk_id]
          return item
        })
        setPagination({ ...pagination, current: params.pageNo, pageSize: params.pageSize, total: res?.count })
        setBaseData(list || [])
      }
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setLoading(false)
  };
  const onSearch = (params, isSearch) => {
    setSearchParams(params)
    sessionStorage.setItem("dsearchParams", JSON.stringify(params));
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    if (isLogin === "false" || !isLogin) {
      message.error("请先登录");
    } else {
      getDataListFun({
        ...params,
        is_search: true,
        order: "",
        pageNo: 1,
        pageSize: 20,
        sort: "",
        // pagination
      }, isSearch)
    }
  }
  const refresh = () => {
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    if (isLogin === "false" || !isLogin) {
      message.error("请先登录");
    } else {
      getDataListFun({
        ...searchParams,
        is_search: true,
        order: "",
        pageNo: 1,
        pageSize: 20,
        sort: "",
        pagination
      }, false)
    }

  }

  const handleTableChange = (pagination, filters, sorter) => {
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    
    // setPagination({ ...pagination });
    getDataListFun({
      ...searchParams,
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
      sort: !!sorter.field ? `${sorter.field}` : '',
      order: !!sorter.order ? sorter.order : '',
      // pagination
    });
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // const rowSelection = null
  const changeColumns = (e, index) => {
    if (e.target.checked && _columns?.filter((element) => {
      return element.checked;
    }).length >= 30) {
      message.error('最多选择30列');
      return;
    }
    console.log(_columns, index)
    _columns[index].checked = e.target.checked;
    setColumns([..._columns]);
  };

  const SortableItem = (({ value, sortIndex }) => {
    let { key, name } = value;
    let title = value?.title
    return <div key={`line_${key}`} className={styles.uiContentItem} style={{ zIndex: 1031 }}>
      <Checkbox checked={value.checked} disabled={value.disabled} onChange={(e) => {
        changeColumns(e, sortIndex);
      }} > {title?.length > 10 ?
        <Tooltip title={title} color={'black'}>
          <span style={{ cursor: 'pointer' }} >{title.slice(0, 10)}...</span>
        </Tooltip> :
        title
        }</Checkbox>
    </div>;
  });
  const SortableList = (({ items }) => {
    return (
      <div className={styles.uiSelectBox} >
        {
          items?.map((element, index) => {
            return <SortableItem key={`item-${index}`} value={element} sortIndex={index} index={index} />;
          })
        }
      </div>
    );
  });
  const getContent = () => {
    return <SortableList distance={10} items={_columns} lockAxis={'y'} />
  }

  const toTutorials = () => {
    window.open(
      routerList?.filter(i => i?.path === props?.match?.path)[0]?.tutorials_url
    )
  }

  const changeTab = (key) => {
    console.log(key);
  }

  const renderEmpty = () => {
    return  <div className={styles.empty}>
      {!!baseData && (
      <img src={nodata} alt="" />) || null}
    </div>
  }

  const toDetail = (r) => {
    history.push(`/works/${r.dt_id}/0`)
  }
  const toDisble = (r) => {
    history.push(`/disassemble/${r.dt_id}/0`)
  }
  const addBenchmark = async(r) => {
    setLoading(true)
      try {
        await addBenchmarkTalent({sec_uid: r?.sec_uid})
        message.success('添加成功')
      } catch (errorInfo) {
        message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
        console.log('Failed:', errorInfo);
      }
      setLoading(false)
  }
  return <div className={styles.list}>
    { vipVis && (
      <Addvip handleCancel={() => setVipVis(false)} />
    )
    }
    <Search isSearch={isSearch} onSearch={onSearch} />
    <div className={styles.listBtn}>
      <div className={styles.btnLeft}>
        {/* <Button style={{ fontSize: '16px'}} className='dumpData' icon={<UploadOutlined />} type={!!selectedRowKeys.length ? "primary" : ""} onClick={dumpDataFun} >批量下载</Button> */}
      </div>
      <div className={styles.btnRight}>
        {/* <Button icon={<SearchOutlined style={{fontSize: '16px'}} />} onClick={() => setIsSearch(!isSearch)} /> */}
        <Button icon={<ReloadOutlined />} style={{ marginRight: '8px' }} onClick={refresh}>刷新</Button>
        <Popover getPopupContainer={triggerNode => triggerNode.parentNode} placement="bottomRight" visible={showColumnsSelect} onVisibleChange={(visible) => {
          setShowColumnsSelect(visible);
        }} content={getContent()} trigger="click">
          <div className={styles.selectBtn} onClick={() => {
            setShowColumnsSelect(true);
          }}>
            <Button icon={<SettingOutlined />}>列设置</Button>

          </div>
        </Popover>
      </div>
    </div>
    <div className={tableFixed ? 'tableFixed' : 'table'} id='tableBox'>
      <ConfigProvider renderEmpty={() => renderEmpty()}>
        <Table loading={loading} columns={_columns.filter(i => i?.checked)} dataSource={baseData}
            sticky={{offsetHeader: 64}}
        scroll={{ x: 'max-content' }}
        onChange={handleTableChange} pagination={pagination} />
      </ConfigProvider>
    </div>
  </div>
}
export default List;

