import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';

import { Table, Pagination, Tooltip, Alert, Button, message, Select, Radio, ConfigProvider, Modal, Input, Form, Cascader, Spin } from 'antd'
import { PlusOutlined, ExclamationCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import { getcontentTypeList, addgroup, gettradeList, deleteGroup, getGroupList, receiveBenchmarkTalent } from '../service'
import { GlobalContext } from '@/App'
import { useState, useEffect, useContext, useCallback } from 'react';
import GroupItem from './groupItem'
import { Fragment } from 'react/cjs/react.production.min';
const Grouup = (props) => {
  const history = useHistory();
  const { confirm } = Modal
  const { Option } = Select;
  const { user_info } = useContext(GlobalContext);
  // const { isLogin } = useContext(GlobalContext);
  // const isLogin = sessionStorage.getItem("user_info");
  const [grouploading, setgrouploading] = useState(false)
  const [attentionVis, setAttentionVis] = useState(false)
  const [tradeList, setTradeList] = useState(JSON.parse(sessionStorage.getItem('dTypeList')) || [])
  const [addgroupform] = Form.useForm();
  const [attentionform] = Form.useForm();
  const [attention, setattention] = useState(null)
  const [typeList, setTypeList] = useState([])
  const [createGroupLoading, setCreateGroupLoading] = useState(false)
  const [attentionLoading, setAttentionLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: JSON.parse(sessionStorage.getItem('dpagination'))?.pageNo || 1,
    pageSize: JSON.parse(sessionStorage.getItem('dpagination'))?.pageSize || 20,
    total: 0,
    showTotal: (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` },
    defaultPageSize: 20,
    showSizeChanger: true, // 显示可改变每页数量
    pageSizeOptions: [20, 50, 100, 500], // 每页数量选项
  });
  useState(false)
  const [createGroupVis, setCreateGroupVis] = useState(false)
  const [grouppagination, setGrouppagination] = useState({
    current: 1
  })
  const [groupList, setGroupList] = useState(null)
  useEffect(() => {
    attentionform.setFieldsValue({ "identity_type": 1 })
    getSelectListFun()
    pagination.showTotal = (total, range) => { return `显示第${range[0]}到第${range[1]}条记录，总共${total} 条记录` }
    getGroupListFun(pagination.current,
      pagination.pageSize)

  }, [])
  useEffect(() => {
    if (!createGroupVis) {
      addgroupform.resetFields()
    }
    if (!attentionVis) {
      attentionform.resetFields(['age', 'identity','company_size','position','content_type'])
    }
  }, [createGroupVis, attentionVis])
  const changeContentTypeList = (list) => {
    return list?.map(i => {
      // i.value = i.dttct_id
      i.value = i.dttct_id
      i.label = i.content_type
      if (i.children) {
        changeContentTypeList(i.children)
      }
      return i
    })
  }
  const attentionFun = async() => {
    message.destroy()
    setAttentionLoading(true)
    try { 
      attentionform.submit();
      const attentionformvalues = await attentionform.validateFields();
      
      await receiveBenchmarkTalent(attentionformvalues)
      setAttentionVis(false)
      message.success('领取行业对标达人成功')
      getGroupListFun(1,pagination.pageSize);
      // setTimeout(() => {
      //   history.go(history.location.pathname)
      // }, 400);
    } catch (errorInfo) {
      // message.error(errorInfo)
      !!errorInfo?.errorFields && message.error(errorInfo?.errorFields[0]?.errors || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setAttentionLoading(false)
  }
  const getSelectListFun = async () => {
    message.destroy()
    try {
      if (!sessionStorage.getItem('dTypeList')) {
        const res = await getcontentTypeList();
        sessionStorage.setItem('dTypeList', JSON.stringify(changeContentTypeList(res)))
        setTypeList(changeContentTypeList(res))
      } else {
        setTypeList(changeContentTypeList(JSON.parse(sessionStorage.getItem('dTypeList'))))
      }
      if (!sessionStorage.getItem('dTradeList')) {
        const res = await gettradeList();
        sessionStorage.setItem('dTradeList', JSON.stringify(res))
        setTradeList(res)
      }
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
  };
  const createGroup = async () => {
    setCreateGroupVis(true)
    setCreateGroupLoading(true)
    try {
      const { group_name } = await addgroupform.getFieldsValue()
      if (!!group_name) {
        await addgroup(group_name)
        getGroupListFun(1, pagination.pageSize)
        message.success('新建分组完成');
        setCreateGroupVis(false);
      } else {
        message.destroy()
        message.warning('请输入分组名称')
      }
    } catch (errorInfo) {
      console.log(errorInfo);
    }
    setCreateGroupLoading(false)
  }
  const deleteItemFun = (dbtg_id) => {
    confirm({
      title: '您确定要移除该分组?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        deleteItem(dbtg_id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  const deleteItem = async (dbtg_id) => {
    try {
      await deleteGroup(dbtg_id)
      getGroupListFun(pagination.current,
        pagination.pageSize)
      message.success('分组移除成功')
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }

  }
  const groupChange = (pageNo, pageSize, sorter) => {
    getGroupListFun(pageNo, pageSize)
  }
  const getGroupListFun = async (pageNo, pageSize) => {
    setgrouploading(true)
    try {
      let newpageSize = pageSize === 50 ? pageSize - 3 : pageSize - 1
      const res = await getGroupList(pageNo, newpageSize)
      // setGrouppagination({
      //   pageNo,pageSize
      // })
      setPagination({ ...pagination, current: pageNo, pageSize: pageSize, total: res?.count })
      setGroupList(res)
      setattention(res?.attention)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    setTimeout(() => {
      setgrouploading(false)
    }, 300)
  }
  const onValuesChange = (changedValues) => {
    if (changedValues.hasOwnProperty('identity_type')) {
      attentionform.resetFields(['age', 'identity','company_size','position','content_type'])
    }
    if (changedValues.hasOwnProperty('content_type')) {
      let content_type_value = JSON.parse(JSON.stringify(changedValues.content_type))
      let ary = []
      content_type_value.forEach(i => {
        ary.push(i[0])
      })
      console.log('ary', ary.filter(function (currentValue, index, arr) {
        return  arr.indexOf(currentValue) === index;
      }))
      let firstArr = ary.filter(function (currentValue, index, arr) {
        return  arr.indexOf(currentValue) === index;
      })
      if (firstArr.length > 5) {
        message.error('最多可选择5个1级行业')
        // attentionform.resetFields(['content_type'])
        attentionform.setFieldsValue({
          content_type: [...changedValues.content_type.filter(i => i[0] != firstArr[5])],
        });
      }
    }
  };
  return <div className={styles.groupbox}>
    <Spin size='large' spinning={grouploading} >
      {
        !!attention && (
          <Alert
            message={<div className='groupalert'>
              帮助提示：<br></br>
              请先领取行业对标达人（填写行业等信息后，我们将基于行业给您推荐您的行业对标达人）
              <div className="gruopBtn" onClick={() => setAttentionVis(true)}>领取行业对标达人</div>
            </div>
            }
            type="success"
            showIcon
            icon={<InfoCircleFilled />}
          />
        )
      }
      <div className={styles.group}>
        <div className={user_info?.is_vip ? styles.new : styles.ntnew} onClick={() => { user_info?.is_vip ? setCreateGroupVis(true) : message.error('没有该模块权限') }}>
          <PlusOutlined />&nbsp;
          新增对标分组
        </div>
        
        {
          !!groupList && (
            groupList?.list?.map(i => <GroupItem deleteItem={() => deleteItemFun(i?.dbtg_id)} item={i} />)
          ) || (
            <div className='nogroup'>
              {/* 暂无分组 */}
            </div>
          )
        }
        <Pagination
          {...pagination}
          onChange={groupChange}
          hideOnSinglePage
          defaultCurrent={1} />
        <Modal title="新建对标分组" visible={createGroupVis} className='addgroup'
          footer={[
            <Button key="back" type='primary' loading={createGroupLoading} onClick={createGroup}>
              完成
            </Button>,
          ]} onCancel={() => setCreateGroupVis(false)}>
          <div className='groupname'>
            分组名称
            <Form
              // layout={'inline'}
              form={addgroupform}
              wrapperCol={{ span: 24 }}
            >
              <Form.Item rules={[{ required: true, message: '请输入分组名称' }]} key='group_name' style={{ width: '100%', marginTop: '16px' }} name='group_name' >
                <Input showCount maxLength={15} placeholder='请输入' />
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>

      <Modal title={<div className="attentiontitle">
        领取行业对标达人
        <div className='attentiontitlemark'>

          （<span style={{ color: '#DF4040' }}>仅1次</span>领取机会）

        </div>
      </div>} visible={attentionVis} className='attention'
        footer={[
          <Button key="back" type='primary' loading={attentionLoading} onClick={attentionFun}>
            完成
          </Button>,
        ]} onCancel={() => setAttentionVis(false)}>
        {/* <div className='groupname'>
            分组名称 */}
        <Form
          // layout={'inline'}
          form={attentionform}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onValuesChange={onValuesChange}
        >
          <Form.Item label={'身份类别：'} rules={[{ required: true, message: '请输入分组名称' }]} key='identity_type' style={{ width: '100%', marginTop: '16px' }} name='identity_type' >
            <Radio.Group >
              <Radio value={1}>个人</Radio>
              <Radio value={2}>企业</Radio>
            </Radio.Group>

          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues.identity_type != currentValues.identity_type
            } }
          >
             {({ getFieldValue, setFieldsValue }) => {
                return getFieldValue('identity_type') && (
                  getFieldValue('identity_type') === 1 && (
                   <Fragment> <Form.Item label={'你的年龄：'} rules={[{ required: true, message:'请选择你的年龄'}]} key='age' style={{ width: '100%', marginTop: '16px' }} name='age' >
                    <Select placeholder='请输入' >
                    <Option value="18 ~ 24">18 ~ 24</Option>

                    <Option value="25 ~ 30">25 ~ 30</Option>
                    <Option value="31 ~40">31 ~40</Option> <Option value=" 41 ~ 50"> 41 ~ 50</Option> 
                    <Option value="50+">50+</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label={'你的身份：'} rules={[{ required: true, message: '请输入分组名称' }]} key='group_name' style={{ width: '100%', marginTop: '16px' }} name='identity' >
                  <Select placeholder='请输入' >
                    <Option value="学生">学生</Option>

                    <Option value="上班族">上班族</Option>
                    <Option value="自由职业">自由职业</Option> <Option value="宝妈"> 宝妈</Option> 
                    <Option value="其他">其他</Option>
                    </Select>
                  </Form.Item>
                  </Fragment>
                    )
                ) || (
                  <Fragment> <Form.Item label={'公司规模：'} rules={[{ required: true, message: '选择公司规模' }]} key='company_size' style={{ width: '100%', marginTop: '16px' }} name='company_size' >
                   <Select placeholder='请输入' >
                    <Option value="0 ~ 20人">0 ~ 20人</Option>

                    <Option value="20 ~ 99人">20 ~ 99人</Option>
                    <Option value="100 ~ 499人">100 ~ 499人</Option> <Option value="500 ~ 999人"> 500 ~ 999人</Option> 
                    <Option value="1000 ~ 9999人">1000 ~ 9999人</Option>
                    <Option value="10000人以上">10000人以上</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label={'你的岗位：'} rules={[{ required: true, message: '请输入你的岗位：' }]} key='position' style={{ width: '100%', marginTop: '16px' }} name='position' >
                    <Input placeholder='请输入' />
                  </Form.Item>
                  </Fragment>
                )
              }}
          </Form.Item>
         
          <Form.Item key='32062df '
            rules={[{ required: true, message: '请选择行业' }]} name='content_type' label='您的行业' className='searchFormItem'>
            <Cascader options={typeList} multiple maxTagCount="responsive" key='content_type' placeholder="请选择行业"  />
          </Form.Item>
        </Form>
        {/* </div> */}
      </Modal>
    </Spin>
  </div>
}
export default Grouup;
