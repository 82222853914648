import { Fragment, useState } from 'react'
import styles from './index.module.scss';
import { Checkbox, Input } from 'antd'
const { TextArea } = Input;
const Check = (props) => {
  const { dataSource } = props;
  console.log('dataSource', dataSource)
  const [sign_analyze, setSign_analyze] = useState([])
  const onChange = (e) => {
    props.onChange(e, [...dataSource] )
  }
  const onBlur = (e,i) => {
    props.onChange(e, [...dataSource],i )
  }
  return <Fragment>
    {
      !!dataSource && dataSource?.map(i => <div>
        <Checkbox value={i.title}     
        key={i} checked={i.checked} onChange={onChange}>
        
          {i.title}
        </Checkbox>
        {
          i.checked && (
            <TextArea
              onBlur={(e) => onBlur(e,i)} 
              autoSize={{ minRows: 2, maxRows: 2 }}
              key={i}
            defaultValue={i?.inner}
              allowClear
              placeholder={i.placeholder ? i.placeholder : '请输入'}
              maxLength={300} rows={4} className={styles.textArea} />
          )
        }
      </div>)
    }
   
  </Fragment>
}
export default Check;