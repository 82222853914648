export const columnsList = [
{"title":"达人信息","dataIndex":"info","checked":true,"sortDirections":["ascend","descend"],"sorter":false,"note":"","name":"关键词","key":"info"},
{"title":"拆解次数","dataIndex":"dismantle_num","checked":true,"sortDirections":["ascend","descend"],"sorter":true,"note":"","name":"拆解次数","key":"dismantle_num"},
{"title":"粉丝","dataIndex":"followers_num","checked":true,"sortDirections":["ascend","descend"],"sorter":true,"note":"","name":"粉丝","key":"followers_num"},
{"title":"获赞","dataIndex":"like_num","checked":true,"sortDirections":["ascend","descend"],"sorter":true,"note":"","name":"获赞","key":"like_num"},
{"title":"作品","dataIndex":"works_num","checked":true,"sortDirections":["ascend","descend"],"sorter":true,"note":"","name":"作品","key":"works_num"},
{"title":"近10天粉丝增量","dataIndex":"followers_num_add","checked":true,"sortDirections":["ascend","descend"],"sorter":true,"note":"","name":"近10天粉丝增量","key":"followers_num_add"},
]
export const worksList = [
  {"title":"视频信息","dataIndex":"info","checked":true,"sortDirections":["ascend","descend"],"sorter":false,"note":"","name":"视频信息","key":"info"},
  {"title":"评论量","dataIndex":"comment_num","checked":true,"sortDirections":["ascend","descend"],"sorter":true,"note":"","name":"播放量","key":"comment_num"},
  {"title":"点赞量","dataIndex":"like_num","checked":true,"sortDirections":["ascend","descend"],"sorter":true,"note":"","name":"点赞量","key":"like_num"},
  {"title":"转发数","dataIndex":"forward_num","checked":true,"sortDirections":["ascend","descend"],"sorter":true,"note":"","name":"转发数","key":"forward_num"},
]
export const menu_list = [{"menu_id":773,"pid":0,"controller_name":"KeywordHot","title":"全网热词","pk_id":"k_id","table_name":"keyword_hot","is_create":1,"status":1,"sortid":2,"table_status":0,"is_url":null,"url":"","menu_icon":"fa fa-asterisk","tab_menu":"","app_id":217,"is_submit":0,"upload_config_id":0,"tutorials_url":null}]
export const controller_name = 'KeywordHot'
export const pk_id = 'k_id'

        