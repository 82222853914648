// import { NewContract } from '@/components/business/new.contract';
import request from '@/utils/request';
import { controller_name } from './constant'
/***
 * banner数据
 * @param params
 */
export async function commodityList() {
  return request({
    url: `jkapi/Commodity/commodityList`,
    method: 'POST',
    // data: params,
  });
}


/***
 * 总价格
 * @param params
 */
 export async function calculatePrice(params) {
  return request({
    url: `/jkapi/Commodity/calculatePrice`,
    method: 'POST',
    data: params,
  });
}

/***
 * 支付
 * @param params
 */
 export async function inquireQuestion(params) {
  return request({
    url: `/jkapi/Commodity/toPay`,
    method: 'POST',
    data: params,
  });
}

