import styles from './index.module.scss';
import { Table, notification, Tooltip, Checkbox, Button, message, Popover, Modal, Tabs, ConfigProvider } from 'antd'
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '@/App'
import { useState, useEffect, useContext } from 'react';
import { deleteGroup } from './../../service'
const GroupItem = (props) => {
  const { item } = props;
  const history = useHistory();
  const addToGroup = () => {
    history.push(`/addToGroup/${item?.dbtg_id}/${item?.group_name}`)
  }
  const deleteItem = async (dbtg_id) => {
    try {
      await deleteGroup(dbtg_id)
    } catch (errorInfo) {
      message.error(errorInfo?.msg || '系统正在卖力成长中，请稍后重试！');
      console.log('Failed:', errorInfo);
    }
    
  }
  const groupList = () => {
    history.push(`/groupList/${item?.dbtg_id}`)
  }
  return <div className={styles.groupItem}>
    <div className={styles.name}>
    {
    item?.group_name
  }
    </div>
    <div className={styles.daren}>
    对标达人：{item?.talent_num}个对标
    </div>
    <div className={styles.content}>
      {
        item?.group_info?.split(';')?.map(i => <div className={styles.infoItem}>{i}</div>)
      }
    </div>
    <div className={styles.operation}>
     
      <div className={styles.add} onClick={addToGroup}>
      添加 
      </div>
      <div className={styles.details} onClick={groupList}>
      分组列表
      </div>
      <div className={styles.delete} onClick={props?.deleteItem}>
      移除分组 
      </div>
    </div>
  </div>
}
export default GroupItem;
